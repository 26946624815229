<div class="mainbox">
	<div class="leftsidebar">
		&nbsp;
	</div>
	<div class="submainbox">
		<div>
			<div class="topgridbox">
				<div class="topgrid">
					<div>
						<mat-form-field class="search">
							<mat-label>Filter by Text</mat-label>
							<input matInput data-testid="filter-by-text" (keyup)="applyFilterEvent($event)"
								placeholder="Enter text string to filter (Date fields are not searchable)"
								spellcheck="false">
							<mat-icon matSuffix style="font-size: 1.2em">search</mat-icon>
						</mat-form-field>
					</div>
					<div>
						<mat-form-field class="dropdown" data-testid="filter-by-product">
							<mat-label>Filter Product</mat-label>
							<mat-select value="all" [ngModel]="filterProductState" (selectionChange)="setProductFilter($event.value)">
								<mat-option value="all">All</mat-option>
								<mat-option [value]="product" *ngFor="let product of getProductList()">
									{{product}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div>
						<mat-form-field class="dropdown" data-testid="filter-by-licensed">
							<mat-label>Filter Licensed</mat-label>
							<mat-select value="0" [ngModel]="filterActivatedState" (selectionChange)="setUsedFilter($event.value)">
								<mat-option value="0">All</mat-option>
								<mat-option value="1">True</mat-option>
								<mat-option value="2">False</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div>
						<mat-form-field class="dropdown" data-testid="filter-by-lock-style">
							<mat-label>Filter Lock Style</mat-label>
							<mat-select value="0" [ngModel]="filterCidState" (selectionChange)="setCIDFilter($event.value)">
								<mat-option value="0">All</mat-option>
								<mat-option value="1">USB/CID Keys</mat-option>
								<mat-option value="2">Node Locked</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="columns">
						<button data-testid="select-columns" mat-button (click)="selectColumns()">Columns</button>
					</div>
				</div>
			</div>
			<div class="detailstable">
				<div id="table" class="table">
					<table mat-table [dataSource]="dataSource" matSort #table>
						<ng-container matColumnDef="ponumber" sticky *ngIf="getFreeze('ponumber')">
							<th mat-header-cell *matHeaderCellDef>Purchase Order</th>
							<td mat-cell sticky *matCellDef="let element">
								<button mat-button (click)="showDetail('Purchase Order', element.ponumber)">
									{{element.ponumber != null && element.ponumber.length > 25 ?
									(element.ponumber | slice:0:25) + ".." : element.ponumber}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="productname" sticky *ngIf="getFreeze('productname')">
							<th mat-header-cell *matHeaderCellDef>Product</th>
							<td mat-cell sticky *matCellDef="let element">
								<button mat-button (click)="showDetail('Product', element.productname)">
									{{element.productname}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="entitlement" sticky *ngIf="getFreeze('entitlement')">
							<th mat-header-cell *matHeaderCellDef>Entitlement</th>
							<td mat-cell sticky *matCellDef="let element">
								<button mat-button (click)="showDetail('Entitlement', element.tenasysentitlementid)">
									{{element.tenasysentitlementid}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="suac" sticky *ngIf="getFreeze('suac')">
							<th mat-header-cell *matHeaderCellDef>Suac</th>
							<td mat-cell sticky *matCellDef="let element">
								<button mat-button (click)="showDetail('Suac', element.suac)">
									{{element.suac}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="isactive" sticky *ngIf="getFreeze('isactive')">
							<th mat-header-cell *matHeaderCellDef>Licensed</th>
							<td mat-cell sticky *matCellDef="let element">
								<button mat-button (click)="showDetail('Licensed', element.licensed)">
									{{element.licensed}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="license" sticky *ngIf="getFreeze('license')">
							<th mat-header-cell *matHeaderCellDef>License</th>
							<td mat-cell sticky *matCellDef="let element">
								<button mat-button (click)="showDetail('License', element.license)">
									{{element.license != null && element.license.length > 25 ? (element.license |
									slice:0:25) + ".." :
									element.license}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="activateddate" sticky *ngIf="getFreeze('activateddate')">
							<th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="licensedate-freeze-sort">
								Activated On</th>
							<td mat-cell sticky *matCellDef="let element">
								<button mat-button (click)="showDetail('Activated On', element.activateddate)">
									{{element.activateddate | date:'mediumDate'}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="activatedby" sticky *ngIf="getFreeze('activatedby')">
							<th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="licenseby-freeze-sort">
								Licensed By</th>
							<td mat-cell sticky *matCellDef="let element">
								<button mat-button (click)="showDetail('Licensed By', element.activatedby)">
									{{element.activatedby == element.tenasysentitlementid ? 'Entitlement' :
									element.activatedby}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="comment" sticky *ngIf="getFreeze('comment')">
							<th mat-header-cell *matHeaderCellDef>Activation Comment</th>
							<td mat-cell sticky *matCellDef="let element"
								matTooltip="{{element.customeractivationcomment}}" matTooltipClass="tooltip-long"
								[matTooltipPosition]="'after'">
								<button mat-button
									(click)="showDetail('Customer Activation Comment', element.customeractivationcomment)">
									{{element.customeractivationcomment != null &&
									element.customeractivationcomment.length > 25 ?
									(element.customeractivationcomment | slice:0:25) + ".." :
									element.customeractivationcomment}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="ipaddress" sticky *ngIf="getFreeze('ipaddress')">
							<th mat-header-cell *matHeaderCellDef>Licensed IP Address</th>
							<td mat-cell sticky *matCellDef="let element">
								<button mat-button (click)="showDetail('License IP Address', element.ipaddress)">
									{{element.ipaddress}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="classiclicense" sticky *ngIf="getFreeze('classiclicense')">
							<th mat-header-cell *matHeaderCellDef
								matTooltip="Imported by EntitlementImporter from the SUAC Manager"
								matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">Classic License</th>
							<td mat-cell sticky *matCellDef="let element">
								<button mat-button (click)="showDetail('Classic License', element.classiclicense)">
									{{element.classiclicense != null && element.classiclicense.length > 25 ?
									(element.classiclicense |
									slice:0:25) + ".." : element.classiclicense}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="cidclassiclicense" sticky *ngIf="getFreeze('cidclassiclicense')">
							<th mat-header-cell *matHeaderCellDef matTooltip="Created by USB/CID Onboarding Tool"
								matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">CID Classic License</th>
							<td mat-cell sticky *matCellDef="let element">
								<button mat-button (click)="showDetail('CID Classic License', element.shortlicense)">
									{{element.shortlicense != null && element.shortlicense.length > 25 ?
									(element.shortlicense |
									slice:0:25) + ".." : element.shortlicense}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="fingerprint" sticky *ngIf="getFreeze('fingerprint')">
							<th mat-header-cell *matHeaderCellDef>Fingerprint</th>
							<td mat-cell sticky *matCellDef="let element">
								<button mat-button (click)="showDetail('Fingerprint', element.fingerprint)">
									{{element.fingerprint != null && element.fingerprint.length > 25 ?
									(element.fingerprint | slice:0:25)
									+
									".." : element.fingerprint}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="usbkey" sticky *ngIf="getFreeze('usbkey')">
							<th mat-header-cell *matHeaderCellDef>USB/CID Key</th>
							<td mat-cell sticky *matCellDef="let element">
								<button mat-button (click)="showDetail('USB/CID Key', element.usbkey)">
									{{element.usbkey}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="createddate" sticky *ngIf="getFreeze('createddate')">
							<th mat-header-cell *matHeaderCellDef>Entitlement Created Date</th>
							<td mat-cell sticky *matCellDef="let element">
								<button mat-button
									(click)="showDetail('Entitlement Created Date', element.createddate)">
									{{element.createddate | date:'mediumDate'}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="classicorder" sticky *ngIf="getFreeze('classicorder')">
							<th mat-header-cell *matHeaderCellDef>Classic Order</th>
							<td mat-cell sticky *matCellDef="let element">
								<button mat-button (click)="showDetail('Classic Order', element.classicordernumber)">
									{{element.classicordernumber != null && element.classicordernumber.length > 25 ?
									(element.classicordernumber | slice:0:25) + ".." : element.classicordernumber}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="classicinvoice" sticky *ngIf="getFreeze('classicinvoice')">
							<th mat-header-cell *matHeaderCellDef>Classic Invoice</th>
							<td mat-cell sticky *matCellDef="let element">
								<button mat-button
									(click)="showDetail('Classic Invoice', element.classicinvoicenumber)">
									{{element.classicinvoicenumber != null && element.classicinvoicenumber.length > 25 ?
									(element.classicinvoicenumber | slice:0:25) + ".." : element.classicinvoicenumber}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="crminvoicename" sticky *ngIf="getFreeze('crminvoicename')">
							<th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="invoice-freeze-sort">
								Invoice</th>
							<td mat-cell sticky *matCellDef="let element">
								<button mat-button (click)="showDetail('Classic Invoice', element.crminvoicename)">
									{{element.crminvoicename != null && element.crminvoicename.length > 25 ?
									(element.crminvoicename | slice:0:25) + ".." : element.crminvoicename}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="ponumber" *ngIf="!getFreeze('ponumber')">
							<th mat-header-cell *matHeaderCellDef>Purchase Order</th>
							<td mat-cell *matCellDef="let element">
								<button mat-button (click)="showDetail('Purchase Order', element.ponumber)">
									{{element.ponumber != null && element.ponumber.length > 25 ?
									(element.ponumber | slice:0:25) + ".." : element.ponumber}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="productname" *ngIf="!getFreeze('productname')">
							<th mat-header-cell *matHeaderCellDef>Product</th>
							<td mat-cell *matCellDef="let element">
								<button mat-button (click)="showDetail('Product', element.productname)">
									{{element.productname}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="entitlement" *ngIf="!getFreeze('entitlement')">
							<th mat-header-cell *matHeaderCellDef>Entitlement</th>
							<td mat-cell *matCellDef="let element">
								<button mat-button (click)="showDetail('Entitlement', element.tenasysentitlementid)">
									{{element.tenasysentitlementid}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="suac" *ngIf="!getFreeze('suac')">
							<th mat-header-cell *matHeaderCellDef>Suac</th>
							<td mat-cell *matCellDef="let element">
								<button mat-button (click)="showDetail('Suac', element.suac)">
									{{element.suac}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="isactive" *ngIf="!getFreeze('isactive')">
							<th mat-header-cell *matHeaderCellDef>Licensed</th>
							<td mat-cell *matCellDef="let element">
								<button mat-button (click)="showDetail('Licensed', element.licensed)">
									{{element.licensed}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="license" *ngIf="!getFreeze('license')">
							<th mat-header-cell *matHeaderCellDef>License</th>
							<td mat-cell *matCellDef="let element">
								<button mat-button (click)="showDetail('License', element.license)">
									{{element.license != null && element.license.length > 25 ? (element.license |
									slice:0:25) + ".." :
									element.license}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="activateddate" *ngIf="!getFreeze('activateddate')">
							<th mat-header-cell *matHeaderCellDef mat-sort-header
								data-testid="licensedate-unfreeze-sort">Activated On</th>
							<td mat-cell *matCellDef="let element">
								<button mat-button (click)="showDetail('Activated On', element.activateddate)">
									{{element.activateddate | date:'mediumDate'}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="activatedby" *ngIf="!getFreeze('activatedby')">
							<th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="licenseby-unfreeze-sort">
								Licensed By</th>
							<td mat-cell *matCellDef="let element">
								<button mat-button (click)="showDetail('Licensed By', element.activatedby)">
									{{element.activatedby == element.tenasysentitlementid ? 'Entitlement' :
									element.activatedby}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="comment" *ngIf="!getFreeze('comment')">
							<th mat-header-cell *matHeaderCellDef>Activation Comment</th>
							<td mat-cell *matCellDef="let element" matTooltip="{{element.customeractivationcomment}}"
								matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">
								<button mat-button
									(click)="showDetail('Customer Activation Comment', element.customeractivationcomment)">
									{{element.customeractivationcomment != null &&
									element.customeractivationcomment.length > 25 ?
									(element.customeractivationcomment | slice:0:25) + ".." :
									element.customeractivationcomment}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="ipaddress" *ngIf="!getFreeze('ipaddress')">
							<th mat-header-cell *matHeaderCellDef>Licensed IP Address</th>
							<td mat-cell *matCellDef="let element">
								<button mat-button (click)="showDetail('License IP Address', element.ipaddress)">
									{{element.ipaddress}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="classiclicense" *ngIf="!getFreeze('classiclicense')">
							<th mat-header-cell *matHeaderCellDef
								matTooltip="Imported by EntitlementImporter from the SUAC Manager"
								matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">Classic License</th>
							<td mat-cell *matCellDef="let element">
								<button mat-button (click)="showDetail('Classic License', element.classiclicense)">
									{{element.classiclicense != null && element.classiclicense.length > 25 ?
									(element.classiclicense |
									slice:0:25) + ".." : element.classiclicense}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="cidclassiclicense" *ngIf="!getFreeze('cidclassiclicense')">
							<th mat-header-cell *matHeaderCellDef matTooltip="Created by USB/CID Onboarding Tool"
								matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">CID Classic License</th>
							<td mat-cell *matCellDef="let element">
								<button mat-button (click)="showDetail('CID Classic License', element.shortlicense)">
									{{element.shortlicense != null && element.shortlicense.length > 25 ?
									(element.shortlicense |
									slice:0:25) + ".." : element.shortlicense}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="fingerprint" *ngIf="!getFreeze('fingerprint')">
							<th mat-header-cell *matHeaderCellDef>Fingerprint</th>
							<td mat-cell *matCellDef="let element">
								<button mat-button (click)="showDetail('Fingerprint', element.fingerprint)">
									{{element.fingerprint != null && element.fingerprint.length > 25 ?
									(element.fingerprint | slice:0:25)
									+
									".." : element.fingerprint}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="usbkey" *ngIf="!getFreeze('usbkey')">
							<th mat-header-cell *matHeaderCellDef>USB/CID Key</th>
							<td mat-cell *matCellDef="let element">
								<button mat-button (click)="showDetail('USB/CID Key', element.usbkey)">
									{{element.usbkey}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="createddate" *ngIf="!getFreeze('createddate')">
							<th mat-header-cell *matHeaderCellDef>Entitlement Created Date</th>
							<td mat-cell *matCellDef="let element">
								<button mat-button
									(click)="showDetail('Entitlement Created Date', element.createddate)">
									{{element.createddate | date:'mediumDate'}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="classicorder" *ngIf="!getFreeze('classicorder')">
							<th mat-header-cell *matHeaderCellDef>Classic Order</th>
							<td mat-cell *matCellDef="let element">
								<button mat-button (click)="showDetail('Classic Order', element.classicordernumber)">
									{{element.classicordernumber != null && element.classicordernumber.length > 25 ?
									(element.classicordernumber | slice:0:25) + ".." : element.classicordernumber}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="classicinvoice" *ngIf="!getFreeze('classicinvoice')">
							<th mat-header-cell *matHeaderCellDef>Classic Invoice</th>
							<td mat-cell *matCellDef="let element">
								<button mat-button
									(click)="showDetail('Classic Invoice', element.classicinvoicenumber)">
									{{element.classicinvoicenumber != null && element.classicinvoicenumber.length > 25 ?
									(element.classicinvoicenumber | slice:0:25) + ".." : element.classicinvoicenumber}}
								</button>
							</td>
						</ng-container>
						<ng-container matColumnDef="crminvoicename" *ngIf="!getFreeze('crminvoicename')">
							<th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="invoice-unfreeze-sort">
								Invoice</th>
							<td mat-cell *matCellDef="let element">
								<button mat-button (click)="showDetail('Classic Invoice', element.crminvoicename)">
									{{element.crminvoicename != null && element.crminvoicename.length > 25 ?
									(element.crminvoicename | slice:0:25) + ".." : element.crminvoicename}}
								</button>
							</td>
						</ng-container>
						<tr mat-header-row *matHeaderRowDef="getDisplayedColumnsAll()"></tr>
						<tr mat-row *matRowDef="let row; columns: getDisplayedColumnsAll();"></tr>

						<!-- Row shown when there is no matching data.-->
						<tr class="mat-row" *matNoDataRow>
							<td class="mat-cell" colspan="4">No data matching the filter provided.</td>
						</tr>
					</table>
				</div>
				<mat-paginator [pageSize]="10" color="accent" [pageSizeOptions]="[10, 100, 200]"
					[length]="filteredRecordsTotal" showFirstLastButtons></mat-paginator>
			</div>
			<div class="exportdlsgrid">
				<div>
					<button mat-raised-button color="accent" (click)="downloadFileByRecord($event)"
						matTooltip="Click this button to download the filtered TenAsys license data selected above!"
						matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">Download License
						Data<mat-icon>arrow_downward</mat-icon></button>
				</div>
				<div>
					<ngx-spinner name="spinner_0" bdColor="white" size="small" color="#e12523" type="square-jelly-box"
						[fullScreen]="false">
						<p style="color: white">&nbsp;</p>
					</ngx-spinner>
				</div>
			</div>
		</div>
	</div>
	<div class="rightsidebar">
		&nbsp;
	</div>
</div>