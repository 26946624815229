import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Product } from '../types/Product';
import { Customer } from '../types/Customer';
import { ActivationObject } from '../types/ActivationObject';
import { Invoice } from '../types/InvoiceData';
import { AuthorizationService } from './authorization.service';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { timeout, catchError, shareReplay, retry } from 'rxjs/operators';
import { OrderData } from '../types/OrderData';
import { SnackService } from './snack.service';
import { Router } from '@angular/router';
import { PortalError } from '../types/PortalError';
import { UpdateEntitlement } from '../types/UpdateEntitlement';
import { SearchEntitlements as SearchEntitlements } from '../types/SearchEntitlements';
import { UpdateColumnRequest } from '../types/ColumnData';

@Injectable()
export class EntitlementService {
    public ERROR_MSG: string = "An error occurred.";
    private endpoint: string = environment.baseApiUrl; //'https://tenasysws.azurewebsites.net';
    private version: string = 'v1';
    private pageSize: number = 5000;
    public userMode: boolean = false;
    private contactAccountURL: string = "";
    private isNavigatingToMaintenance = false;
    constructor(private http: HttpClient,
        private auth: AuthorizationService,
        private snackMsg: SnackService,
        private router: Router
    ) { }

    public sendToMaintenance() {
        if (!this.isNavigatingToMaintenance) {
            this.isNavigatingToMaintenance = true;
            this.router.navigate(['/maintenance']);
        }
    }

    public getFromEMS<T>(url: string, timeoutSeconds: number = 120, headers: HttpHeaders = undefined): Observable<T> {
        if (this.auth.isTokenValid()) {
            if (headers == undefined) {
                headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    'token': this.auth.getSecurityToken(),
                    'user': this.auth.getUsername()
                });
            }
            let fullUrl = this.endpoint + '/api/' + this.version + '/' + url;
            return this.http.get<any>(fullUrl, { headers }).pipe(
                timeout(timeoutSeconds * 1000),
                catchError((error) => {
                    let errCode: number = 0;
                    let runId: number = 0;
                    if (error != null) {
                        if (error.status == 503) {
                            this.sendToMaintenance();
                        } else if (error.status == 400) {
                            if ('error' in error) {
                                errCode = error.error["ErrorCode"];
                                runId = error.error["RunId"];
                                var msg = this.ERROR_MSG += "\n Transaction ID (runid): " + runId.toString() + ".";
                                this.snackMsg.openSnackBar(msg, 5000 * 10);
                            } else {
                                this.snackMsg.openSnackBar(this.ERROR_MSG, 5000);
                            }
                        }
                    } else {
                        this.snackMsg.openSnackBar(this.ERROR_MSG, 5000);
                    }
                    var pe = new PortalError();
                    pe.RunId = runId;
                    pe.ErrorCode = errCode;
                    return of(pe);
                }),
                shareReplay(1)
            );
        }
        return throwError("Unauthorized", undefined);
    }

    public postToEMS<T>(url: string, body: any, timeoutSeconds: number = 60): Observable<T> {
        if (this.auth.isTokenValid()) {
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'token': this.auth.getSecurityToken(),
                'user': this.auth.getUsername()
            });
            let fullUrl = this.endpoint + '/api/' + this.version + '/' + url;
            return this.http.post<any>(fullUrl, body, { headers }).pipe(
                timeout(timeoutSeconds * 1000),
                catchError((error) => {
                    let errCode: number = 0;
                    let runId: number = 0;
                    if (error != null) {
                        if (error.status == 503) {
                            this.sendToMaintenance();
                        } else if (error.status == 400) {
                            if ('error' in error) {
                                errCode = error.error["ErrorCode"];
                                runId = error.error["RunId"];
                                var msg = this.ERROR_MSG += "\n Transaction ID (runid): " + runId.toString() + ".";

                                // 110 is order and invoice assoc with different accounts
                                if (errCode == 110) {
                                    msg += "\n Order and Invoice CRM Account mismatch.  Check CRM and try again!";
                                }
                                this.snackMsg.openSnackBar(msg, 5000 * 10);
                            } else {
                                this.snackMsg.openSnackBar(this.ERROR_MSG, 5000);
                            }
                        }
                    } else {
                        this.snackMsg.openSnackBar(this.ERROR_MSG, 5000);
                    }
                    var pe = new PortalError();
                    pe.RunId = runId;
                    pe.ErrorCode = errCode;
                    return of(pe);
                }),
                shareReplay(1)
            );
        }
        return throwError("Unauthorized", undefined);
    }

    public getUserMode(): boolean {
        return this.userMode;
    }

    public getPageSize(): number {
        return this.pageSize;
    }

    public getContactAccountURL(contactcrmid: string, page: number, pagesize: number, accountDataOnly: boolean, accountid: string = ""): string {
        var url = 'getcontactaccount/' + contactcrmid + '/' + page + '/' + pagesize + (accountDataOnly ? '/true' : '');
        if (accountid != "") {
            url += "?asUser=true&accountid=" + accountid;
        }
        this.contactAccountURL = url;
        return this.contactAccountURL;
    }

    public getAdminStatus() {
        return this.getFromEMS<any>('adminstatus/');
    }

    public GetExport<T>(accountid: string,
        filterCidState: number = 0,
        filterActivatedState: number = 0,
        filterReservedState: number = 0,
        filterProductState: string = '*',
        searchString: string = '*',
        page: number = 1,
        pageSize: number = 1000000,
        userMode: boolean = false,
        exportType: string = "standard"): Observable<Blob> {
        var timeoutSeconds = 60 * 10;
        if (this.auth.isTokenValid()) {
            let headers = new HttpHeaders({
                'Accept': 'text/csv',
                'Content-Type': 'application/json',
                'token': this.auth.getSecurityToken(),
                'user': this.auth.getUsername()
            });

            if (filterProductState.toLowerCase() == 'all' || filterProductState.toLowerCase() == '') {
                filterProductState = '*';
            }
            searchString = searchString == '' ? '*' : searchString
            var url = 'getexport/';
            url += userMode == true ? "?asUser=true" : "";
            filterCidState = Number(filterCidState);
            filterReservedState = Number(filterReservedState);
            filterActivatedState = Number(filterActivatedState);
            let body = {
                accountid: accountid,
                authToken: this.auth.getSecurityToken(),
                username: this.auth.getUsername(),
                exportType: exportType,
                filterActivated: filterActivatedState,
                filterCid: filterCidState,
                filterString: searchString,
                filterProduct: filterProductState,
                filterReserved: filterReservedState,
                page: page,
                pagesize: pageSize
            };
            let fullUrl = this.endpoint + '/api/' + this.version + '/' + url;
            return this.http.post(fullUrl, body, { responseType: 'blob', headers: headers })
                .pipe(
                    timeout(timeoutSeconds * 1000),
                    catchError(e => { throw e; }),
                    shareReplay(1)
                );
        }
        return throwError("Unauthorized", undefined);
    }

    public getUsbDownload<T>(batchid: string) {
        var timeoutSeconds = 60 * 5;
        let body = {};
        var url = "getusbdownload/" + batchid;
        //return this.postToEMS<Blob>(url, body);

        let headers = new HttpHeaders({
            'Accept': 'text/csv',
            'Content-Type': 'application/json',
            'token': this.auth.getSecurityToken(),
            'user': this.auth.getUsername()
        });

        let fullUrl = this.endpoint + '/api/' + this.version + '/' + url;
        return this.http.post(fullUrl, body, { responseType: 'blob', headers: headers })
            .pipe(
                timeout(timeoutSeconds * 1000),
                catchError(e => { throw e; }),
                shareReplay(1)
            );
    }

    public getEntitlements(accountid: string,
        filterCidState: number = 0,
        filterActivatedState: number = 0,
        filterReservedState: number = 0,
        filterProductState: string = '*',
        searchString: string = '*',
        page: number = 1,
        pageSize: number = this.pageSize,
        userMode: boolean = false,
        exportType: string = "standard") {
        filterProductState = filterProductState.toLowerCase() == 'all' ? '*' : filterProductState
        searchString = searchString == '' ? '*' : searchString
        var url = 'getentitlements/';
        url += userMode == true ? "?asUser=true" : "";
        filterCidState = Number(filterCidState);
        filterReservedState = Number(filterReservedState);
        filterActivatedState = Number(filterActivatedState);
        let body = {
            accountid: accountid,
            authToken: this.auth.getSecurityToken(),
            username: this.auth.getUsername(),
            exportType: exportType,
            filterActivated: filterActivatedState,
            filterCid: filterCidState,
            filterString: searchString,
            filterProduct: filterProductState,
            filterReserved: filterReservedState,
            page: page,
            pagesize: pageSize
        };
        return this.postToEMS<any>(url, body, 60 * 10);
    }

    public getOrders(accountid: string) {
        return this.getFromEMS<Array<OrderData>>('getorders/' + accountid);
    }

    public getInvoices(orderid: string) {
        return this.getFromEMS<Array<Invoice>>('getinvoices/' + orderid);
    }

    public getProducts() {
        return this.getFromEMS<Array<Product>>('getproducts');
    }

    public getTypes() {
        return this.getFromEMS<string[]>('gettypes');
    }

    public getCustomers() {
        return this.getFromEMS<Array<Customer>>('getcustomers', 240);
    }

    public getContact() {
        return this.getFromEMS<Array<Customer>>('getcontact', 240);
    }

    public isContact(email: string) {
        return this.getFromEMS<Array<Customer>>('iscontact/' + email, 240);
    }

    public getAccountAdmin(accountid: string, page: number = 1, pagesize: number = this.pageSize, accountDataOnly: boolean = true) {
        return this.getFromEMS<any>('getaccount/' + accountid + '/' + page + '/' + pagesize + (accountDataOnly ? '/true' : ''));
    }

    public getAccountInvoices(accountid: string) {
        return this.getFromEMS<any>('getaccountinvoices/' + this.auth.getUsername() + '/' + this.auth.getSecurityToken() + '/' + accountid);
    }

    public getUserSettings(crmuserid:string, asuser:boolean=false, action:string="entitlement-details") {
        let call = `getuser/${crmuserid}/${action}/${asuser}`;
        return this.getFromEMS<any>(call);
    }

    public getReservationTypes() {
        return this.getFromEMS<any>('getreservationinformation/' + this.auth.getUsername() + '/' + this.auth.getSecurityToken());
    }

    public getAccountSummaryAdmin(accountid: string) {
        let url = 'getaccountsummary/' + accountid;
        if (this.userMode == true) {
            url += "?asUser=true"
        }
        return this.getFromEMS<any>(url);
    }

    public getLogs(page: number = 1, pagesize: number = this.pageSize) {
        return this.getFromEMS<any>('logs/' + page + '/' + pagesize);
    }

    public getUSBExports(entitlements: Array<string>) {
        return this.getFromEMS<any>('getusbexports/' + this.auth.getUsername() + '/' + this.auth.getSecurityToken() + '/' + entitlements.reduce((a, b) => a + ',' + b));
    }

    public addEntitlements(addProduct: any) {
        if (this.auth.isTokenValid()) {
            return this.postToEMS<any>('addentitlements', addProduct);
        }
        return null;
    }

    public decodeLicense(licString: string) {
        if (this.auth.isTokenValid()) {
            var body: any = {
                licString: licString,
                includeEndCustomer: true
            };
            return this.postToEMS<any>('decodelicense', body);
        }
        return null;
    }

    public decodeFingerprint(fp: string) {
        if (this.auth.isTokenValid()) {
            var body: any = {
                fp: fp
            };
            return this.postToEMS<any>('decodefingerprint', body);
        }
        return null;
    }

    public SearchEntitlements(searchFilter: SearchEntitlements) {
        if (this.auth.isTokenValid()) {
            return this.postToEMS<any>('adminsearch', searchFilter);
        }
        return null;
    }

    public saveEntitlementDetailColumns(columns: UpdateColumnRequest) {
        if (this.auth.isTokenValid()) {
            return this.postToEMS<any>('updateentitlementtable', columns);
        }
        return null;
    }

    public saveTimeLimitedColumns(columns: UpdateColumnRequest) {
        if (this.auth.isTokenValid()) {
            return this.postToEMS<any>('updatetimelimitedtable', columns);
        }
        return null;
    }

    /** The endpoint used when editing an entitlement */
    public UpdateEntitlement(entitlement: UpdateEntitlement) {
        if (this.auth.isTokenValid()) {
            return this.postToEMS<any>('updent', entitlement);
        }
        return null;
    }

    public addUSBEntitlements(addProduct: any) {
        if (this.auth.isTokenValid()) {
            return this.postToEMS<any>('addUSBEntitlements', addProduct);
        }
        return throwError("Unauthorized", undefined);
    }

    public getAdminExportURL(flags: string, productname: string, accountid: string, page: number = 1, pagesize: number = this.pageSize) {
        return this.endpoint + '/api/' + this.version + '/getentitlementsadminexport/' + this.auth.getUsername() + '/' + this.auth.getSecurityToken() + '/' + accountid + '/' + productname + '/' + flags + '/' + page + '/' + pagesize;
    }

    public timeLimitedActivate(entitlementID: string, fingerprint: string) {
        if (this.auth.isTokenValid()) {
            var body: any = {
                entitlementID: entitlementID,
                fingerprint: fingerprint,
            };
            return this.postToEMS<any>('timelimitedactivate', body);
        }
        return throwError("Unauthorized", undefined);
    }

    public getAddEntitlementsExport(batchid: number) {
        return this.endpoint + '/api/' + this.version + '/addentitlementsexport/' + this.auth.getUsername() + '/' + this.auth.getSecurityToken() + '/' + batchid;
    }

    public getTimeLimitedEntitlements(page: number = 1, pagesize: number = this.pageSize) {
        return this.getFromEMS<any>('gettimelimitedentitlements/' + page + '/' + pagesize);
    }

    public setUpdateOperationsManager(body: any) {
        return this.postToEMS<any>('setoperationsmanager', body);
    }


    //Ops Manager

    public getAccountUser(url: string) {
        return this.getFromEMS<any>(url, 60 * 15);
    }

    public getAccountSummaryUser(contactcrmid: string) {
        let url = 'getaccountsummary/' + contactcrmid;

        if (this.userMode == true) {
            url += "?asUser=true"
        }
        return this.getFromEMS<any>(url);
    }

    public getCounts(accountid: string) {
        return this.getFromEMS<any>('getcountsbyaccount/' + accountid);
    }

    public activateEntitlement(activationObject: ActivationObject) {
        return this.postToEMS<any>('activate', activationObject);
    }

    public getContacts(contactcrmid: string) {
        return this.getFromEMS<any>('getcontactsbytechnicalcontact/' + contactcrmid);
    }

    public changeReservedState(product: string, count: number, comment: string) {
        let body = {
            product: product,
            count: count,
            comment: comment
        };
        return this.postToEMS<any>('updatereserved', body);
    }

    public addContact(firstname: string, lastname: string, email: string, password: string, crmprimaryid: string) {
        let body = {
            firstname,
            lastname,
            email,
            password,
            crmprimaryid
        };
        return this.postToEMS<any>('addcontacttocompany', body);
    }

    public removeContact(contactid: string, email: string, crmprimaryid: string) {
        return this.getFromEMS<any>('removecontactfromcompany/' + contactid + '/' + crmprimaryid);
    }

    public resetPassword(username: string) {
        return this.getFromEMS<any>('resetpassword/' + username);
    }

    public resetDefaultPassword(username: string) {
        return this.getFromEMS<any>('setdefaultpassword/' + username);
    }

    public getStatus() {
        return this.getFromEMS<any>('status/');
    }

    public updateContactPassword(email: string, password: string, crmprimaryid: string) {
        let body = {
            email,
            password,
            crmprimaryid
        };
        return this.postToEMS<any>('updatecontact', body);
    }

    public updatePassword(oldPassword: string, newPassword: string) {
        let body = { oldpassword: oldPassword, newpassword: newPassword };
        return this.postToEMS<any>('updatepassword', body);
    }

    //Exports:

    public getExportURL(contactid: string, flags: string, productname: string, page: number = 1, pagesize: number = this.pageSize, accountid: string = "") {
        var url = this.endpoint + '/api/' + this.version + '/getentitlementsexport/' + this.auth.getUsername() + '/' + this.auth.getSecurityToken() + '/' + contactid + '/' + productname + '/' + flags + '/' + page + '/' + pagesize;
        if (accountid != "") {
            url += "?asUser=true&accountid=" + accountid + "&sortcolumn=id&sortdirection=1";
        } else {
            url += "?sortcolumn=id&sortdirection=1";
        }
        return url;
    }

    public getExportUSBURL(accountid: string, createbatchid: number) {
        return this.endpoint + '/api/' + this.version + '/getusbexports/' + accountid + '/' + this.auth.getUsername() + '/' + this.auth.getSecurityToken() + '/' + createbatchid;
    }

    public getLicenseFile(accountid: string, entitlement: string) {
        return this.endpoint + '/api/' + this.version + '/licenseexport/' + accountid + '/' + entitlement;
    }

    public getUSBKeyLicense(accountid: string, usbserial: string): string {
        return this.endpoint + '/api/' + this.version + '/getusblicense/' + accountid + '/' + usbserial;
    }

    public GetCidInfo<CidLookup>(usbid: string): Observable<CidLookup> {
        if (this.auth.isTokenValid()) {
            let urlStub = '/getcidlicense/' + usbid;
            let cidLookup = this.getFromEMS<CidLookup>(urlStub).pipe(shareReplay(1));
            return cidLookup;
        }
        return throwError("Unauthorized", undefined);
    }
}