<div class="mainbox">
	<div class="leftsidebar">
		&nbsp;
	</div>
	<div class="submainbox">
		<div>
			<mat-card appearance="outlined" *ngIf="adduser == true">
				<mat-card-header>
					<mat-card-title>Add User</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<div class="addusergrid">
						<div>
							<mat-form-field>
								<input matInput placeholder="First Name" [formControl]="firstname" required
									spellcheck="false">
								<mat-error *ngIf="firstname.hasError('required')">
									First name is <strong>required</strong>
								</mat-error>
								<mat-error *ngIf="firstname.hasError('minlength')">
									First name cannot be blank
								</mat-error>
							</mat-form-field>
							<mat-form-field>
								<input matInput placeholder="Last Name" type="text" name="lastname" id="lastname"
									[formControl]="lastname" required spellcheck="false">
								<mat-error *ngIf="lastname.hasError('required')">
									Last name is <strong>required</strong>
								</mat-error>
								<mat-error *ngIf="lastname.hasError('minlength')">
									Last name cannot be blank
								</mat-error>
							</mat-form-field>
							<mat-form-field>
								<mat-label>Email</mat-label>
								<input matInput [formControl]="email" placeholder="Ex. pat@example.com">
								<mat-error *ngIf="email.hasError('email') && !email.hasError('required')">Please enter a
									valid email
									address</mat-error>
								<mat-error *ngIf="email.hasError('required')">Email is
									<strong>required</strong></mat-error>
							</mat-form-field>
							<mat-form-field>
								<mat-label>Temporary Password</mat-label>
								<input matInput type="text" name="password" id="password" [formControl]="password"
									readonly>
							</mat-form-field>
						</div>
						<div mat-dialog-actions>
							<div class="buttongrid">
								<div><button color="accent" mat-raised-button cdkFocusInitial (click)="savenewuser()"
										[disabled]="newuseradded">Save</button>
								</div>
								<div><button mat-raised-button (click)="onNoClick()">
										<span>Cancel</span>
										<mat-icon class="button-icon">cancel</mat-icon></button>
								</div>
							</div>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card appearance="outlined" *ngIf="adduser != true">
				<mat-card-header>
					<mat-card-title>Add User</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<div class="addusergrid">
						<div class="table">
							<table mat-table density="-4" [dataSource]="dataSource">
								<ng-container matColumnDef="name">
									<th mat-header-cell *matHeaderCellDef> Name </th>
									<td mat-cell *matCellDef="let element">{{element.name}}</td>
								</ng-container>
								<ng-container matColumnDef="email">
									<th mat-header-cell *matHeaderCellDef> Email </th>
									<td mat-cell *matCellDef="let element">{{element.email}}</td>
								</ng-container>
								<ng-container matColumnDef="deactivate">
									<th mat-header-cell *matHeaderCellDef>Remove User?</th>
									<td mat-cell *matCellDef="let element"><button mat-stroked-button
											(click)="remove(element.id)">Remove</button></td>
								</ng-container>
								<ng-container matColumnDef="changepassword">
									<th mat-header-cell *matHeaderCellDef>Reset Password</th>
									<td mat-cell *matCellDef="let element"> <button mat-stroked-button
											(click)="updatepassword(element)">
											Reset
											Password </button></td>
								</ng-container>
								<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
								<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
							</table>
						</div>
						<div>
							<div class="buttongrid">
								<div><button mat-raised-button color="accent" (click)="add()">Add User</button>
								</div>
								<div><button mat-raised-button (click)="goback()">
										<span>Back</span></button>
								</div>
							</div>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div class="rightsidebar">
		&nbsp;
	</div>
</div>