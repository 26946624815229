import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { CustomerDataService } from '../../services/customer-data.service';
import { AuthorizationService } from '../../services/authorization.service';

@Component({
  selector: 'app-log-off',
  templateUrl: './log-off.component.html',
  styleUrls: [
    '../../shared-styles/divs.scss',
    '../../shared-styles/tables.scss',
    '../../shared-styles/elements.scss',
    './log-off.component.scss'
]
})
export class LogOffComponent {
  constructor(private router: Router,
    private location: Location,
    private customerData: CustomerDataService,
    private auth: AuthorizationService,)
  {
  }
  
  public navTo(newRoute: string) {
    if (newRoute == "back") {
      this.location.back();
    } else if (newRoute == "login") {
      this.auth.reset();
      this.customerData.reset();
      this.router.navigate(['/login/']);
    } else {
      this.router.navigate(['/login/']);
    }
  }
}
