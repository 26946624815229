import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-adminmodal',
  templateUrl: './adminmodal.component.html',
  styleUrls: [
    '../../../../shared-styles/divs.scss',
    '../../../../shared-styles/tables.scss',
    '../../../../shared-styles/elements.scss',
    './adminmodal.component.scss'
  ]
})
export class AdminmodalComponent implements OnInit {
  exportURL: string = "";
  entitlementsURL: string = "";
  entitlementsExportName: string = "";

  constructor(public dialogRef: MatDialogRef<AdminmodalComponent>, @Inject(MAT_DIALOG_DATA) public data: {
    exportURL: string,
    entitlementsURL: string,
    entitlementsExportName: string
  }) {
    this.exportURL = data.exportURL;
    this.entitlementsURL = data.entitlementsURL;
    this.entitlementsExportName = data.entitlementsExportName;
  }

  ngOnInit(): void { }

  closeDialog() {
    this.dialogRef.close('Closing!');
  }
}
