import { Component, Inject, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizationService } from 'src/app/modules/services/authorization.service';
import { CustomerDataService } from 'src/app/modules/services/customer-data.service';
import { EntitlementService } from 'src/app/modules/services/ems.service';
import { SnackService } from 'src/app/modules/services/snack.service';
import { ActivationObject } from 'src/app/modules/types/ActivationObject';
import { LicenseData } from 'src/app/modules/types/LicenseData';

@Component({
  selector: 'app-volumepopup',
  templateUrl: './volumepopup.component.html',
  styleUrls: ['./volumepopup.component.scss']
})
export class VolumepopupComponent implements OnInit {
  @ViewChild('proceedBtn', {read: ElementRef, static: false}) proceedbtn: ElementRef; 
  licenseResults: LicenseData = new LicenseData();
  proceedclicked: boolean = false;
  constructor(private ems: EntitlementService,
    public auth: AuthorizationService,
    private customerData: CustomerDataService,
    public snackMsg: SnackService,
    private spinner: NgxSpinnerService,
    public VolumepopupComponentRef: MatDialogRef<VolumepopupComponent>,    
    @Inject(MAT_DIALOG_DATA) public data: { activationObject: ActivationObject }) {  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.proceedbtn.nativeElement.focus();
  }

  closeDialog() {
    this.VolumepopupComponentRef.close(this.licenseResults);
  }

  public action(action: string) {
    if (action.toLowerCase() == "cancel") {
      this.closeDialog();
    } else if (action.toLowerCase() == "proceed") {
      this.spinner.show("modalspinner");
      this.proceedclicked = true;
      this.data.activationObject.force = true;
      this.ems.activateEntitlement(this.data.activationObject).subscribe((next: any) => {
        if (next === null) {
          this.spinner.hide("spinneronly");
          this.spinner.hide("modalspinner");
          return;
        }
        let raw = JSON.stringify(next);
        let data = JSON.parse(raw);
        if (data.ErrorCode !== undefined || data == "Failed to Activate.") {
          this.snackMsg.openSnackBar("A problem occurred during license activation.", 5000);
        } else {
          this.licenseResults.license = data.license;
          this.licenseResults.tenasysentitlementid = data.entitlement;
          this.licenseResults.licenseUrl = this.customerData != null && this.customerData.accountData != null ? 
            this.ems.getLicenseFile(this.customerData.accountData.accountid, data.entitlement) : undefined;
          this.closeDialog();
        }
        this.spinner.hide("spinneronly");
        this.spinner.hide("modalspinner");
      }, () => {
        this.snackMsg.openSnackBar("An Error Has Occurred.", 5000);
        this.spinner.hide("spinneronly");
        this.spinner.hide("modalspinner");
      });
    }
  }
}

