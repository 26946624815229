<div class="modal">
    <div>
        <h2 mat-dialog-title><span>{{data.datacolumn}}</span></h2>
    </div>
    <mat-dialog-content>
        <ngx-spinner name="spinner_view" size="medium" type="ball-spin-clockwise" color="#e12523">
            <!-- <p class="loading">Loading...</p> -->
        </ngx-spinner>
        <div class="celldata">
            <div *ngIf="this.data.fingerprint != '' || this.data.license != ''">
                <mat-expansion-panel class="licensecontainer" #matExpansionPanelAccount [expanded]="this.expand">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{data.datacolumn}} Information
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="resultgrid">
                        <div><span>
                                <pre>{{data.datavalue}}</pre>
                            </span>
                        </div>
                        <div><span>
                                <pre>{{this.dataresult}}</pre>
                            </span>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>
            <div *ngIf="this.data.fingerprint == '' && this.data.license == ''">
                <pre>{{data.datavalue}}</pre>
            </div>
        </div>
        <div class="buttons">
            <mat-dialog-actions>
                <a mat-raised-button *ngIf="this.data.url != ''" mat-stroked-button href="{{this.data.url}}"
                    target="_blank">Open CRM
                </a>
                <button mat-raised-button color="accent" (click)="copy(this.data.datavalue)" value="Copy">Copy</button>
                <button mat-raised-button *ngIf="this.data.fingerprint != '' && this.auth.getUserType() == 4"
                    (click)="decodefingerprint(this.data.fingerprint)">Decode</button>
                <button mat-raised-button *ngIf="this.data.license != '' && this.auth.getUserType() == 4"
                    (click)="decodelicense(this.data.license)">Decode</button>
                <button mat-raised-button mat-dialog-close>Close</button>
            </mat-dialog-actions>
        </div>
    </mat-dialog-content>
</div>