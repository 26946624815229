export class CidLookup {
    enhanced_licenses: string = "";
    classic_licenses: string = "";

    constructor(private licenses:string) { 
        var obj = JSON.parse(licenses);
        this.enhanced_licenses = obj.enhanced_licenses[0];
        this.classic_licenses = obj.classic_licenses[0];
    }
}
