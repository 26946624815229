<div class="main" *ngIf="showSplash" data-testid="splash-is-present">
    <div class="sub">
        <div class="gridmain">
            <div>Loading</div>
            <div class="gridtenasys">
                <div>Ten</div>
                <div class="biga">A
                    <div class="clipa1">&nbsp;</div>
                    <div class="clipa2">&nbsp;</div>
                </div>
                <div>sys</div>
            </div>
            <div>Data</div>
        </div>
        <div class="pbwrapper">
            <mat-progress-bar color="accent" class="pb" mode="determinate" [value]="progressbarValue">
            </mat-progress-bar>
        </div>
    </div>
    <div class="app-splash-screen">&nbsp;</div>    
</div>

<div class="greyout" *ngIf="showSplash">&nbsp;</div>