<div class="mainbox">
    <div class="leftsidebar">
        &nbsp;
    </div>
    <div class="submainbox">
        <div>
            <mat-card appearance="outlined" class="accountCard">
                <mat-card-content>
                    <div *ngIf="nouserset == true">
                        <p>No Operations Manager is set yet for this account. Once one is indicated within the CRM, we
                            can set their
                            password here.</p>
                    </div>
                    <div *ngIf="nouserset != true">
                        <div class="frmIns">
                            <p>Use the &quot;Reset password to default&quot; button below to reset the TenAsys
                                Operations Manager
                                password.<br /><br />The new password will be provided
                                upon completion!</p>
                        </div>
                        <div class="resetopsmanform">
                            <form [formGroup]="opsManagerForm">
                                <div class="formgrid">
                                    <mat-label>Current TenAsys Operations Manager:</mat-label>
                                    <div>
                                        <mat-form-field disabled>
                                            <mat-label>First Name</mat-label>
                                            <input matInput formControlName="firstname" placeholder="First Name"
                                                type="text" name="firstname" id="firstname" required spellcheck="false"
                                                readonly>
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <mat-form-field disabled>
                                            <mat-label>Last Name</mat-label>
                                            <input matInput formControlName="lastname" placeholder="Last Name"
                                                type="text" name="lastname" id="lastname" required spellcheck="false"
                                                readonly>
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <mat-form-field disabled>
                                            <mat-label>Email Address</mat-label>
                                            <input matInput formControlName="email" placeholder="Email Address"
                                                type="email" name="email" id="email" required spellcheck="false"
                                                readonly>
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="resetdone">
                                        <mat-form-field>
                                            <mat-label>New Password</mat-label>
                                            <input matInput formControlName="newpassword" type="text" name="newpassword"
                                                id="newpassword" required spellcheck="false" readonly>
                                        </mat-form-field>
                                    </div>
                                    <div class="buttongrid">
                                        <button mat-raised-button color="accent" (click)="resetPassword()">Reset
                                            password to
                                            default<mat-icon>lock_reset</mat-icon></button>
                                        <button mat-button (click)="returnToAccountPage()">Return to Account
                                            page</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="rightsidebar">
        &nbsp;
    </div>
</div>