<!-- <div class="tableform">
      <form>
        <mat-form-field>
          <mat-label>Filter by Text</mat-label>
          <input matInput (keyup)="applyFilterEvent($event)"
            placeholder="Enter text string to search (Date fields are not searchable)" spellcheck="false">
          <mat-icon matSuffix style="font-size: 1.2em">search</mat-icon>
        </mat-form-field>
      </form>
    </div> -->
<div class="mainbox">
  <div class="leftsidebar">
    &nbsp;
  </div>
  <div class="submainbox">
    <div class="grid">
      <div>
        <div class="filtergrid">
          <div>
            <mat-form-field>
              <mat-label>Filter by Text</mat-label>
              <input matInput data-testid="filter-by-text" (keyup)="applyFilterEvent($event)"
                placeholder="Enter text string to search (Date fields are not searchable)" spellcheck="false">
              <mat-icon matSuffix style="font-size: 1.2em">search</mat-icon>
            </mat-form-field>
          </div>
          <div><button mat-button (click)="selectColumns()">Columns</button></div>
        </div>
      </div>
      <div class="detailstable">
        <div id="table" class="table">
          <table mat-table density="-4" [dataSource]="dataSource" matSort #table>
            <ng-container matColumnDef="activateddate" sticky *ngIf="getFreeze('activateddate')">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Licensed On</th>
              <td mat-cell *matCellDef="let element">
                {{element.activateddate | date:'mediumDate'}}
              </td>
            </ng-container>
            <ng-container matColumnDef="internalcomment" sticky *ngIf="getFreeze('internalcomment')">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Internal Comment</th>
              <td mat-cell *matCellDef="let element" matTooltip="{{element.internalcomment}}"
                matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">
                <button mat-button (click)="showDetail('Internal Comment', element.internalcomment)">
                  {{element.internalcomment != null && element.internalcomment.length > 25 ? (element.internalcomment
                  |
                  slice:0:25) + ".." : element.internalcomment}}
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="accountname" sticky *ngIf="getFreeze('accountname')">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
              <td mat-cell *matCellDef="let element">
                <button mat-button
                  (click)="showDetail('Customer', element.accountname)">{{element.accountname}}</button>
              </td>
            </ng-container>
            <ng-container matColumnDef="productname" sticky *ngIf="getFreeze('productname')">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
              <td mat-cell *matCellDef="let element">
                <button mat-button (click)="showDetail('Product', element.productname)">{{element.productname}}</button>
              </td>
            </ng-container>
            <ng-container matColumnDef="enddate" sticky *ngIf="getFreeze('enddate')">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
              <td mat-cell *matCellDef="let element">
                <button mat-button (click)="showDetail('End Date', element.enddate)">{{element.enddate |
                  date:'mediumDate'
                  }}</button>
              </td>
            </ng-container>
            <ng-container matColumnDef="daystolive" sticky *ngIf="getFreeze('daystolive')">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Days</th>
              <td mat-cell *matCellDef="let element">
                <button mat-button (click)="showDetail('Days', element.daystolive)">{{element.daystolive}}</button>
              </td>
            </ng-container>
            <ng-container matColumnDef="license" sticky *ngIf="getFreeze('license')">
              <th mat-header-cell *matHeaderCellDef>License</th>
              <td mat-cell *matCellDef="let element">
                <button mat-button (click)="showDetail('License', element.license)">
                  {{element.license != null && element.license.length > 25 ? (element.license | slice:0:25) + ".." :
                  element.license}}
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="fingerprint" sticky *ngIf="getFreeze('fingerprint')">
              <th mat-header-cell *matHeaderCellDef>Fingerprint</th>
              <td mat-cell *matCellDef="let element">
                <button mat-button (click)="showDetail('Fingerprint', element.fingerprint)">
                  {{element.fingerprint != null && element.fingerprint.length > 25 ? (element.fingerprint |
                  slice:0:25) +
                  ".." : element.fingerprint}}
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="ipaddress" sticky *ngIf="getFreeze('ipaddress')">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>IP Address</th>
              <td mat-cell *matCellDef="let element">
                <button mat-button (click)="showDetail('IP Address', element.ipaddress)">{{element.ipaddress}}</button>
              </td>
            </ng-container>
            <ng-container matColumnDef="timelimitedtype" sticky *ngIf="getFreeze('timelimitedtype')">
              <th mat-header-cell *matHeaderCellDef>Type</th>
              <td mat-cell *matCellDef="let element">{{element.timelimitedtype}}</td>
            </ng-container>
            <ng-container matColumnDef="activateddate" *ngIf="!getFreeze('activateddate')">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Activated On</th>
              <td mat-cell *matCellDef="let element">
                <button mat-button
                  (click)="showDetail('Activated On', element.activateddate)">{{element.activateddate}}</button>
              </td>
            </ng-container>
            <ng-container matColumnDef="internalcomment" *ngIf="!getFreeze('internalcomment')">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Internal Comment</th>
              <td mat-cell *matCellDef="let element" matTooltip="{{element.internalcomment}}"
                matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">
                <button mat-button (click)="showDetail('Internal Comment', element.internalcomment)">
                  {{element.internalcomment != null && element.internalcomment.length > 25 ? (element.internalcomment
                  |
                  slice:0:25) + ".." : element.internalcomment}}
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="accountname" *ngIf="!getFreeze('accountname')">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
              <td mat-cell *matCellDef="let element">
                <button mat-button
                  (click)="showDetail('Customer', element.accountname)">{{element.accountname}}</button>
              </td>
            </ng-container>
            <ng-container matColumnDef="productname" *ngIf="!getFreeze('productname')">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
              <td mat-cell *matCellDef="let element">
                <button mat-button (click)="showDetail('Product', element.productname)">{{element.productname}}</button>
              </td>
            </ng-container>
            <ng-container matColumnDef="enddate" *ngIf="!getFreeze('enddate')">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
              <td mat-cell *matCellDef="let element">
                <button mat-button (click)="showDetail('End Date', element.enddate)">{{element.enddate |
                  date:'mediumDate'
                  }}</button>
              </td>
            </ng-container>
            <ng-container matColumnDef="daystolive" *ngIf="!getFreeze('daystolive')">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Days</th>
              <td mat-cell *matCellDef="let element">
                <button mat-button (click)="showDetail('Days', element.daystolive)">{{element.daystolive}}</button>
              </td>
            </ng-container>
            <ng-container matColumnDef="license" *ngIf="!getFreeze('license')">
              <th mat-header-cell *matHeaderCellDef>License</th>
              <td mat-cell *matCellDef="let element">
                <button mat-button (click)="showDetail('License', element.license)">
                  {{element.license != null && element.license.length > 25 ? (element.license | slice:0:25) + ".." :
                  element.license}}
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="fingerprint" *ngIf="!getFreeze('fingerprint')">
              <th mat-header-cell *matHeaderCellDef>Fingerprint</th>
              <td mat-cell *matCellDef="let element">
                <button mat-button (click)="showDetail('Fingerprint', element.fingerprint)">
                  {{element.fingerprint != null && element.fingerprint.length > 25 ? (element.fingerprint |
                  slice:0:25) +
                  ".." : element.fingerprint}}
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="ipaddress" *ngIf="!getFreeze('ipaddress')">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>IP Address</th>
              <td mat-cell *matCellDef="let element">
                <button mat-button (click)="showDetail('IP Address', element.ipaddress)">{{element.ipaddress}}</button>
              </td>
            </ng-container>
            <ng-container matColumnDef="timelimitedtype" *ngIf="!getFreeze('timelimitedtype')">
              <th mat-header-cell *matHeaderCellDef>Type</th>
              <td mat-cell *matCellDef="let element">{{element.timelimitedtype}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="getDisplayedColumnsAll()"></tr>
            <tr mat-row *matRowDef="let row; columns: getDisplayedColumnsAll();"></tr>
            <!-- Row shown when there is no matching data.-->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No data matching the filter provided.</td>
            </tr>
          </table>
        </div>
        <mat-paginator [pageSize]="10" color="accent" [pageSizeOptions]="[10, 100, 200, totalEntitlements]"
          [length]="totalEntitlements" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>
<div class="rightsidebar">
  &nbsp;
</div>