<div style="height: 100%;">
    <mat-card appearance="outlined" class="mat-elevation-z0" style="height: 100%;">
        <mat-card-header>
            <div class="headergrid">
                <div>
                    <mat-card-title>Process Completed&nbsp;&nbsp;&ndash;</mat-card-title>
                </div>
                <div>
                    <button class="closeBtn" mat-button mat-dialog-close>Close</button>
                </div>
            </div>
        </mat-card-header>
        <mat-card-content>
            <div>
                <div class="results" *ngIf="entitlementsURL">
                    <div>
                        <label mat-label>Entitlement Result File (CSV Format):</label><br />
                        <a mat-button color="accent" class="hlink" href="{{entitlementsURL}}" download="{{entitlementsExportName}}">New
                            Entitlements</a>
                    </div>
                </div>
                <div *ngIf="exportURL" class="importgrid results">
                    <div>
                        <span>Download this file to use with the TenAsys Onboarding Tool:<br><a mat-button color="accent" class="hlink"
                                href="{{exportURL}}">USB/CID Import
                                File</a></span>

                    </div>
                    <div>
                        <span>These tools are required to complete this USB/CID process. <span
                                style="text-decoration: underline;">These tools only need to be installed once on your machine.</span>
                            <br /><br />
                            The TenAsys tool required to associate hardware keys:<br>
                            <a class="hlink" mat-button color="accent" 
                                href="https://tenasys-licensing-safenet.s3.amazonaws.com/tools/USBOnboardingTool_Setup.exe"
                                download="USB_Importer_Setup.exe">TenAsys USB/CID Onboarding
                                Tool</a>
                            <br /><br />
                            The driver needed for the USB/CID hardware device:<br>
                            <a class="hlink" mat-button color="accent" 
                                href="https://tenasys-licensing-safenet.s3.amazonaws.com/tools/Sentinel%20System%20Driver%20Installer%207.5.9.exe"
                                download="SentinelSystemDriverInstaller752.exe">Sentinel
                                USB
                                Driver</a>
                        </span>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>