// what's sent to add entitlement
export class ActivationProduct {
    username: string = "";
    authToken: string = "";
    crmAccountid: string = "";
    crmOrderid: string = "";
    crmInvoiceid: string = "";
    product: string = "";
    productVersion: string = "";
    productType: string = "";
    comment: string = null;
    endDate: string = null;
    days: number = null;
    numberToAdd: number = null;
    seats1: number = null;
    seats2: number = null;
    setfulfillstatus: boolean = null;
    productmapid: number = null;
}

// the productmaps table
export class Product {
    Id: string = "";
    ProductName: string = "";
    ProductVersion: string = "";
    ProductType: string = "";
    EmsProductVersion: string = "";
    IsIfw: boolean = null;
    CrmVendorProduct: string = "";
    IsDevKit: boolean = null;
    EmsId: string = "";
    EmsMap: string = "";
    IsNetwork: boolean = null;
    IsCid: boolean = null;
 }
