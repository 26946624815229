<form [formGroup]="addEntitlementsFormAdmin">
    <div class="mainbox">
        <div class="leftsidebar">
            &nbsp;
        </div>
        <div class="submainbox">
            <div>
                <mat-card appearance="outlined">
                    <mat-card-header>
                        <mat-card-title>
                            <a class="titleText" mat-button matTooltip="{{accountname}}" matTooltipClass="tooltip-long"
                                [matTooltipPosition]="'below'"
                                href="{{crmendpoint}}main.aspx?appid=90933db0-b17a-eb11-a812-000d3a55fe16&pagetype=entityrecord&etn=account&id={{accountDetail.accountid}}"
                                target="_blank">{{accountname.toUpperCase()}}</a>
                            <mat-card-subtitle style="padding-left: 8px;">{{accountDetail.territory}}</mat-card-subtitle>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="maingrid">
                            <div class="topgrid">
                                <div>
                                    <dl>
                                        <dt>Primary Technical Contact</dt><dd>{{getPrimaryContact()}}</dd>
                                        <dt>Secondary Technical Contact</dt><dd>{{getSecondaryContact()}}</dd>
                                        <dt>Operations Manager Contact</dt><dd>{{getOpsManager()}}</dd>
                                    </dl>
                                </div>
                                <div>
                                    <div class="adminbuttongrid">
                                        <div>
                                            <button mat-raised-button value="Entitlement Details"
                                                (click)="showEntitlements()" data-testid="entitlement-details"><span class="button-title">Entitlement
                                                    Details</span><mat-icon class="button-icon">list</mat-icon>
                                            </button>
                                        </div>
                                        <div>
                                            <button mat-raised-button (click)="showOperationsManager()">Reset Operations
                                                Manager</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bodygrid">
                                <div>&nbsp;</div>
                                <div class="productcounts">
                                    <mat-card appearance="outlined" class="mat-elevation-z0" style="overflow: auto;">
                                        <mat-card-header class="cardheadertext">
                                            <mat-card-title>Product Counts</mat-card-title>
                                        </mat-card-header>
                                        <mat-card-content>
                                            <ngx-spinner name="productspinner" bdColor="#CCCCCC" size="small"
                                                color="#e12523" type="square-jelly-box" [fullScreen]="false">
                                                <p style="color: white">&nbsp;</p>
                                            </ngx-spinner>
                                            <div class="productbox">
                                                <table mat-table [dataSource]="dataSource">
                                                    <ng-container matColumnDef="product">
                                                        <th class="product" mat-header-cell *matHeaderCellDef> Product </th>
                                                        <td class="product" mat-cell *matCellDef="let count"> {{count.productname}}
                                                        </td>
                                                        <td class="product footerTitle" mat-footer-cell *matFooterCellDef>
                                                            Total
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="totalcount">
                                                        <th class="producttotal" mat-header-cell *matHeaderCellDef> Total </th>
                                                        <td class="producttotal" mat-cell *matCellDef="let count"> {{count.total |
                                                            number}}
                                                        </td>
                                                        <td class="producttotal" mat-footer-cell *matFooterCellDef>
                                                            {{calculateTotal("total")
                                                            |
                                                            number}}</td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="availableCount">
                                                        <th class="producttotal" mat-header-cell *matHeaderCellDef> Available </th>
                                                        <td class="producttotal" mat-cell *matCellDef="let count"> {{count.available |
                                                            number}}
                                                        </td>
                                                        <td class="producttotal" mat-footer-cell *matFooterCellDef>
                                                            {{calculateTotal("available")
                                                            |
                                                            number}}</td>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                                    </tr>
                                                    <tr mat-footer-row class="footer" *matFooterRowDef="displayedColumns">
                                                    </tr>
                                                </table>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div class="addentitlements">
                                    <mat-card appearance="outlined" class="mat-elevation-z0">
                                        <mat-card-header class="cardheadertext">
                                            <mat-card-title>
                                                Add Entitlements for Order
                                            </mat-card-title>
                                        </mat-card-header>

                                        <mat-card-content>
                                            <ngx-spinner name="invoicespinner" bdColor="white" size="small"
                                                color="#e12523" type="square-jelly-box" [fullScreen]="false">
                                                <p style="color: white">&nbsp;</p>
                                            </ngx-spinner>

                                            <div class="ordercontentgrid">
                                                <div class="columngrid">
                                                    <div>
                                                        <mat-form-field>
                                                            <mat-label>Order/PO</mat-label>
                                                            <mat-select formControlName="orderControl"
                                                                (selectionChange)='setOrder($event)'>
                                                                <mat-option style="width: 100%;" mat-option
                                                                    *ngFor="let order of orders" [value]="order">
                                                                    <span class="po_option">
                                                                        {{order.PONumber}}
                                                                    </span>
                                                                    <span class="optiontext"
                                                                        style="color: seagreen; font-weight: 600;"
                                                                        *ngIf="order?.state == '0'">[active]</span>
                                                                    <span class="optiontext" style="color: blue;"
                                                                        *ngIf="order?.state == '1'">[submitted]</span>
                                                                    <span class="optiontext" style="color: brown;"
                                                                        *ngIf="order?.state == '2'">[canceled]</span>
                                                                    <span class="optiontext" style="color: violet;"
                                                                        *ngIf="order?.state == '3'">[already
                                                                        fulfilled]</span>
                                                                    <span class="optiontext" style="color: gold;"
                                                                        *ngIf="order?.state == '4'">[invoiced]</span>
                                                                </mat-option>
                                                            </mat-select>
                                                            <mat-error
                                                                *ngIf="!getAddEntitlementsFormAdminControls('orderControl')?.valid">{{getAddEntitlementsFormAdminControls('orderControl')?.errors
                                                                | json}}</mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div style="text-align: center;"><a
                                                            [disabled]="selectedOrderUrl == ''" mat-raised-button
                                                            target="_blank" href="{{this.selectedOrderUrl}}"><span
                                                                class="button-title">Open</span><mat-icon
                                                                class="button-icon"
                                                                iconPositionEnd>arrow_outward</mat-icon></a>
                                                    </div>
                                                </div>
                                                <div class="columngrid">
                                                    <div>
                                                        <mat-form-field>
                                                            <mat-label>Invoice</mat-label>
                                                            <mat-select formControlName="invoiceControl"
                                                                (selectionChange)='setInvoice($event)'>
                                                                <mat-option style="width: 100%;"
                                                                    *ngFor="let invoice of invoices" [value]="invoice">
                                                                    <span class="po_option">
                                                                        {{invoice.name}}
                                                                    </span>
                                                                    <span class="optiontext"
                                                                        style="color: seagreen; font-weight: 600;"
                                                                        *ngIf="invoice?.state == '1'">[new]</span>
                                                                    <span class="optiontext" style="color: burlywood;"
                                                                        *ngIf="invoice?.state == '2'">[partially
                                                                        shipped]</span>
                                                                    <span class="optiontext" style="color: purple;"
                                                                        *ngIf="invoice?.state == '4'">[billed]</span>
                                                                    <span class="optiontext" style="color: hotpink;"
                                                                        *ngIf="invoice?.state == '5'">[booked]</span>
                                                                    <span class="optiontext" style="color: teal;"
                                                                        *ngIf="invoice?.state == '6'">[installed]</span>
                                                                    <span class="optiontext" style="color: tomato;"
                                                                        *ngIf="invoice?.state == '8'">[already
                                                                        fulfilled]</span>
                                                                    <span class="optiontext" style="color: crimson;"
                                                                        *ngIf="invoice?.state == '100001'">[complete]</span>
                                                                </mat-option>
                                                            </mat-select>
                                                            <mat-hint>Invoice
                                                                is automatically selected unless more than one
                                                                invoice is associated with the Order/PO.
                                                            </mat-hint>
                                                            <mat-error
                                                                *ngIf="!getAddEntitlementsFormAdminControls('invoiceControl')?.valid">{{getAddEntitlementsFormAdminControls('invoiceControl')?.errors
                                                                | json}}</mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div style="text-align: center;"><a
                                                            [disabled]="selectedInvoiceUrl == ''" mat-raised-button
                                                            target="_blank" href="{{this.selectedInvoiceUrl}}"><span
                                                                class="button-title">Open</span><mat-icon
                                                                class="button-icon"
                                                                iconPositionEnd>arrow_outward</mat-icon></a>
                                                    </div>
                                                </div>
                                                <div class="columngrid">
                                                    <div>
                                                        <mat-form-field>
                                                            <mat-label>Product</mat-label>
                                                            <mat-select formControlName="productControl"
                                                                (selectionChange)='setProduct($event)'>
                                                                <mat-option *ngFor="let product of filteredProducts"
                                                                    [value]="product">{{product.ProductName}}</mat-option>
                                                            </mat-select>
                                                            <mat-hint
                                                                *ngIf="selectedProduct?.ProductName && getAddEntitlementsFormAdminControls('productControl')?.valid">{{this.typeFriendly}}</mat-hint>
                                                            <mat-error
                                                                *ngIf="!getAddEntitlementsFormAdminControls('productControl')?.valid">{{getAddEntitlementsFormAdminControls('productControl')?.errors
                                                                | json}}</mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div>
                                                        <mat-form-field>
                                                            <mat-label *ngIf="getType() != 'network'"># to
                                                                Add</mat-label>
                                                            <mat-label *ngIf="getType() == 'network'">#
                                                                Development
                                                                Seats</mat-label>
                                                            <input matInput type="number" placeholder="# to Add"
                                                                formControlName="addNumberControl"
                                                                (change)='setAddEntitlementsAmount()' min="0"
                                                                max="{{maxNumberToAdd}}" value="{{numberToAdd}}">
                                                            <mat-error
                                                                *ngIf="!getAddEntitlementsFormAdminControls('addNumberControl')?.valid">{{getAddEntitlementsFormAdminControls('addNumberControl')?.errors
                                                                | json}}</mat-error>
                                                        </mat-form-field>
                                                        <div *ngIf="getType() == 'network'">
                                                            <mat-form-field>
                                                                <mat-label># Runtime Seats</mat-label>
                                                                <input matInput type="number"
                                                                    formControlName="addNumber2Control"
                                                                    (change)='setAddEntitlementsAmount()' min="0"
                                                                    max="{{maxNumberToAdd}}" value="{{numberToAdd}}">
                                                                <mat-error
                                                                    *ngIf="!getAddEntitlementsFormAdminControls('addNumber2Control')?.valid">{{getAddEntitlementsFormAdminControls('addNumber2Control')?.errors
                                                                    | json}}</mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <mat-form-field>
                                                        <mat-label>Internal Comment</mat-label>
                                                        <textarea matInput formControlName="commentControl"></textarea>
                                                        <mat-error
                                                            *ngIf="!getAddEntitlementsFormAdminControls('commentControl')?.valid">{{getAddEntitlementsFormAdminControls('commentControl')?.errors
                                                            | json}}</mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div>
                                                    <mat-checkbox 
                                                        formControlName="setfulfillControl"
                                                        [disabled]="selectedOrder && selectedOrder.PONumber == ''"
                                                        labelPosition="after"><span class="button-title">Close
                                                            CRM
                                                            Invoice</span><mat-icon class="button-icon"
                                                            matTooltip="Update invoice status to 'Fulfilled' within CRM"
                                                            matTooltipClass="tooltip-long"
                                                            [matTooltipPosition]="'after'">help</mat-icon>
                                                    </mat-checkbox>
                                                </div>
                                                <div id="add" class="buttongrid">
                                                    <div>
                                                        <button mat-raised-button color="accent"
                                                            (click)="$event.stopPropagation();addEntitlementsClick($event);"
                                                            [disabled]="isAddEntitlementsBtnDisabled">Add
                                                            Entitlement(s)
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button *ngIf="getShowCrm()" mat-raised-button
                                                            (click)="showCrmUpdate('40vw', '40vh')"><span class="button-title">View
                                                                New
                                                                Entitlements</span><mat-icon class="button-icon" iconPositionEnd>arrow_right_alt</mat-icon></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div class="rightsidebar">
            &nbsp;
        </div>
    </div>
</form>