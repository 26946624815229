<div class="largemainbox">
    <div class="leftsidebar">
        &nbsp;
    </div>
    <div class="submainbox">
        <div class="table">
            <div class="tableform">
                <mat-form-field>
                    <input matInput (keyup)="applyFilterEvent($event)" placeholder="Filter" spellcheck="false">
                    <mat-icon matSuffix style="font-size: 1.2em">search</mat-icon>
                </mat-form-field>
            </div>
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="creationtime">
                    <th style="width: 250px;" mat-header-cell *matHeaderCellDef mat-sort-header>Timestamp</th>
                    <td class="smallfield" mat-cell *matCellDef="let element"> {{ element.creationtime | date:'long' }}</td>
                </ng-container>
                <ng-container matColumnDef="runid">
                    <th style="width: 250px;" mat-header-cell *matHeaderCellDef>Run Id</th>
                    <td class="smallfield" mat-cell *matCellDef="let element">{{ element.runid }}</td>
                </ng-container>
                <ng-container matColumnDef="username">
                    <th style="width: 250px;" mat-header-cell *matHeaderCellDef>Username</th>
                    <td class="smallfield" mat-cell *matCellDef="let element">{{ element.username }}</td>
                </ng-container>
                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef>Log Entry</th>
                    <td mat-cell *matCellDef="let element">{{ element.detail }}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSize]="10" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <div class="rightsidebar">
        &nbsp;
    </div>
</div>