<div class="mainbox">
    <div class="leftsidebar">
        &nbsp;
    </div>
    <div class="submainbox">
        <div>
            <h2>License Administration</h2>
            <form [formGroup]="impersonateForm">
                <mat-form-field class="adminform">
                    <div class="admingrid">
                        <div class="impersonate">
                            <div>
                                <input type="radio" value="admin" name="radimpersonate"
                                    formControlName="radimpersonate" /><mat-label for="admin">Login as an
                                    administrator?</mat-label>
                            </div>
                            <div>
                                <div class="impersonategrid">
                                    <div class="impersonatesep"></div>
                                    <div class="impersonateoptionsgrid">
                                        <mat-label class="simulatelabel">Or simulate...</mat-label>
                                        <div>
                                            <input type="radio" value="opsman" name="radimpersonate"
                                                formControlName="radimpersonate" /><mat-label for="opsman">the
                                                operations manager</mat-label>
                                        </div>
                                        <div>
                                            <input type="radio" value="user" name="radimpersonate"
                                                formControlName="radimpersonate" /><mat-label for="user">a user of the
                                                account</mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="customerselect_container">
                            <mat-form-field data-testid="select-customer" #selCust class="customerselect">
                                <mat-label>Select Customer</mat-label>
                                <input type="text" appearance="outline" matInput [matAutocomplete]="autoGroup"
                                    placeholder="Start typing to filter customer accounts" spellcheck="false"
                                    [formControl]="customerControl">
                                <mat-autocomplete #autoGroup="matAutocomplete">
                                    <mat-option *ngFor="let customer of filteredCustomers | async"
                                        (onSelectionChange)="navToCustomer(customer.name)" [value]="customer.name"
                                        matTooltip="{{customer.name}}" matTooltipClass="tooltip-long"
                                        [matTooltipPosition]="'after'">
                                        {{customer.name}}</mat-option>
                                </mat-autocomplete>
                                <mat-icon matSuffix style="font-size: 1.2em">search</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-form-field>
            </form>
        </div>
        <div class="buttongrid">
            <div>
                <button mat-stroked-button data-testid="timelimited-button" (click)="navTo('temporaryentitlements')"
                    *ngIf="auth.getUserType() == 4 || auth.getUserType() == 3">Evals and Runtime Trials</button>
            </div>
            <div>
                <button mat-stroked-button (click)="navTo('logs')" *ngIf="auth.getUserType() == 4">Logs</button>
            </div>
            <div>
                <button mat-stroked-button (click)="navTo('loginstats')" *ngIf="auth.getUserType() == 4">Login
                    Statistics</button>
            </div>
            <div>
                <button mat-stroked-button (click)="navTo('salesadmin')"
                    *ngIf="auth.getUserType() == 4 || auth.getUserType() == 3">Sales Administration</button>
            </div>
        </div>
    </div>
    <div class="rightsidebar">
        &nbsp;
    </div>
</div>