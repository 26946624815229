import { Component } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription, forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { EntitlementService } from '../../../services/ems.service';
import { CustomerDataService } from '../../../services/customer-data.service';
import { AccountData } from '../../../types/AccountData';
import { OrderData } from '../../../types/OrderData';
import { Product } from '../../../types/Product';
import { EntitlementSummaryRow } from '../../../types/EntitlementSummaryRow';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackService } from 'src/app/modules/services/snack.service';
import { UsbCidSearchService } from 'src/app/modules/services/usbcidsearch.service';

@Component({
  selector: 'app-user-landing',
  templateUrl: './user-landing.component.html',
  styleUrls: [
    '../../../shared-styles/divs.scss',
    '../../../shared-styles/tables.scss',
    '../../../shared-styles/elements.scss',
    './user-landing.component.scss']
})
export class UserLandingComponent {
  NOTSET: string = "None";
  user: string;
  entitlements: Array<EntitlementSummaryRow> = new Array<EntitlementSummaryRow>();
  orders?: Array<OrderData>;
  accountDetail: AccountData = new AccountData();
  accountname: string = "";
  counts: Array<EntitlementSummaryRow> = [];
  dataSource: MatTableDataSource<EntitlementSummaryRow> = new MatTableDataSource<EntitlementSummaryRow>();
  displayedColumns: string[] = ['product', 'totalcount', 'availableCount', 'activate'];
  anorder?: OrderData;
  aproduct: Product = new Product();
  numberToAdd?: number;
  awaitResponse: boolean = false;
  usbSearchCharacters: string = "";
  currentSearchSequence: number = 0;
  priorKey: string = "";
  LOADDURATION: number = 15;
  hasUSB: boolean = false;
  accountid: string = "";
  constructor(private ems: EntitlementService,
    private customerData: CustomerDataService,
    private router: Router,
    private route: ActivatedRoute,
    private snackMsg: SnackService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private usbsearchservice: UsbCidSearchService
  ) {
    this.spinner.show("spinneronly");
    this.user = this.route.snapshot.paramMap.get('username') ?? "";
    this.accountid = this.customerData.accountData.accountid;
    let getAccount = this.ems.getAccountSummaryUser(this.accountid);

    forkJoin([getAccount]).subscribe(next => {
      if (next === null) {
        this.spinner.hide("spinneronly");
        return;
      }
      let raw = JSON.stringify(next);
      let getAccountResult = JSON.parse(raw);
      if (getAccountResult[0].entitlementsSummary === undefined) {
        this.spinner.hide("spinneronly");
        return;
      }
      this.counts = getAccountResult[0].entitlementsSummary;
      this.accountDetail = getAccountResult[0].account;
      this.dataSource = new MatTableDataSource(this.counts);
      this.usbsearchservice.hasUsb.next(this.counts.filter(c => c.productname.indexOf("KEY") > 1).length > 0);
      this.usbsearchservice.accountid.next(this.accountid);
      this.spinner.hide("spinneronly");
    }, () => {
      this.snackMsg.openSnackBar("An Error Has Occurred.", 5000);
      this.spinner.hide("spinneronly");
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  activate(itemToActivate: any) {
    this.customerData.loginType = "login";
    this.router.navigate(['/' + this.user + '/user/' + itemToActivate + '/activate']);
  }

  toTitleCase(str: string) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1, txt.length).toLowerCase();
      }
    );
  }

  getPrimaryContact() {
    let contact = this.toTitleCase(this.accountDetail.primaryTechnicalFirstName) + " " +
      this.toTitleCase(this.accountDetail.primaryTechnicalLastName);
    if (contact.trim() == "") {
      contact = this.NOTSET;
    }
    return contact;
  }

  getSecondaryContact() {
    let contact = this.toTitleCase(this.accountDetail.secondaryTechnicalFirstName) + " " +
      this.toTitleCase(this.accountDetail.secondaryTechnicalLastName);
    if (contact.trim() == "") {
      contact = this.NOTSET;
    }
    return contact;
  }

  getOpsManager() {
    let contact = this.toTitleCase(this.accountDetail.operationsManagerFirstName) + " " +
      this.toTitleCase(this.accountDetail.operationsManagerLastName);
    if (contact.trim() == "") {
      contact = this.NOTSET;
    }
    return contact;
  }
}
