import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../services/authorization.service';

@Component({
    selector: 'page-not-found',
    styleUrls: [
      '../../shared-styles/divs.scss',
      '../../shared-styles/tables.scss',
      '../../shared-styles/elements.scss',
      './page-not-found.scss'
    ],
    templateUrl: './page-not-found.html',
  })


export class PageNotFoundComponent {
  constructor(public auth: AuthorizationService,
    private router: Router)
  {
  }

  public navTo(newRoute: string) {
    this.router.navigate(['/login/']);
  }
}