<div class="mainbox">
	<div class="leftsidebar">
		&nbsp;
	</div>
	<div class="submainbox">
		<mat-card class="card" appearance="outlined">
			<mat-card-header>
				<mat-card-title>{{accountDetail.name}}</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<div class="contentgrid">
					<div>
						<div>
							<dl>
								<dt>Primary Technical Contact</dt>
								<dd>{{getPrimaryContact()}}</dd>
								<dt>Secondary Technical Contact</dt>
								<dd>{{getSecondaryContact()}}</dd>
								<dt>Operations Manager Contact</dt>
								<dd>{{getOpsManager()}}</dd>
							</dl>
						</div>
					</div>
					<div><app-usbcid-search [accountid]="accountid" [hasUsb]="hasUSB"></app-usbcid-search>
					</div>
					<div class="productbox">
						<table mat-table [dataSource]="dataSource">
							<ng-container matColumnDef="product">
								<th class="product" mat-header-cell *matHeaderCellDef> Product </th>
								<td class="product" mat-cell *matCellDef="let count">
									{{count.productname}} </td>
							</ng-container>
							<ng-container matColumnDef="totalcount">
								<th class="producttotal" mat-header-cell *matHeaderCellDef> Total </th>
								<td class="producttotal" mat-cell *matCellDef="let count">
									{{count.total | number}} </td>
							</ng-container>
							<ng-container matColumnDef="availableCount">
								<th class="producttotal" mat-header-cell *matHeaderCellDef> Available </th>
								<td class="producttotal" mat-cell *matCellDef="let count">
									{{count.available | number}} </td>
							</ng-container>
							<ng-container matColumnDef="activate">
								<th mat-header-cell *matHeaderCellDef> </th>
								<td class="createlicense" mat-cell *matCellDef="let count"><button
										class="activatebutton" mat-stroked-button disabled="{{count.available == 0}}"
										(click)="activate(count.productname)">Create License</button></td>
							</ng-container>
							<tr mat-header-row *matHeaderRowDef="displayedColumns" style="vertical-align:middle"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;" style="vertical-align:middle">
							</tr>
						</table>
					</div>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
	<div class="rightsidebar">
		&nbsp;
	</div>
</div>