<div class="mainbox">
	<div class="leftsidebar">
		&nbsp;
	</div>
	<div class="submainbox">
		<div>
			<div>
				<p class="hl1">We&rsquo;ll be back soon!</p>
			</div>
			<div class="maint_box_sub">
				<mat-icon class="icon" matSuffix>engineering</mat-icon>
				<p>Sorry for the inconvenience. We&rsquo;re performing maintenance on our License Portal. We&rsquo;ll be
					back up shortly!</p>
				<p>&mdash; The Ten<span class="a">A</span>sys Team</p>

				<div style="margin-top: 1em;">
					<app-status></app-status>
				</div>				
			</div>
		</div>
	</div>
	<div class="rightsidebar">
		&nbsp;
	</div>
</div>