export class OrderData {
    orderid: string = ""; 
    PONumber: string = ""; 
    ordernumber: string = ""; 
    description: string = ""; 
    createdby: string = ""; 
    createdon: string = ""; 
    submitdate: string = ""; 
    d365pros_orderfulfilled: boolean = false;
    datefulfilled: string = ""; 
    d365pros_orderfulfilledname: string = ""; 
    d365pros_relatedagreementname: string = ""; 
    modifiedby: string = ""; 
    modifiedon: string = ""; 
    state: string = ""; 
    products: Array<OrderProduct> = new Array<OrderProduct>();
}

export class OrderProduct {
    productmapname: string = "";
    count: number = 0;
}