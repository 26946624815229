import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../../../services/authorization.service';
import { CustomerDataService } from '../../../services/customer-data.service';
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from '@angular/material/dialog';
import { EntitlementService } from '../../../services/ems.service';
import { AccountData } from '../../../types/AccountData';
import { ActivationObject } from '../../../types/ActivationObject';
import { NgxSpinnerService } from 'ngx-spinner';
import { LicensepopupComponent } from './licensepopup/licensepopup.component';
import { Location } from '@angular/common';
import { SnackService } from 'src/app/modules/services/snack.service';
import { VolumepopupComponent } from './volumepopup/volumepopup.component';

@Component({
  selector: 'app-user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: [
    '../../../shared-styles/divs.scss',
    '../../../shared-styles/tables.scss',
    '../../../shared-styles/elements.scss',
    './user-activation.component.scss'],
  host: { class: 'tenasysContentColumn' }
})
export class UserActivationComponent implements OnInit  {
  license: string = "";
  licenseURL: string = "";
  fingerprint: string = "";
  user: string = "";
  product: string = "";
  comment: string = "";
  accountDetail: AccountData = new AccountData();
  activationObject: ActivationObject = new ActivationObject();
  licActivateClicked = false;
  activationInformation:string = "";
  loginType:string = "";
  constructor(private ems: EntitlementService,
    public auth: AuthorizationService,
    private customerData: CustomerDataService,
    private router: Router,
    private route: ActivatedRoute,
    public snackMsg: SnackService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private location: Location) {
    this.user = this.route.snapshot.paramMap.get('username') ?? "";
    this.product = this.route.snapshot.paramMap.get('product') ?? "";
    this.accountDetail = this.customerData.accountData ?? new AccountData(); 
  }

  ngOnInit(): void {
    if (this.customerData.loginType == "entitlement") {
      this.loginType = "entitlement";
      this.activationInformation = this.auth.getUsername();
    } else {
      this.loginType = "login";
    }   
  }

  public logout() {
    if (this.loginType == "entitlement") {
      this.router.navigateByUrl('entitlement');
    } else {
      this.location.back();
    }
  }

  public showvolume() {
    const dialogRef = this.dialog.open(VolumepopupComponent, {
      data: {
        activationObject: this.activationObject
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.license !== "") {
        this.license = result.license;
        this.licenseURL = result.licenseUrl;
        this.showlicense();
      }
    });
  }

  public showlicense() {
    const dialogRef = this.dialog.open(LicensepopupComponent, {
      data: {
        datavalue: this.license,
        datacolumn: "License",
        licenseurl: this.licenseURL
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public activate() {
    this.licActivateClicked = true;
    this.activationObject.securityToken = this.auth.getSecurityToken();
    this.activationObject.comment = this.comment;
    this.activationObject.fingerprint = this.fingerprint;
    this.activationObject.userType = this.auth.getUserType();
    this.activationObject.requestType = this.customerData.loginType ?? "";
    this.activationObject.requestValue = this.customerData.loginType == "entitlement" ? this.auth.getUsername() : this.product;
    this.activationObject.source = "Portal";
    this.activationObject.username = this.auth.getUsername();

    if (this.fingerprint == '') {
      this.snackMsg.openSnackBar("Enter the target fingerprint!", 5000);
      return;
    }

    if (this.license != '') {
      this.snackMsg.openSnackBar("A license has already been generated!", 5000);
      return;
    }

    this.spinner.show("spinneronly");
    this.ems.activateEntitlement(this.activationObject).subscribe((next: any) => {
      if (next === null) {
        this.spinner.hide("spinneronly");
        return;
      }
      let raw = JSON.stringify(next);
      let data = JSON.parse(raw);
      if (data.ErrorCode !== undefined || data == "Failed to Activate.")
      {
        var msg = "A problem occurred during license activation.";
        if (data.ErrorCode == 109) {
          this.showvolume();          
        } else {
          this.snackMsg.openSnackBar(msg, 5000);
        }        
      } else {
        this.license = data.license;
        let entitlement = data.entitlement;        
        this.licenseURL = this.customerData != null && this.customerData.accountData != null ? this.ems.getLicenseFile(this.customerData.accountData.accountid, entitlement) : undefined;
        this.showlicense();
      }
      this.spinner.hide("spinneronly");
    }, () => {
      this.snackMsg.openSnackBar("An Error Has Occurred.", 5000);
      this.spinner.hide("spinneronly");
    });
  }
}