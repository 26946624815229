import { Contact } from "./Contact";
import { Customer } from "./Customer";

export class AuthorizationData {
    EMSToken: string = "";
    EMSusername: string = "";
    username: string = "";
    securityToken: string = "";
    tokenClaimDate: string = "";
    customer: Customer = new Customer();
    contact: Contact = new Contact();
    userType: number = 0;
}
