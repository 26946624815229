import { Component } from '@angular/core';
import { AuthorizationService } from '../../../services/authorization.service';
import { CustomerDataService } from '../../../services/customer-data.service';
import { Router } from "@angular/router";
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EntitlementService } from '../../../services/ems.service';
import { Customer } from '../../../types/Customer';
import { forkJoin } from 'rxjs';
import { Link } from '../../../types/Link';
import { UntypedFormControl, FormBuilder, FormsModule } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { SplashService } from 'src/app/modules/services/splash.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { SnackService } from 'src/app/modules/services/snack.service';

// controls delay of the tooltip
export const adminCustListTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 1500,
  hideDelay: 0,
  touchendHideDelay: 1500,
};
@Component({
  selector: 'admin-landing',
  templateUrl: './admin-landing.component.html',
  styleUrls: [
    '../../../shared-styles/divs.scss',
    '../../../shared-styles/tables.scss',
    '../../../shared-styles/elements.scss',
    './admin-landing.component.scss'
  ],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: adminCustListTooltipDefaults }
  ],
})

export class AdminLandingComponent {
  customers: Array<Customer> = new Array<Customer>();
  customerLinks: Array<Link> = new Array<Link>();
  filteredCustomers: Observable<Customer[]> = new Observable<Customer[]>();
  customerControl: UntypedFormControl = new UntypedFormControl();
  LOADDURATION: number = 8;
  impersonateForm = this.fb.group({
    radimpersonate: this.fb.control('admin'),
  });

  constructor(
    public fb: FormBuilder,
    private ems: EntitlementService,
    public auth: AuthorizationService,
    private router: Router,
    public snackMsg: SnackService,
    public dialog: MatDialog,
    private splash: SplashService,
    private spinner: NgxSpinnerService,
    private customerData: CustomerDataService) {
      // null out admin customer data in case we came back from another account
      this.customerData.adminCustomerAccount = ""; // displayed in upper-right up screen for admins
      this.customerData.adminCustomerAccountGuid = ""; // displayed in upper-right up screen for admins
      this.customerData.adminCrmEndpoint = ""; // displayed in upper-right up screen for admins
  }

  ngOnInit() {
    this.spinner.show("spinneronly");
    let getCustomers = this.ems.getCustomers();
    forkJoin([getCustomers]).subscribe(([getCustomersResult]) => {
      this.customers = getCustomersResult;
      this.filteredCustomers = this.customerControl.valueChanges.pipe(startWith(''), map(value => this.customerFilter(value)));
      this.spinner.hide("spinneronly");
    });
  }

  private customerFilter(value: string): Customer[] {
    let valueLower = value.toLowerCase();
    return this.customers.filter(c => c.name.toLowerCase().includes(valueLower));
  }

  public navTo(newRoute: string) {
    this.router.navigate(['/' + this.auth.getUsername() + '/admin/' + newRoute + '/']);
  }

  public navToCustomer(accountName: string) {
    console.log("Navigating to customer: " + accountName);
    var item = this.impersonateForm.get('radimpersonate');
    if (this.customerData.accountData != null) {
      this.customerData.accountData.accountid = this.customers.find(c => c.name == accountName)?.crmId ?? "";
      if (item.value == "opsman") {
        this.ems.userMode = true;
        this.router.navigate(['/' + this.auth.getUsername() + '/superuser/']);
      }
      else if (item.value == "user") {
        this.ems.userMode = true;
        this.router.navigate(['/' + this.auth.getUsername() + '/user/']);
      }
      else {
        this.ems.userMode = false;
        this.router.navigate(['/' + this.auth.getUsername() + '/admin/' + accountName.replace('/', '%2F') + '/']);
      }
    }
  }
}
