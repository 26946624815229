<div class="main">
    <h3 color="warn">Plase confirm deletion of entitlement!</h3>
    <div class="delete-grid">
        <div>
            <dl>
                <dt>Account</dt>
                <dd><span class="accountname">{{data.account.name}}</span></dd>
                <dt>Entitlement ID</dt>
                <dd>{{data.datavalue["tenasysentitlementid"]}}</dd>
                <dt>Activated</dt>
                <dd>{{data.datavalue["licensed"]}}</dd>
                <dt>Purchase Order (PO)</dt>
                <dd>{{data.datavalue["ponumber"]}}</dd>
                <dt>Invoice</dt>
                <dd>{{data.datavalue["crminvoicename"]}}</dd>
                <dt>Entitlement State</dt>
                <dd>{{data.datavalue["reservationtype"]}}</dd>
                <dt>Creation Batch ID</dt>
                <dd>{{data.datavalue["createbatchid"]}}</dd>
            </dl>
        </div>
        <div>
            <mat-form-field class="field" appearance="outline">
                <mat-label class="label">Delete Reason</mat-label>
                <mat-hint>A reason for deletion is required to proceed.</mat-hint>
                <textarea class="nomwidth" matInput placeholder="Enter the reason for this change" cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" cols="60" rows="5"
                    [(ngModel)]="this.saveComment" name="comment" spellcheck="true">
                </textarea>
            </mat-form-field>
        </div>
        <div class="button-grid">
            <button class="buttons" mat-raised-button color="accent" (click)="close()">Cancel</button>
            <button class="buttons" [disabled]="this.saveComment == '' || this.disableSaveBtn || confirmed"
                (click)="confirmRecordConfirmed()" mat-raised-button>Delete</button>
            <button class="buttons" *ngIf="confirmed" [disabled]="this.saveComment == '' || this.disableSaveBtn"
                (click)="updateRecordConfirmed()" mat-raised-button>CONFIRM</button>
        </div>
        <p *ngIf="this.batchitems > 1 && this.fullBatch" class="multihint">{{data.batchitems}}
            entitlements will be DELETED!</p>
    </div>
</div>