<div class="mainbox">
    <div class="leftsidebar">
        &nbsp;
    </div>
    <div class="submainbox">
        <div class="session_expired_box_sub">
            <div class="center">
                <p class="hl1">Session Expired</p>
            </div>
            <div>
                <mat-icon class="icon" matSuffix>watch_off</mat-icon>
            </div>
            <div>
                Please login again to continue using the Ten<span class="a">A</span>sys License Portal!
            </div>
            <div class="button_center">
                <button class="button-title" mat-stroked-button (click)="navTo('login')">Go to Login page
                    <mat-icon class="button-icon">logout</mat-icon></button>
            </div>
        </div>
    </div>
    <div class="rightsidebar">
        &nbsp;
    </div>
</div>