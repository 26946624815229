<div class="mainbox">
    <div class="leftsidebar">
        &nbsp;
    </div>
    <div class="submainbox">
        <div>
            <div>
                <p class="hl1">Page Not Found</p>
            </div>
            <div class="maint_box_sub">
                <div>
                    <mat-icon class="icon" matSuffix>error</mat-icon>
                    <p>Sorry, we couldn't find what you were looking for or the page no longer exists.</p>
                </div>
                <div class="button_center">
                    <button mat-stroked-button (click)="navTo('login')">Go to Login page
                        <mat-icon>logout</mat-icon></button>
                </div>
                <div>
                    <p>&mdash; The Ten<span class="a">A</span>sys Team</p>
                </div>
            </div>
        </div>
    </div>
    <div class="rightsidebar">
        &nbsp;
    </div>
</div>