import { Component } from '@angular/core';
import { AuthorizationService } from '../../../../services/authorization.service';
import { CustomerDataService } from '../../../../services/customer-data.service';
import { Router, ActivatedRoute } from "@angular/router";
import { EntitlementService } from '../../../../services/ems.service';
import { AccountData } from '../../../../types/AccountData';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackService } from 'src/app/modules/services/snack.service';

@Component({
  selector: 'app-admin-customer-edit-operations-manager',
  templateUrl: './admin-customer-edit-operations-manager.component.html',
  styleUrls: [
    '../../../../shared-styles/divs.scss',
    '../../../../shared-styles/tables.scss',
    '../../../../shared-styles/elements.scss',
    './admin-customer-edit-operations-manager.component.scss'
  ]
})


export class AdminCustomerEditOperationsManagerComponent {
  accountDetail: AccountData = new AccountData();
  accountname: string = "";
  checked: boolean = false;
  resetdone:boolean = false;
  nouserset: boolean = false;
  firstname: string = "";
  lastname: string = "";
  email: string = "";

  opsManagerForm = new UntypedFormGroup({
    firstname: new UntypedFormControl(null, Validators.required),
    lastname: new UntypedFormControl(null, Validators.required),
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email
    ]),
    newpassword: new UntypedFormControl(null, Validators.required),
    checked: new UntypedFormControl(null, null)
  });

  constructor(
    private ems: EntitlementService,
    private auth: AuthorizationService,
    private spinner: NgxSpinnerService,
    private customerData: CustomerDataService,
    private router: Router,
    public snackMsg: SnackService,
    private route: ActivatedRoute,
    ) { 
      let accountid = this.customerData.accountData.accountid;
      let getCustomers = this.ems.getAccountAdmin(accountid);
      this.accountname = this.route.snapshot.paramMap.get('customer')?.replace("%2F", "/") ?? "";
      this.spinner.show("spinneronly");
      getCustomers.subscribe((data) =>{
        this.accountDetail = data.account;
        this.firstname = data.account.operationsManagerFirstName;
        this.lastname = data.account.operationsManagerLastName;
        this.email = data.account.operationsManagerEmail;
        if (this.email == null || this.email == "") {
          this.nouserset = true;
          this.spinner.hide("spinneronly");
        } else {
          let isContact = this.ems.isContact(this.email);
          isContact.subscribe((isValid) => {
            this.opsManagerForm.patchValue({firstname: this.firstname, lastname: this.lastname, email: this.email});
            this.spinner.hide("spinneronly");
          }, 
          () => {
            this.snackMsg.openSnackBar("Could not confirm if user is in EMS.", 2000);
            this.spinner.hide("spinneronly");
          });
        }
      },
      (error) => {
        this.snackMsg.openSnackBar("Unknown error occurred.", 2000);
        this.spinner.hide("spinneronly");
      });
    }

    returnToAccountPage(){
      this.accountname = this.route.snapshot.paramMap.get('customer')?.replace("%2F", "/") ?? "";
      this.router.navigate(['/'+ this.auth.getUsername() + '/admin/' + this.accountname.replace('/', '%2F') +'/']);
    }

    setUpdateOperationsManager(){
      let body = {
        firstname: this.opsManagerForm.controls.firstname.value,
        lastname: this.opsManagerForm.controls.lastname.value,
        email: this.opsManagerForm.controls.email.value,
        accountid: this.customerData.accountData.accountid,
        password: this.opsManagerForm.controls.password.value,
      }
      this.spinner.show("spinneronly");
      this.ems.setUpdateOperationsManager(body).subscribe((data)=> {
        this.spinner.hide("spinneronly");
        this.snackMsg.openSnackBar("Set operations manager complete.", 5000);
        this.returnToAccountPage();
      }, (error) => {
        this.snackMsg.openSnackBar("An error occurred.", 5000);
        this.spinner.hide("spinneronly");
      });
    }

    isValid(){
      return this.opsManagerForm.controls.password.value == this.opsManagerForm.controls.confirmpassword.value && (
        this.opsManagerForm.controls.password.value != undefined &&
        this.opsManagerForm.controls.password.value.length >= 10
      ) && this.opsManagerForm.controls.firstname.value.length > 0 && this.opsManagerForm.controls.lastname.value.length > 0 && this.opsManagerForm.controls.email.valid;
    }

    getType(){
      return this.opsManagerForm.controls.checked.value ? 'text' : 'password';
    }

    toTitleCase(str: string) {
      return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1, txt.length).toLowerCase();
        }
      );
    }
  
    resetPassword(){
      this.spinner.show("spinneronly");
      this.ems.resetDefaultPassword(this.email).subscribe((data) =>{
        if (data.status == true){
          this.opsManagerForm.controls.newpassword.setValue(data.pass);
          this.resetdone = true;
          this.snackMsg.openSnackBar("Password reset.", 2000);
        }
        this.spinner.hide("spinneronly");

      },
      () => { 
        this.snackMsg.openSnackBar("Password reset error. Please confirm user is in EMS.", 10000);
        this.spinner.hide("spinneronly"); }
      );
    }
}
