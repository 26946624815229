import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, TitleStrategy, RouterStateSnapshot } from '@angular/router';
import { LoginComponent } from '../shared-components/login/login';
import { ChangePasswordComponent } from '../shared-components/change-password/change-password.component';
import { EntitlementLoginComponent as EntitlementLoginComponent } from '../shared-components/entitlement-login/entitlement-login';
import { PageNotFoundComponent } from '../shared-components/page-not-found/page-not-found';
import { UserLandingComponent } from '../user-mode/components/user-landing/user-landing.component';
import { UserActivationComponent } from '../user-mode/components/user-activation/user-activation.component';
import { PrimaryLandingComponent } from '../user-mode/components/primary-landing/primary-landing.component';
import { PrimaryManageComponent } from '../user-mode/components/primary-manage/primary-manage.component';
import { PrimaryEditUsersResetPasswordComponent } from '../user-mode/components/primary-edit-users/primary-edit-users-reset-password/primary-edit-users-reset-password.component';
import { PrimaryEditUsersComponent } from '../user-mode/components/primary-edit-users/primary-edit-users.component';
import { AdminLandingComponent } from '../admin-mode/components/admin-landing/admin-landing.component';
import { AdminCustomerHomeComponent } from '../admin-mode/components/admin-customer-view/admin-customer-view.component';
import { AdminCustomerEntitlementsComponent } from '../admin-mode/components/admin-customer-view/admin-customer-entitlements/admin-customer-entitlements.component';
import { AdminLogViewComponent } from '../admin-mode/components/admin-log-view/admin-log-view.component';
import { AdminTimelimitedentitlementsViewComponent } from '../admin-mode/components/admin-timelimitedentitlements-view/admin-timelimitedentitlements-view.component';
import { AdminCustomerEditOperationsManagerComponent } from '../admin-mode/components/admin-customer-view/admin-customer-edit-operations-manager/admin-customer-edit-operations-manager.component';
import { AuthGuard } from './auth.guard';
import { QrComponent } from '../qr/qr.component';
import { AdminStatusComponent } from '../admin-mode/components/admin-status/admin-status.component';
import { QrlookupComponent } from '../qr/qrlookup/qrlookup.component';
import { MaintenanceComponent } from '../shared-components/maintenance/maintenance.component';
import { Title } from '@angular/platform-browser';
import { SalesAdministrationComponent } from '../admin-mode/components/sales-administration/sales-administration.component';
import { SessionExpiredComponent } from '../shared-components/session-expired/session-expired.component';
import { LogOffComponent } from '../shared-components/log-off/log-off.component';
const appRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { 
    path: '404', 
    title: 'Page Not Found',
    component: PageNotFoundComponent, 
    data: { breadcrumb: { label: 'PAGE NOT FOUND' } } 
  },
  { 
    path: 'logoff', 
    title: 'Session Expired',
    component: SessionExpiredComponent, 
    data: { breadcrumb: { label: 'SESSION EXPIRED' } } 
  },
  { 
    path: 'qr/:computerid/:serial', 
    title: 'USB/CID Lookup',
    component: QrComponent, 
    data: { breadcrumb: { label: 'QR LICENSE LOOKUP' } } 
  },
  { 
    path: 'qr', 
    title: 'USB/CID Lookup',
    component: QrlookupComponent, 
    data: { breadcrumb: { label: 'LICENSE LOOKUP' } } 
  },
  { 
    path: 'changepassword', component: ChangePasswordComponent,
    title: "Change Password",
    data: { breadcrumb: { label: 'CHANGE PASSWORD' } },
    canActivate: [AuthGuard]
  },
  { 
    path: 'maintenance',
    title: 'Under Maintenance',
    component: MaintenanceComponent, 
    data: { breadcrumb: { label: 'MAINTENANCE' } } 
  },
  {
    path: ':product/activate', component: UserActivationComponent,
    title: 'Product Activation (Entitlement)',
    data: { breadcrumb: { label: 'ENTITLEMENT ACTIVATION' } },
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    data: { breadcrumb: { skip: true } },
    children:
    [
      {
        path: '',
        data: { breadcrumb: { skip: true } },
        children:
        [
          {
            path: '',
            title: "Entitlement Login",
            component: EntitlementLoginComponent,
            data: { breadcrumb: { label: 'ENTITLEMENT LOGIN' } }
          }
        ]
      },
      {
        path: 'userlogin',
        title: "Login",        
        component: LoginComponent,
        data: { breadcrumb: { label: 'LOGIN' } }
      }
    ]
  },
  {
    path: ':username',     
    data: { breadcrumb: { skip: true } },
    children:
    [
      {
        path: '',
        redirectTo: '/login/userlogin',
        pathMatch: 'full'
      },
      {
        path: 'admin',
        data: { breadcrumb: { label: 'ADMINISTRATION HOME', info: 'home' } },
        children:
        [
          {
            path: '',
            title: "Licensing Home (Administration)",
            component: AdminLandingComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'changepassword', component: ChangePasswordComponent,
            title: "Change Password",
            data: { breadcrumb: { label: 'CHANGE PASSWORD' } },
            canActivate: [AuthGuard]
          },
          { 
            path: 'logoff', 
            title: 'Log Off',
            component: LogOffComponent, 
            data: { breadcrumb: { label: 'LOG OFF' } } 
          },
          {
            path: 'logs',
            title: 'Administrative Logs', 
            component: AdminLogViewComponent,
            data: { breadcrumb: { label: 'LOGS' } },
            canActivate: [AuthGuard]
          },
          {
            path: 'loginstats',
            title: 'Login Statistics', 
            component: AdminStatusComponent,
            data: { breadcrumb: { label: 'LOGIN STATISTICS' } },
            canActivate: [AuthGuard]
          },
          {
            path: 'salesadmin',
            title: 'Sales Administration', 
            component: SalesAdministrationComponent,
            data: { breadcrumb: { label: 'SALES ADMINISTRATION' } },
            canActivate: [AuthGuard]
          },
          {
            path: 'temporaryentitlements',
            title: 'Temporary Licenses', 
            component: AdminTimelimitedentitlementsViewComponent,
            data: { breadcrumb: { label: 'TEMPORARY LICENSES' } },
            canActivate: [AuthGuard]
          },
          {
            path: ':customer',
            data: { breadcrumb: { label: 'CUSTOMER DETAIL' } },
            children:
            [
              {
                path: '', 
                title: 'Customer Details',
                component: AdminCustomerHomeComponent,                
                canActivate: [AuthGuard]
              },
              {
                path: 'entitlements', 
                title: 'Entitlement Details (Administrator)', 
                component: AdminCustomerEntitlementsComponent,
                data: { breadcrumb: { label: 'ENTITLEMENTS' } },
                canActivate: [AuthGuard]
              },
              {
                path: 'operationsmanager',
                title: 'Edit Operations Manager', 
                component: AdminCustomerEditOperationsManagerComponent,
                data: { breadcrumb: { label: 'OPERATIONS MANAGER' } },
                canActivate: [AuthGuard]
              }
            ]
          }
        ]
      },
      {
        path: 'superuser',
        data: { breadcrumb: { label: 'HOME', info: 'home' } },
        children:
        [
          {
            path: '', 
            title: 'Licensing Home',
            component: PrimaryLandingComponent,            
            canActivate: [AuthGuard]
          },
          { 
            path: 'logoff', 
            title: 'Log Off',
            component: LogOffComponent, 
            data: { breadcrumb: { label: 'LOG OFF' } } 
          },
          {
            path: 'changepassword', 
            title: "Change Password",
            component: ChangePasswordComponent,
            data: { breadcrumb: { label: 'CHANGE PASSWORD' } },
            canActivate: [AuthGuard]
          },
          {
            path: ':product/activate', 
            title: 'Product Activation (Logon)',
            component: UserActivationComponent,
            data: { breadcrumb: { label: 'LICENSE ACTIVATION' } },
            canActivate: [AuthGuard]
          },
          {
            path: 'manage', 
            title: 'Entitlement Details',
            component: PrimaryManageComponent,
            data: { breadcrumb: { label: 'ENTITLEMENTS' } },
            canActivate: [AuthGuard]
          },
          {
            path: 'users',   
            data: {  breadcrumb: { label: 'MANAGE USERS' } },        
            children:
            [
              {                
                path: '', 
                title: 'Manage Users',
                component: PrimaryEditUsersComponent,                
                canActivate: [AuthGuard]
              },
              {
                path: 'resetpassword/:usertoreset', 
                title: 'Reset Password',
                component: PrimaryEditUsersResetPasswordComponent,
                data: { breadcrumb: { label: 'RESET PASSWORD' } },
                canActivate: [AuthGuard]
              }
            ]
          }
        ]
      },
      {
        path: 'user',
        data: { breadcrumb: { label: 'HOME', info: 'home'  } },        
        children:
        [
          {
            path: '', 
            title: 'Licensing Home',
            component: UserLandingComponent,       
            canActivate: [AuthGuard]
          },
          {
            path: 'changepassword', 
            title: "Change Password",
            component: ChangePasswordComponent,
            data: { breadcrumb: { label: 'CHANGE PASSWORD' } },
            canActivate: [AuthGuard]
          },
          {
            path: ':product/activate', 
            title: 'Product Activation (Logon)',
            component: UserActivationComponent,            
            data: { breadcrumb: { label: 'LICENSE ACTIVATION' } },
            canActivate: [AuthGuard]
          },
          { 
            path: 'logoff', 
            title: 'Log Off',
            component: LogOffComponent, 
            data: { breadcrumb: { label: 'LOG OFF' } } 
          }
        ]
      }
    ]      
  },
  { path: '**', redirectTo: '/404' }
];

@Injectable({ providedIn: 'root' })
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`TenAsys License Portal | ${title}`);
    }
  }
}

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: [
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },
  ]
})
export class EntitlementsRoutingModule { }

