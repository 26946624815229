import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { AuthorizationService } from '../../services/authorization.service';
import { CustomerDataService } from '../../services/customer-data.service';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthorizationData } from '../../types/AuthorizationData';
import { MatDialog } from '@angular/material/dialog';
import { AccountData } from '../../types/AccountData';
import { SplashService } from '../../services/splash.service';
import { SnackService } from '../../services/snack.service';

@Component({
  selector: 'tenasys-login',
  styleUrls: [
    '../../shared-styles/divs.scss',
    '../../shared-styles/tables.scss',
    '../../shared-styles/elements.scss',
    './login.scss'
  ],
  templateUrl: './login.html'
})
export class LoginComponent {
  username: string = "";
  password: string = "";
  entitlementid: string = "";
  user: AuthorizationData = new AuthorizationData();
  showLogin: boolean = false;
  showLogoff: boolean = false;
  timedOut: boolean = false;
  disallowLogin: boolean = false;
  LOADDURATION: number = 8;

  constructor(private auth: AuthorizationService,
    private router: Router,
    private spinner: SplashService,
    private customerData: CustomerDataService,
    public snackMsg: SnackService,
    public dialog: MatDialog
  ) {
    this.auth.reset();
  }

  public onPasswordChange(key: any) {
    if (key === "Enter") {
      this.tryLogin();
    }
  }

  public goBack() {
    let priorRoute = this.customerData.priorRoute;
    if (priorRoute != undefined) this.router.navigateByUrl(priorRoute);
  }

  public gotoEntitlement() {
    this.router.navigateByUrl('entitlement');
  }

  public getCustomerUserName(): string {
    return this.customerData.username ?? "";
  }

  public gotoLogin() {
    this.router.navigate(['/login/']);
  }

  public tryLogin() {
    this.disallowLogin = true;
    this.spinner.show(this.LOADDURATION);
    if (this.username != "" && this.password != "") {
      this.auth.login(this.username, this.password)
        .subscribe(data => {
          this.customerData.loginType = "login";
          if (data.securityToken == "Login Failed") {
            this.snackMsg.openSnackBar("Incorrect username or password.", 5000);
          } else {
            this.auth.setContact(data.contact);
            this.auth.setSecurityToken(data.securityToken);
            this.auth.setUserType(data.userType);
            this.customerData.accountData = new AccountData();
            this.customerData.username = this.username;
            this.customerData.accountData.accountid = data.customer.id;
            this.customerData.accountData.name = data.customer.name;
            this.auth.setSession();
            if (data.customer.name == "changepassword") {
              this.router.navigate(['/changepassword']);
            } else {
              switch (data.userType) {
                case 1:
                  this.router.navigate(['/' + this.username + '/user']);
                  break;
                case 2:
                  this.router.navigate(['/' + this.username + '/superuser']);
                  break;
                case 3:
                  this.router.navigate(['/' + this.username + '/admin']);
                  break;
                case 4:
                  this.router.navigate(['/' + this.username + '/admin']);
                  break;
              }
            }
          }
          this.spinner.hide();
          this.disallowLogin = false;
        },
          error => {
            this.spinner.hide();
            this.snackMsg.openSnackBar("Incorrect username or password.", 5000);
            this.disallowLogin = false;
          }
        );
    } else if (this.entitlementid != "") {
      this.auth.entitlementLogin(this.entitlementid)
        .subscribe((data: any) => {
          this.customerData.loginType = "entitlement";
          if ('ErrorCode' in data) {
            let errCode = data["ErrorCode"];
            if (errCode == 107) {
              this.snackMsg.openSnackBar("Entitlement has already been activated.\n Login or contact your Operations Manager to retrieve the license.", 5000);              
            } else {
              this.snackMsg.openSnackBar("An error has occurred.  Please try again.", 5000);
            }
            this.disallowLogin = false;
          }
          else if (data.securityToken == "") {
            this.snackMsg.openSnackBar("An error has occurred.  Please try again.", 5000);
          } else {
            this.auth.setSecurityToken(data.securityToken);
            this.auth.setUserType(data.userType);
            let productname = data.contact.crmid;
            let tenasysentitilementid = data.contact.name;
            this.customerData.accountData = new AccountData();
            this.customerData.username = tenasysentitilementid;
            this.customerData.accountData.accountid = data.customer.id;
            this.auth.setSession();
            this.router.navigate(['/' + productname + '/activate']);
          }
          this.spinner.hide();
        },
          () => {
            this.spinner.hide();
            this.snackMsg.openSnackBar("Entitlement Lookup Failed.", 5000);
            this.disallowLogin = false;
          }
        );
    } else {
      this.spinner.hide();
      this.snackMsg.openSnackBar("A username and password or TenAsys entitlement is required to proceed.", 5000);
      this.disallowLogin = false;
    }
  }
}
