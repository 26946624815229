<div class="modal" data-target="#editmodal">
    <div>
        <h2 mat-dialog-title><span data-testid="edit-entitlements-title">{{data.datacolumn}}</span></h2>
    </div>
    <ngx-spinner name="spinner_edit" size="medium" type="ball-spin-clockwise" color="#e12523">
        <!-- <p class="loading">Loading...</p> -->
    </ngx-spinner>
    <div class="celldata">
        <dl>
            <dt>Account</dt>
            <dd><span class="accountname">{{data.account.name}}</span></dd>
            <dt>Entitlement ID</dt>
            <dd>{{data.datavalue["tenasysentitlementid"]}}</dd>
            <dt>Activated</dt>
            <dd>{{data.datavalue["licensed"]}}</dd>
            <dt>Purchase Order (PO)</dt>
            <dd>{{data.datavalue["ponumber"]}}</dd>
            <dt>Invoice</dt>
            <dd>{{data.datavalue["crminvoicename"]}}</dd>
            <dt>Entitlement State</dt>
            <dd>{{data.datavalue["reservationtype"]}}</dd>
            <dt>Creation Batch ID</dt>
            <dd>{{data.datavalue["createbatchid"]}}</dd>
        </dl>
        <div class="changes">
            <ul mat-list>
                <li mat-list-item *ngIf="this.batchtype == 'single'">Only the selected entitlement will be changed.</li>
                <li mat-list-item *ngIf="this.batchtype == 'batch'" class="warn">All entitlements created within the same batch will be changed!<br>(entitlement batch
                    {{data.datavalue["createbatchid"]}} has {{data.batchitems}} entitlements)</li>
                <li mat-list-item *ngIf="this.forceDisableState" class="warn">Entitlement state cannot be changed on on "In-Process" entitlements.</li>
                <li mat-list-item *ngIf="this.statechanged">Entitlement state will be changed to
                    "{{this.newState.name}}"</li>
                <li mat-list-item *ngIf="this.accountchanged">Account will be changed to "{{this.newAccount.name}}"</li>
            </ul>
        </div>
    </div>
    <div class="buttons">
        <div class="editgrid">
            <div>
                <mat-expansion-panel #matExpansionPanelState [expanded]="this.toggleState()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Change Entitlement State
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="states" *ngFor="let s of data?.reservation_types">
                        <label [for]='s["id"]'>
                            <dl>
                                <dt *ngIf='s["id"] != 6'><input name='stateGroup' (change)="stateChange($event)" type='radio'
                                        id='{{s["id"]}}'
                                        [disabled]='s["id"] == this.hiddenStateId || this.forceDisableState'
                                        [checked]='data.datavalue["reservationtype"] == s["type"]' />{{s["type"]}}
                                </dt>
                                <dd><span>{{s["type_description"]}}</span></dd>
                            </dl>
                        </label>
                    </div>
                </mat-expansion-panel>
            </div>
            <div>
                <mat-expansion-panel #matExpansionPanelAccount [expanded]="this.toggleTransfer()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Transfer Entitlement
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <span class="warn">Attention &hyphen; The License Identifier attached to the entitlement is not
                            changed and may still point to original account. Proceed with caution.</span>
                        <mat-form-field #selCust class="customerselect">
                            <mat-label>Select Customer</mat-label>
                            <input type="text" appearance="outline" matInput [matAutocomplete]="autoGroup"
                                placeholder="Start typing to filter customer accounts" [(ngModel)]="searchCustomerName"
                                (input)="customerFilter()" [disabled]="this.forceDisableState" spellcheck="false">
                            <mat-autocomplete #autoGroup="matAutocomplete">
                                <mat-option *ngFor="let customer of filterAccounts"
                                    (onSelectionChange)="transferCustomer(customer.crmId, customer.name)"
                                    [value]="customer.name" matTooltip="{{customer.name}}"
                                    matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">
                                    {{customer.name}}</mat-option>
                            </mat-autocomplete>
                            <mat-icon matSuffix style="font-size: 1.2em">search</mat-icon>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </div> <!-- 
            <div>
                <mat-expansion-panel #matExpansionPanelAccount [expanded]="this.toggleRefresh()">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Modify Refresh Charges
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <label>This is the number of times an entitlement can be activated after being initiality activated</label>
                        <mat-form-field>
                            <mat-label>Refresh Charges</mat-label>
                            <input type="text" appearance="outline" matInput [(ngModel)]="refreshCharges" spellcheck="false">
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
            </div> -->
            <div class="buttonsgrid">
                <div><button mat-raised-button color="accent" mat-dialog-close>Cancel</button></div>
                <div>
                    <button [disabled]="this.saveDisabled || this.disableSaveBtn" (click)="updateRecord()"
                        mat-raised-button>Update
                        Entitlement</button>
                    <div class="confirm" *ngIf="this.saveProceed">
                        <mat-expansion-panel [expanded]="this.saveProceed"
                            [afterExpand]="scrollToBottom('confirmUpdateBtnDiv')">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <label>Confirm Entitlement Update</label>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div>
                                <mat-form-field class="field" appearance="outline">
                                    <mat-label class="label">Update Reason</mat-label>
                                    <mat-hint>An update reason is required to proceed.</mat-hint>
                                    <textarea class="nomwidth" matInput placeholder="Enter the reason for this change"
                                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
                                        cdkAutosizeMaxRows="5" cols="60" rows="5" [(ngModel)]="this.saveComment"
                                        name="comment" spellcheck="true">
                                        </textarea>
                                    <div id="confirmUpdateBtnDiv">
                                        <div class="confirmgrid">
                                            <div>
                                                <button [disabled]="this.saveComment == '' || this.disableSaveBtn"
                                                    (click)="updateRecordConfirmed()" mat-raised-button>Confirm
                                                    Update</button>
                                                <p *ngIf="this.showmultialert" class="multihint">{{data.batchitems}}
                                                    entitlements will be affected!</p>
                                            </div>
                                        </div>
                                        <div id="updateRecordConfirmedDiv">&nbsp;</div>
                                    </div>
                                </mat-form-field>
                            </div>
                        </mat-expansion-panel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>