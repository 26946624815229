import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: [
    '../../shared-styles/divs.scss',
    '../../shared-styles/tables.scss',
    '../../shared-styles/elements.scss',
    './session-expired.component.scss'
  ]
})
export class SessionExpiredComponent {
  constructor(private router: Router)
  {
  }
  
  public navTo(newRoute: string) {
    this.router.navigate(['/login/']);
  }
}
