<div class="mainbox">
	<div class="leftsidebar">
		&nbsp;
	</div>
	<div class="submainbox">
		<div class="loginbox">
			<div class="loginopts">
				<div class="login">
					<div>
						<form novalidate #f="ngForm">
							<label mat-label>Use entitlement to activate new TenAsys license:</label>
							<mat-form-field appearance="outline">
								<mat-label>Enter Entitlement or SUAC</mat-label>
								<input matInput type="text" data-testid="entitlementid" [(ngModel)]="this.entitlementid" name="entitlementid"
									spellcheck="false">								
							</mat-form-field>
						</form>
					</div>
					<div style="align-self: center;">
						<button mat-raised-button color="accent" data-testid="entitlement-login-btn" (click)="tryLogin()">Proceed <mat-icon
								iconPositionEnd>arrow_right_alt</mat-icon></button>
					</div>
				</div>
				<div>
					<h3>OR</h3>
				</div>
				<div>
					<button mat-raised-button class="switchbtn" data-testid="user-login-btn" [disabled]="this.disallowLogin" (click)="gotoLogin()" id="go"
						(click)="gotoLogin()">Login to License Portal</button>
				</div>
			</div>
			<div>
				<hr />
				<app-status></app-status>
			</div>
		</div>
	</div>
	<div class="rightsidebar">
		&nbsp;
	</div>
</div>