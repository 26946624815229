import { Component } from '@angular/core';
import { AuthorizationService } from '../../services/authorization.service';
import { Router } from "@angular/router";
import { AuthorizationData } from '../../types/AuthorizationData';
import { AccountData } from '../../types/AccountData';
import { CustomerDataService } from '../../services/customer-data.service';
import { MatDialog } from '@angular/material/dialog';
import { SplashService } from '../../services/splash.service';
import { SnackService } from '../../services/snack.service';

@Component({
  selector: 'tenasys-entitlement-login',
  styleUrls: [
    '../../shared-styles/divs.scss',
    '../../shared-styles/tables.scss',
    '../../shared-styles/elements.scss',
    './entitlement-login.scss'
  ],
  templateUrl: './entitlement-login.html'
})

export class EntitlementLoginComponent {
  entitlementid: string = "";
  user: AuthorizationData = new AuthorizationData();
  LOADDURATION: number = 8;
  disallowLogin:boolean = false;

  constructor(private auth: AuthorizationService,
    private router: Router,
    private spinner: SplashService,
    private customerData: CustomerDataService,
    public snackMsg: SnackService,
    
    public dialog: MatDialog
  ) {
    this.auth.reset();
  }

  public gotoLogin() {
    this.router.navigate(['/login/userlogin']);
  }

  public tryLogin() {
    this.disallowLogin = true;
    if (this.entitlementid != "") {
      this.spinner.show(this.LOADDURATION);
      this.auth.entitlementLogin(this.entitlementid)
        .subscribe((data: any) => {
          this.customerData.loginType = "entitlement";
          if ('ErrorCode' in data) {
            let errCode = data["ErrorCode"];
            if (errCode == 107) {
              this.snackMsg.openSnackBar("Entitlement has already been activated.\n Login or contact your Operations Manager to retrieve the license.", 5000);              
            } else {
              this.snackMsg.openSnackBar("An error has occurred.  Please try again.", 5000);
            }
            this.disallowLogin = false;
          }
          else if (data.securityToken == "") {
            this.snackMsg.openSnackBar("An error has occurred.  Please try again.", 5000);
          } else {
            this.auth.setSecurityToken(data.securityToken);
            this.auth.setUserType(data.userType);
            let productname = data.contact.crmid;
            let tenasysentitilementid = data.contact.name;
            this.customerData.accountData = new AccountData();
            this.customerData.username = tenasysentitilementid;
            this.customerData.accountData.accountid = data.customer.id;
            this.auth.setSession();
            this.router.navigate(['/' + productname + '/activate']);
          }
          this.spinner.hide();
        },
          () => {
            this.spinner.hide();
            this.disallowLogin = false;
            this.snackMsg.openSnackBar("Entitlement Lookup Failed.", 5000);            
          }
        );
    } else {
      this.snackMsg.openSnackBar('A TenAsys entitlement is required to proceed.  The "Login to License Portal" button allows use of username and password to login.', 5000)
      this.disallowLogin = false;
    }
  }
}
