import { Product } from '../types/Product';

export class Invoice {
    name: string = "";
    salesorderid: string = "";
    invoicenumber: string = "";
    invoiceid: string = "";
    new_teginvoice: string = "";
    state: string = "";
    products: Array<ProductLineItem> = new Array<ProductLineItem>();
}

export class ProductLineItem {
    productName: string = "";
    quantity: number = 0;
    productType: string = "";
}