import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EntitlementService } from 'src/app/modules/services/ems.service';
import { SnackService } from 'src/app/modules/services/snack.service';
import { SplashService } from 'src/app/modules/services/splash.service';
import { saveAs } from 'file-saver';
import { AdminSearchResults, SearchEntitlements as SearchEntitlements } from 'src/app/modules/types/SearchEntitlements';
import { AuthorizationService } from 'src/app/modules/services/authorization.service';

@Component({
  selector: 'app-sales-administration',
  templateUrl: './sales-administration.component.html',
  styleUrls: [
    '../../../shared-styles/divs.scss',
    '../../../shared-styles/tables.scss',
    '../../../shared-styles/elements.scss',
    './sales-administration.component.scss'
  ]
})
export class SalesAdministrationComponent implements OnInit {
  batchid: string = "";
  searchFilter: string = "";
  searchPlaceHolder: string = "Search Filter...";
  searchType: string = "order";
  friendlySearchType: string = "Entitlements"
  showResults: boolean = false;
  entitlementsReturned: number = 0;
  resultsText: string = ""
  licenseType: string = "permanent";
  searchEntitlements: SearchEntitlements = new SearchEntitlements();
  constructor(
    private ems: EntitlementService,
    private auth: AuthorizationService,
    private splash: SplashService,
    private spinner: NgxSpinnerService,
    public snackMsg: SnackService,
  ) { }

  ngOnInit(): void {
    this.setSearchTypeByString(this.searchType);
  }

  search() {
    this.searchEntitlements.username = this.auth.getUsername();
    this.searchEntitlements.authToken = this.auth.getSecurityToken();
    if (this.searchFilter == "" || this.searchFilter.length < 4) {
      this.snackMsg.openSnackBar("A filter of at least 4 characters is required to search.", 10000);
    } else {
      this.snackMsg.openSnackBar("Searching " + this.friendlySearchType, 5000);
      this.spinner.show("spinneronly");
      this.searchEntitlements.searchtext = this.searchFilter;
      this.searchEntitlements.searchtype = this.searchType;
      this.searchEntitlements.licensetype = this.licenseType;
      this.ems.SearchEntitlements(this.searchEntitlements).subscribe((response) => {
        console.log(response);
        let res: Array<AdminSearchResults> = response;
        this.entitlementsReturned = res.length;
        this.snackMsg.openSnackBar(this.entitlementsReturned + " entitlements returned.", 5000);
        this.resultsText = JSON.stringify(res, null, 2);
        this.showResults = true;
        this.spinner.hide("spinneronly");
      });
    }
  }

  setSearchTypeByString(searchType) {
    this.searchType = searchType;
    if (searchType == "entitlements") {
      this.searchPlaceHolder = "Search for Entitlements (C070F4C8-8A26-45AD-9C8E-9B775036DF8F)";
      this.friendlySearchType = "entitlement information";
    } else if (searchType == "licenses") {
      this.searchPlaceHolder = "Search for Licenses (All Formats: *ABCKVEBJ";
      this.friendlySearchType = "license information";
    } else if (searchType == "usb") {
      this.searchPlaceHolder = "Search for USB/CID keys (27CF4-10225679-1)";
      this.friendlySearchType = "USB/CID keys information";
    } else if (searchType == "order") {
      this.searchPlaceHolder = "Search for Purchase Order and Invoice information (330-22030004 for 220-22020018)";
      this.friendlySearchType = "purchase order and invoice information";
    } else {
      this.searchPlaceHolder = "Search Filter...";
      this.searchType = "order";
    }
  }

  setSearchType(ev) {
    this.searchType = ev.target.id;
    return this.setSearchTypeByString(this.searchType);
  }

  getUsbDownload() {
    if (this.batchid == "") {
      this.snackMsg.openSnackBar("A BatchID number is required.  This can be found in the entitlements details when viewed as an administrator.", 10000);
      return;
    }
    this.spinner.show("spinneronly");
    let getUsbDownloadResult = this.ems.getUsbDownload<Blob>(this.batchid);
    var downloadName = this.batchid + ".usb";
    getUsbDownloadResult.subscribe(r => {
      saveAs(r, downloadName);
      this.spinner.hide("spinneronly");
    });
  }
}
