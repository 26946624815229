<div class="mainbox">
	<div class="leftsidebar">
		&nbsp;
	</div>
	<div class="submainbox">
		<div class="resetbox">
			<div>
				<h1 mat-dialog-title>Reset User Password</h1>
				<div mat-dialog-content>
					<div>
						<mat-form-field style="width:90%;">
							<mat-label>User</mat-label>
							<input matInput placeholder="User" type="text" [(ngModel)]="usertoreset" name="email" id="email"
								spellcheck="false" readonly>
						</mat-form-field>
						<br />
						<mat-form-field *ngIf="password != ''" style="width:90%;">
							<mat-label>Temporary Password</mat-label>
							<input matInput placeholder="Password" type="text" [(ngModel)]="password" name="password"
								id="password" spellcheck="false" readonly>
						</mat-form-field>
						<br />
					</div>
				</div>
			</div>
			<div class="buttongrid">
				<div><button mat-raised-button [disabled]="this.resetdisabled" color="accent" (click)="performReset()">Reset Password</button></div>
				<div><button mat-raised-button (click)="onNoClick()">{{this.closeButtonLegend()}}</button></div>
			</div>
		</div>
	</div>
	<div class="rightsidebar">
		&nbsp;
	</div>
</div>