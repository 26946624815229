<div class="mainbox">
	<div class="leftsidebar">
		&nbsp;
	</div>
	<div class="submainbox">
		<div class="helparea">
			<mat-label>
				<h3 *ngIf="loginType == 'entitlement'">Provide the target system fingerprint to activate entitlement for: <span
					style="font-weight: bolder;">{{this.activationInformation}}</span></h3>
				<h3 *ngIf="loginType == 'login'">
					Provide the target system fingerprint to activate the <span
						style="font-weight: bolder;">next</span> available entitlement for product <span
						style="font-weight: bolder; text-decoration: underline;">{{this.product}}</span> from your available inventory.
				</h3>
			</mat-label>
		</div>
		<div class="nomwidth">
			<mat-form-field class="field" appearance="outline">
				<mat-label class="label">Fingerprint</mat-label>
				<textarea class="nomwidth" matInput placeholder="Enter the fingerprint of target host"
					cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="20"
					cols="60" rows="20" [(ngModel)]="this.fingerprint" name="fingerprint"
					[readonly]="this.license != ''" spellcheck="false">
				</textarea>
			</mat-form-field>
		</div>
		<div style="text-align: left;">			
			<label style="padding-left: .5em;" mat-label>Please provide a comment to help identify the target system or project this license is generated for:</label>
			<mat-form-field class="field" appearance="outline">				
				<mat-label class="label">Activation Comment</mat-label>
				<input matInput type="text" [(ngModel)]="this.comment" name="comment" [readonly]="this.license != ''">
			</mat-form-field>
		</div>
		<div class="buttongrid">
			<button mat-raised-button disabled="{{this.license != ''}}" (click)="activate()" value="Activate"
				color="accent" class="createLicense">Create License</button>
			<button *ngIf="this.license != ''" mat-raised-button (click)="logout()" class="createLicense">Activate
				another?</button>
			<button *ngIf="this.license != ''" mat-raised-button (click)="showlicense()" class="createLicense">Show
				License</button>
		</div>
	</div>
	<div class="rightsidebar">
		&nbsp;
	</div>
</div>