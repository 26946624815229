import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-qrmodal',
  templateUrl: './qrmodal.component.html',
  styleUrls: [
    '../../shared-styles/divs.scss',
    '../../shared-styles/tables.scss',
    '../../shared-styles/elements.scss',
    './qrmodal.component.scss'
  ]
})
export class QrmodalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<QrmodalComponent>, @Inject(MAT_DIALOG_DATA) 
    public data: 
    {
      enhanced_license: string, 
      classic_license: string,
      usbid: string
    }) { }

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close('Closing!');
  }
}
