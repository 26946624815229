<div class="modal">
    <div>
        <h2 mat-dialog-title><span>{{data.datacolumn}}</span></h2>
    </div>
    <mat-dialog-content>
        <div class="celldata">{{data.datavalue}}</div>
        <div class="buttons">
            <mat-dialog-actions>
                <a mat-raised-button *ngIf="this.data.url != ''" mat-stroked-button href="{{this.data.url}}"
                    target="_blank">Open CRM
                </a>
                <button mat-raised-button color="accent" (click)="copy(this.data.datavalue)" value="Copy">Copy</button>
                <button mat-raised-button mat-dialog-close>Close</button>
            </mat-dialog-actions>
        </div>
    </mat-dialog-content>
</div>