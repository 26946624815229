
import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from "@angular/router";
import { forkJoin, Observable } from 'rxjs';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizationService } from '../../../services/authorization.service';
import { CustomerDataService } from '../../../services/customer-data.service';
import { EntitlementService } from '../../../services/ems.service';
import { EntitlementData } from '../../../types/EntitlementData';
import { saveAs } from 'file-saver';
import { formatDate } from '@angular/common';
import { SplashService } from 'src/app/modules/services/splash.service';
import { CellpopupComponent } from 'src/app/modules/shared-components/cellpopup/cellpopup.component';
import { SnackService } from 'src/app/modules/services/snack.service';
import { SelectColumnsComponent } from 'src/app/modules/shared-components/select-columns/select-columns.component';
import { ColumnData, UpdateColumnRequest, UpdateColumnResponse } from 'src/app/modules/types/ColumnData';

@Component({
  selector: 'app-primary-manage',
  templateUrl: './primary-manage.component.html',
  styleUrls: [
    '../../../shared-styles/divs.scss',
    '../../../shared-styles/tables.scss',
    '../../../shared-styles/elements.scss',
    './primary-manage.component.scss']
})
export class PrimaryManageComponent implements OnInit {
  pageSize: number = 0;
  pages: number = 1;
  entitlements: Array<EntitlementData> = new Array<EntitlementData>();
  user: string = "";
  accountid: string = "";
  accountname: string = "";
  dataSource: MatTableDataSource<EntitlementData> = new MatTableDataSource<EntitlementData>();
  filterActivatedState: number = 0;
  filterReservedState: number = 0;
  filterCidState: number = 0;
  filterProductState: string = "all";
  LOADDURATION: number = 60;
  filterString: string = "";
  products: Array<string> = new Array<string>();
  hasData: boolean = true;
  filteredRecordsTotal: number = 0;
  userMode: boolean = false;
  getExportDataUrl: string = "";
  download_started: boolean = false;
  column_data: ColumnData[] = [];
  saved_column_data: ColumnData[] = [];
  displayedColumns: string[] = [];
  dirty: boolean = false;
  stringSplit = "!$1$!";
  pageEvent: PageEvent = new PageEvent();
  filterPredicate?: (data: any, filter: string) => boolean;

  //sort;
  @ViewChild(MatTable, { static: true }) table?: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) paginator?: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort?: MatSort;

  constructor(
    private datePipe: DatePipe = new DatePipe('mediumDate'),
    private ems: EntitlementService,
    private auth: AuthorizationService,
    private splash: SplashService,
    public spinner: NgxSpinnerService,
    public spinner_0: NgxSpinnerService,
    private route: ActivatedRoute,
    private customerData: CustomerDataService,
    public snackMsg: SnackService,
    public dialog: MatDialog) {
    splash.show(this.LOADDURATION);
    this.filteredRecordsTotal = this.customerData.totalEntitlements;
    this.pageSize = this.ems.getPageSize();
    this.accountid = this.customerData.accountData.accountid;
    this.user = this.route.snapshot.paramMap.get('username') ?? "";
    var getContactAccountURL = "";
    if (this.ems.getUserMode() != true) {
      getContactAccountURL = this.ems.getContactAccountURL(this.auth.getCRMID(), 1, this.pageSize, false);
    } else {
      getContactAccountURL = this.ems.getContactAccountURL(this.auth.getCRMID(), 1, this.pageSize, false, this.accountid);
    }
    const getProducts = this.ems.getProducts();
    const getAccount = this.ems.getAccountUser(getContactAccountURL);
    const getAccountInvoices = this.ems.getAccountInvoices(this.accountid);
    const getUserSettings = this.ems.getUserSettings(this.auth.getCRMID(), this.ems.userMode);
    let pages = Math.ceil(this.customerData.totalEntitlements / this.pageSize);
    var getQueries: Array<Observable<any>> = new Array<Observable<any>>();
    getQueries.push(getProducts);
    getQueries.push(getAccount);
    getQueries.push(getUserSettings);
    getQueries.push(getAccountInvoices);
    for (let i = 1; i <= pages; i++) {
      if (this.ems.getUserMode() != true) {
        getQueries.push(this.ems.getEntitlements(this.customerData.accountData.accountid,
          0, 0, 0, this.filterProductState, '', i, this.pageSize, false));
      }
      else {
        getQueries.push(this.ems.getEntitlements(this.customerData.accountData.accountid,
          0, 0, 0, this.filterProductState, '', i, this.pageSize, true));
      }
    }

    forkJoin(getQueries).subscribe(next => {
      if (next == null) {
        splash.hide();
        return;
      }
      const productResult = next[0]
      const accountResult = next[1];
      const userResult:UpdateColumnResponse = next[2];
      const accountInvoiceResult = next[3];
      this.accountname = accountResult.account.name;
      if (userResult.ColumnDataCustom != null) {
        userResult.ColumnDataCustom = userResult.ColumnDataCustom.sort((a, b) => a.index - b.index); 
        this.column_data = userResult.ColumnDataCustom;
      } else {
        userResult.ColumnDataDefault = userResult.ColumnDataDefault.sort((a, b) => a.index - b.index);    
        this.column_data = userResult.ColumnDataDefault;
      }
      this.saved_column_data = JSON.parse(JSON.stringify(userResult.ColumnDataDefault));
      this.displayedColumns = this.getDisplayedColumnsAll();
      this.entitlements = new Array<EntitlementData>();
      let productMap: Map<string, string> = new Map<string, string>();
      this.applyFilter();

      for (let i = 1; i <= next.length - 1; i++) {
        let getEntitlementsResult = next[i]
        for (let e in getEntitlementsResult.entitlements) {
          let entitlement: EntitlementData = getEntitlementsResult.entitlements[e];

          // add in product info
          let product = productResult.filter((value) => value.Id == entitlement.productmapid)[0];
          entitlement.productname = product.ProductName;

          // add in invoice info
          let invoice = accountInvoiceResult.filter((value) => entitlement.crminvoiceid != null && value.crminvoiceid.toLowerCase() == entitlement.crminvoiceid.toLowerCase());
          if (invoice.length >= 1) {
            entitlement.crminvoicename = invoice[0].crminvoicename;
          } else {
            entitlement.crminvoicename = "";
          }

          if (!productMap.has(getEntitlementsResult.entitlements[e].productname)) {
            productMap.set(getEntitlementsResult.entitlements[e].productname, getEntitlementsResult.entitlements[e].productname);
            this.products.push(getEntitlementsResult.entitlements[e].productname);
          }

          this.entitlements.push(entitlement);
        }
      }
      this.dataSource = new MatTableDataSource(this.entitlements);
      this.hasData = this.dataSource.data.length > 0;
      this.dataSource.paginator = this.paginator ?? null;
      this.dataSource.sort = this.sort ?? null;
      splash.hide();
    }, () => {
      this.snackMsg.openSnackBar("An Error Has Occurred.", 5000);
      this.splash.hide();
    });
  }

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator ?? null;
      if (this.sort != undefined) this.sort.sortChange.subscribe(next => {
        if (this.paginator != null) this.paginator.pageIndex = 0;
      }, () => {
        this.snackMsg.openSnackBar("An Error Has Occurred.", 5000);
        this.splash.hide();
      });
    }
  }


  updateFilterPredicate(filterValues: string, column_data: ColumnData[]) {
    this.dataSource.filterPredicate = function (d: EntitlementData, filter: string): boolean {
      let filter_parts = filter.split("!$1$!");
      filter = filter_parts[0];
      let productFilter = filter_parts[1];
      let activated = Number(filter_parts[2]);
      let cid = Number(filter_parts[3]);
      let keepRow = true;
      let searchPO = true;
      let searchProductName = true;
      let searchTenasysEntitlementID = true;
      let searchSUAC = true;
      let searchLicense = true;
      let searchCustomerActivationComment = true;
      let searchUSBKey = true;
      let searchActivatedBy = true;
      let searchActivatedDate = true;
      let searchIPAddress = true;
      let searchCRMInvoiceName = true;
      let searchClassicInvoiceNumber = true;
      let searchClassicOrderNumber = true;
      let searchCreatedDate = true;
      let searchCIDClassicLicense = true;
      let searchFingerprint = true;
      function getVisibleByColumnId(columnid: string) {
        return column_data.filter(e => e.id == columnid).map(e => e.visible).pop();
      }
      if (column_data != null) {
        searchPO = getVisibleByColumnId("ponumber");
        searchCRMInvoiceName = getVisibleByColumnId("crminvoicename");
        searchProductName = getVisibleByColumnId("productname");
        searchTenasysEntitlementID = getVisibleByColumnId("entitlement");
        searchSUAC = getVisibleByColumnId("suac");
        searchLicense = getVisibleByColumnId("license");
        searchCustomerActivationComment = getVisibleByColumnId("comment");
        searchUSBKey = getVisibleByColumnId("usbkey");
        searchActivatedBy = getVisibleByColumnId("activatedby");
        searchFingerprint = getVisibleByColumnId("fingerprint");
        searchActivatedDate = getVisibleByColumnId("activateddate");
        searchIPAddress = getVisibleByColumnId("ipaddress");
        searchClassicInvoiceNumber = getVisibleByColumnId("classicinvoice");
        searchClassicOrderNumber = getVisibleByColumnId("classicorder");
        searchCreatedDate = getVisibleByColumnId("createddate");
        searchCIDClassicLicense = getVisibleByColumnId("cidclassiclicense");
      }

      // ensure case insensitivity
      filter = filter.toLowerCase();

      const foundProductName = searchProductName && d.productname != null && d.productname.toLowerCase().trim().indexOf(filter) != -1;
      const foundTenasysEntitlementID = searchTenasysEntitlementID && d.tenasysentitlementid != null && d.tenasysentitlementid.toLowerCase().trim().indexOf(filter) != -1;
      const foundActivatedDate = searchActivatedDate != null && d.activateddate != null && d.activateddate.toString().toLowerCase().indexOf(filter) != -1;
      const foundActivatedBy = searchActivatedBy && d.activatedby != null && d.activatedby.toLowerCase().trim().indexOf(filter) != -1;
      const foundLicense = searchLicense && d.license != null && d.license.toLowerCase().trim().startsWith(filter);
      const foundFingerprint = searchFingerprint && d.fingerprint != null && d.fingerprint.toLowerCase().trim().indexOf(filter) != -1;
      const foundCustomerActivationComment = searchCustomerActivationComment && d.customeractivationcomment != null && d.customeractivationcomment.toLowerCase().trim().indexOf(filter) != -1;
      const foundIPAddress = searchIPAddress && d.ipaddress != null && d.ipaddress.toLowerCase().trim().indexOf(filter) != -1;
      const foundCRMInvoiceName = searchCRMInvoiceName && d.crminvoicename != null && d.crminvoicename.toLowerCase().trim().indexOf(filter) != -1;
      const foundPO = searchPO && d.ponumber != null && d.ponumber.toLowerCase().trim().indexOf(filter) != -1;
      const foundUSBKey = searchUSBKey && d.usbkey != null && d.usbkey.toLowerCase().trim().indexOf(filter) != -1;
      const foundCreatedDate = searchCreatedDate != null && d.createddate != null && d.createddate.toString().toLowerCase().indexOf(filter) != -1;
      const foundSUAC = searchSUAC && d.suac != null && d.suac.toLowerCase().indexOf(filter) != -1;
      const foundClassicInvoiceNumber = searchClassicInvoiceNumber && d.classicinvoicenumber != null && d.classicinvoicenumber.toLowerCase().indexOf(filter) != -1;
      const foundClassicOrderNumber = searchClassicOrderNumber && d.classicordernumber != null && d.classicordernumber.toLowerCase().indexOf(filter) != -1;
      const foundCIDClassicLicense = searchCIDClassicLicense && d.cidclassiclicense != null && d.cidclassiclicense.toLowerCase().startsWith(filter);
      if (filter.length == 0 || foundProductName || foundTenasysEntitlementID || foundActivatedDate || foundActivatedBy ||
        foundLicense || foundFingerprint || foundCustomerActivationComment || foundIPAddress || foundCRMInvoiceName || foundPO ||
        foundUSBKey || foundCreatedDate || foundSUAC || foundClassicInvoiceNumber ||
        foundClassicOrderNumber || foundCIDClassicLicense) {
        keepRow = true;
      } else {
        keepRow = false;
      }

      if (keepRow) {
        if (cid === 0 || (cid === 1 && (d.usbkey !== "" && d.usbkey !== null)) || (cid === 2 && (d.usbkey === "" || d.usbkey === null))) {
          keepRow = true;
        } else {
          keepRow = false;
        }
      }

      if (keepRow) {
        if (productFilter.toLowerCase() === "" || productFilter.toLowerCase() === "all" || d.productname.toLowerCase() === productFilter.toLowerCase()) {
          keepRow = true;
        } else {
          keepRow = false;
        }
      }

      if (keepRow) {
        if (activated == 0
          || (activated == 1 && !(d.license == null || d.license == ""))
          || (activated == 2 && (d.license == null || d.license == ""))) {
          keepRow = true;
        } else {
          keepRow = false;
        }
      }
      return keepRow;
    }
    this.dataSource.filter = filterValues;
  }

  save() {
    this.snackMsg.openSnackBar("Saving your changes, please wait!", 5000);
    this.spinner.show("spinneronly");
    let req = new UpdateColumnRequest("opman-entitlements");
    req.userid = this.auth.getCrmID();
    req.column_data = this.column_data;
    this.ems.saveEntitlementDetailColumns(req).subscribe((respond) => {
      let updatemsg = "An error occurred while saving your column settings.  Please try again later.";
      if (respond.TotalUpdated > 0) {
        updatemsg = "Your column settings have been saved.";
        this.snackMsg.openSnackBar(updatemsg, 5000);
      }
    });
    this.spinner.hide("spinneronly");
  }

  getFreeze(columnid: string) {
    let freeze = false;
    let col = this.column_data.filter(e => e.id == columnid);
    if (col.length != 0) {
      freeze = col[0].freeze;
     }
    return freeze;
  }

  getWarmData() {
    if (this.column_data == null) return [];
    let warm = this.column_data.filter(e => !e.freeze).map(e => e);
    return warm;
  }

  getFrozenData() {
    if (this.column_data == null) return [];
    let cold = this.column_data.filter(e => e.freeze).map(e => e);
    return cold;
  }

  getDisplayedColumnsAll() {
    let newcolumns: ColumnData[] = [];
    newcolumns = newcolumns.concat(this.getFrozenData());
    newcolumns = newcolumns.concat(this.getWarmData().filter(e => e.visible));
    return newcolumns.map(e => e.id);
  }

  selectColumns() {
    const dialogRef = this.dialog.open(SelectColumnsComponent, {
      data: {
        datasource: this.dataSource,
        column_data: this.column_data,
        saved_column_data: this.saved_column_data,
        dirty: this.dirty
      },
      width: '500px',
      panelClass: 'selectcolumns-dialog'
    });
    dialogRef.componentInstance.emitService.subscribe((val) => {
      this.column_data = val;
      this.applyFilter();
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.dirty != null) {
        this.dirty = result.dirty;
      }
      if (result && result.save) {
        if (this.dirty) {
          this.dirty = false;
          this.save();
        } else {
          this.snackMsg.openSnackBar("Nothing to save.", 5000);
        }
      }
    });
  }

  showDetail(datacolumn: string, input: string) {
    if (input != null && input.toString().trim() != "") {
      const dialogRef = this.dialog.open(CellpopupComponent, {
        data: {
          datavalue: input,
          datacolumn: datacolumn,
          url: ""
        },
        width: '35vw'
      });

      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  isActive(license: string) {
    return (license != undefined && license != "") ? "True" : "False";
  }

  public copy(input: string) {
    let selBox = document.createElement('textarea');
    let inputRemoveBR = input.replace(new RegExp('<br />', 'gi'), '\r\n');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = inputRemoveBR;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy', false, null);
    document.body.removeChild(selBox);
  }

  public getReservedButtonText() {
    if (this.filterReservedState == 0) return 'All';
    if (this.filterReservedState == 1) return 'False';
    if (this.filterReservedState == 2) return 'True';
    return 'All';
  }

  public getProductList() {
    return this.products;
  }

  public setProductFilter(product) {
    this.filterProductState = product;
    this.applyFilter();
  }

  public setUsedFilter(used) {
    this.filterActivatedState = used;
    this.applyFilter();
  }

  public setCIDFilter(cid) {
    this.filterCidState = cid;
    this.applyFilter();
  }

  applyFilterEvent(filterEvent: Event) {
    if (filterEvent != null) {
      this.filterString = (<HTMLInputElement>filterEvent.target).value;
      this.applyFilter();
    }
  }

  applyFilter(newFilter?: string) {
    if (newFilter != null) {
      this.filterString = newFilter;
    }
    let fullFilter = `${this.filterString}${this.stringSplit}${this.filterProductState}${this.stringSplit}${this.filterActivatedState}${this.stringSplit}${this.filterCidState}`;
    this.updateFilterPredicate(fullFilter, this.column_data);
    this.hasData = this.dataSource.data.length > 0;
    if (this.paginator != null) this.dataSource.paginator = this.paginator;
    if (this.sort != null) this.dataSource.sort = this.sort;
    this.download_started = false;  // allow the download button to be clicked again
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  downloadFileByRecord($event) {
    if (!this.download_started) {
      this.download_started = true;
      var today = formatDate(Date.now(), 'yyyy-MMM-dd_HH-mm-ss', 'en_US');
      var downloadName = this.accountname + "_" + today + "_" + "TenAsysEntitlements.csv";
      this.spinner_0.show("spinner_0");
      this.snackMsg.openSnackBar("Creating TenAsys Entitlement data download: " + downloadName, 8000);
      var page = 1;
      var pagesize = 1000000;
      this.ems.GetExport<Blob>(this.accountid, this.filterCidState,
        this.filterActivatedState, this.filterReservedState,
        this.filterProductState, this.filterString, page, pagesize, false)
        .subscribe(blob => {
          saveAs(blob, downloadName);
        },
          err => {
            this.snackMsg.openSnackBar("An error occurred during the creation of your TenAsys Entitlement data download.", 5000 * 4);
            this.spinner_0.hide("spinner_0")
            this.download_started = false;
          },
          () => {
            this.spinner_0.hide("spinner_0")
            this.download_started = false;
          });
    } else {
      this.snackMsg.openSnackBar("The process to create TenAsys Entitlement data download already in progress.  Please wait!", 5000);
    }
  }
}


