import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { interval, Observable } from 'rxjs';
import { SplashService } from '../../services/splash.service';
import { SplashMessage } from '../../types/splash-message';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: [
    '../../shared-styles/divs.scss',
    '../../shared-styles/tables.scss',
    '../../shared-styles/elements.scss',
    './splash.component.scss'
  ]
})
export class SplashComponent implements OnInit {
  @Output()
  slash = new EventEmitter<SplashMessage>();
  public opacityChange = 1;
  public splashTransition;
  public splashSpeed: string = "0";
  public splashSpeed2: string = "0";
  public splashDirection;
  public showSplash: boolean = false;
  public progressbarValue: number = 0;
  curSec: number = 0;

  constructor(
    private spinner: NgxSpinnerService, private splash: SplashService) { }

  ngOnInit(): void {
    this.splash.observe().subscribe((d) => {
      this.showSplash = d.showSplash;
      let duration: number = d.duration;
      this.showhide(duration);
    });
  }

  public showhide(duration) {
    this.startTimer(duration)
    this.splashSpeed = duration + "s";
    this.splashDirection = "normal";
    if (this.showSplash) {
      this.opacityChange = 1;
    } else {
      this.opacityChange = 0;
    }
  }

  startTimer(durationInSec: number) {
    const timer = interval(1000);
    const sub = timer.subscribe((sec) => {
      let current: number = 1 + sec * 100;
      this.progressbarValue = current / durationInSec;
      this.curSec = sec;
      if (this.curSec === durationInSec) {
        this.curSec = 0;
        sub.unsubscribe();
      }
    });
  }
}


