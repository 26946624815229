<div class="mainbox">
    <div class="leftsidebar">
        &nbsp;
    </div>
    <div class="submainbox">
        <div>
            <h1 class="hl1">INtime USB/CID License Retrieval Portal Access</h1>
        </div>
        <div class="qrgrid">
            <div class="intro">
                <div class="tut">
                    You may access the digital license content by applying your account credentials here,
                    <em>OR</em> by other means referenced in these documents.
                    <ul>
                        <li><a class="hlink" target="_blank" mat-button color="accent"
                                href="https://tenasys.com/about/policies/intime-for-windows-licensing-appendix-7-cid-locked-procedure/">INtime
                                for Windows INTIME-RT/MCRT Licenses</a></li>
                        <li><a class="hlink" target="_blank" mat-button color="accent"
                                href="https://tenasys.com/about/policies/intime-distributed-rtos-licensing-appendix-7-cid-locked-procedure/">INtime
                                Distributed RTOS-RT/MCRT Licenses</a></li>
                    </ul>
                </div>
            </div>
            <div style="border: 1px solid #E12522; border-radius: 5px; background-color: #E12522;"></div>
            <div class="result">
                <div [ngClass]="this.auth.isLoggedIn() == false ? 'sepgrid' : 'lichidden'">
                    <div>&nbsp;</div>
                    <div><mat-label></mat-label></div>
                    <div>&nbsp;</div>
                </div>
                <div
                    [ngClass]="this.auth.isLoggedIn() == true && this.cidData != null && !this.cidData.hasOwnProperty('ErrorCode') ? 'sepgrid' : 'lichidden'">
                    <button class="inputs" mat-stroked-button value="Login" (click)="showLicense()"><span
                            style="color: black">View License Details</span><mat-icon>arrow_right_alt</mat-icon>
                    </button>
                </div>
                <div id="login"
                    [ngClass]="this.auth.isLoggedIn() == false || this.cidData == null || (this.cidData != null && this.cidData.hasOwnProperty('ErrorCode')) ? 'licshown' : 'lichidden'">
                    <div class="logingrid">
                        <div>
                            <mat-form-field appearance="outline" class="inputs">
                                <mat-label>Enter USB/CID Serial Number</mat-label>
                                <input matInput type="text" placeholder="USB/CID Serial Number"
                                    [(ngModel)]="this.serial" data-testid="serial" name="serial" id="serial">
                                <mat-hint>XXXXX-XXXXXXX-X</mat-hint>
                            </mat-form-field>
                        </div>

                        <div>
                            <mat-form-field appearance="outline" class="inputs">
                                <mat-label>Enter Username</mat-label>
                                <input matInput type="text" placeholder="Username (Email Address)"
                                    [(ngModel)]="this.username" data-testid="username" name="username" id="username">
                                <mat-icon matSuffix>email</mat-icon>
                                <mat-hint>Your username is your company email</mat-hint>
                            </mat-form-field>
                        </div>

                        <div>
                            <mat-form-field appearance="outline" class="inputs">
                                <mat-label>Enter Password</mat-label>
                                <input matInput placeholder="Password" data-testid="password" type="password" [(ngModel)]="this.password"
                                    name="password" id="password" spellcheck="false" (keydown)="this.onPasswordChange($event.key)">
                            </mat-form-field>
                        </div>

                        <mat-hint>Contact your Operations Manager if you need assistance logging in.</mat-hint>

                        <div id="login">
                            <button class="inputs" mat-raised-button color="accent" data-testid="login-btn" value="Login"
                                (click)="tryLogin()"><span>Proceed</span><mat-icon iconPositionEnd>arrow_right_alt</mat-icon></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="rightsidebar">
        &nbsp;
    </div>
</div>