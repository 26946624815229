<div class="mainbox">
    <div class="leftsidebar">
        &nbsp;
    </div>
    <div class="submainbox">
        <div class="statusgrid">
            <canvas class="chart" id="loginChart">{{ loginchart }}</canvas>
            <canvas class="chart" id="login2Chart">{{ login2chart }}</canvas>
            <div class="tablebox">
                <div class="tableform">
                    <form>
                        <mat-form-field>
                            <input matInput (keyup)="applyFilterUserEvent($event)" placeholder="Filter"
                                spellcheck="false">
                            <mat-icon matSuffix style="font-size: 1.2em">search</mat-icon>
                        </mat-form-field>
                    </form>
                </div>
                <div class="table">
                    <table mat-table [dataSource]="userLoginData" matSort aria-sort="descending"
                        matSortActive="creationtime" matSortDirection="desc">
                        <ng-container matColumnDef="creationtime">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Timestamp </th>
                            <td mat-cell *matCellDef="let element"> {{ element.creationtime | date:'long' }}</td>
                        </ng-container>
                        <ng-container matColumnDef="user">
                            <th mat-header-cell *matHeaderCellDef> User </th>
                            <td mat-cell *matCellDef="let element" matTooltipClass="tooltip-long"> {{ element.user }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef> Type </th>
                            <td mat-cell *matCellDef="let element" matTooltipClass="tooltip-long"> {{ element.type }}
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="loginDisplayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: loginDisplayedColumns;"></tr>
                    </table>
                    <mat-paginator [length]="10" [pageSize]="10" aria-label="Select page"></mat-paginator>
                </div>
            </div>
        </div>
    </div>
    <div class="rightsidebar">
        &nbsp;
    </div>
</div>