import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SplashMessage } from '../types/splash-message';

@Injectable()
export class SplashService {
    private showSplash:Subject<SplashMessage> = new Subject<SplashMessage>();
    private msg = new SplashMessage();

    public show(duration:number) {        
        this.msg.showSplash = true;
        this.msg.duration = duration;
        this.showSplash.next(this.msg);
    }

    public hide() {
        this.msg.showSplash = false;
        this.showSplash.next(this.msg);
    }

    public observe():Observable<SplashMessage> {
        return this.showSplash.asObservable();
    }
}