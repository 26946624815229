import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EntitlementService } from '../../../services/ems.service';
import { AuthorizationService } from '../../../services/authorization.service';
import { LogData } from '../../../types/LogData';
import { DatePipe } from '@angular/common';
import { SplashService } from 'src/app/modules/services/splash.service';

@Component({
  selector: 'app-admin-log-view',
  templateUrl: './admin-log-view.component.html',
  styleUrls: [
    '../../../shared-styles/divs.scss',
    '../../../shared-styles/tables.scss',
    '../../../shared-styles/elements.scss',
    './admin-log-view.component.scss'
  ]
})
export class AdminLogViewComponent implements AfterViewInit {
  dataSource: MatTableDataSource<LogData> = new MatTableDataSource<LogData>();
  fieldText: string = "";
  LOADDURATION: number = 8;
  displayedColumns: string[] = [
    'creationtime',
    'runid',
    'username',
    'detail'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private ems: EntitlementService,
    private auth: AuthorizationService,
    private spinner: SplashService,
    private datePipe: DatePipe
  ) { 
    this.spinner.show(this.LOADDURATION);
    this.ems.getLogs(1, 5000).subscribe((data) =>{
      this.dataSource = new MatTableDataSource(data);
      if (this.paginator != null) this.dataSource.paginator = this.paginator;
      if (this.sort != null) this.dataSource.sort = this.sort;
      this.spinner.hide();
    }, 
      () => this.spinner.hide()
    );
  }
  
  showDetail(input: string){
    this.fieldText = input;
  }

  applyFilterEvent(filterEvent: Event){
    if (filterEvent != null) this.applyFilter((<HTMLInputElement>filterEvent.target).value);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public copy(input: string){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = input;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  ngAfterViewInit(): void {
    if (this.dataSource){
      if (this.paginator != null) this.dataSource.paginator = this.paginator;
      if (this.sort != null) this.sort.sortChange.subscribe(() => {if (this.paginator != null) this.paginator.pageIndex = 0});
    }
  }
}
