import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-licensepopup',
  templateUrl: './licensepopup.component.html',
  styleUrls: [
    '../../../../shared-styles/divs.scss',
    '../../../../shared-styles/tables.scss',
    '../../../../shared-styles/elements.scss',
    './licensepopup.component.scss']
})
export class LicensepopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LicensepopupComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: {datacolumn: string, datavalue: string, licenseurl: string}) {  
  }

 ngOnInit(): void {

 }

 closeDialog() {
   this.dialogRef.close('Closing!');
 }

 public copy(input:string){
  let selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = input;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
}
}
