import { Injectable } from '@angular/core';
import { AccountData } from '../types/AccountData';
import { Product } from '../types/Product';

@Injectable()
export class CustomerDataService {
    public accountData: AccountData = new AccountData();
    public productData: Array<Product> = new Array<Product>();
    public username: string = "";
    public loginType: string = "";
    public priorRoute: string = "";
    public totalEntitlements: number = 0;
    public adminCustomerAccount: string = ""; // displayed in upper-right up screen for admins
    public adminCustomerAccountGuid: string = ""; // displayed in upper-right up screen for admins
    public adminCrmEndpoint: string = ""; // displayed in upper-right up screen for admins
   
    public reset(){
        this.accountData = new AccountData();
        this.productData = new Array<Product>();
        this.username = "";
        this.loginType = "";
    }

    public switchAccount(id: string, name: string) {
        this.accountData.accountid = id;
        this.accountData.name = name;
    }
}