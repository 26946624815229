import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// this service is used for parent/child communication for user/ops man landing pages for the USB/CID search functionality
@Injectable({
    providedIn: 'root'
})
export class UsbCidSearchService {
    hasUsb = new BehaviorSubject<boolean>(false);
    accountid = new BehaviorSubject<string>("");
    constructor() {
        // this.hasUsb.subscribe({
        //     next: (v) => console.log('hasUsb: ' + v)
        // });

        // this.accountid.subscribe({
        //     next: (v) => console.log('accountid: ' + v)
        // });
    }
}