import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Contact } from '../../../types/Contact';
import { AuthorizationService } from '../../../services/authorization.service';
import { CustomerDataService } from '../../../services/customer-data.service';
import { Router, ActivatedRoute } from "@angular/router";
import { EntitlementService } from '../../../services/ems.service';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackService } from 'src/app/modules/services/snack.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-primary-edit-users',
  templateUrl: './primary-edit-users.component.html',
  styleUrls: [
    '../../../shared-styles/divs.scss',
    '../../../shared-styles/tables.scss',
    '../../../shared-styles/elements.scss',
    './primary-edit-users.component.scss'
  ]
})
export class PrimaryEditUsersComponent {
  user: string = "";
  users: Array<Contact> = new Array<Contact>();
  adduser: boolean = false;
  newuseradded: boolean = false;
  LOADDURATION: number = 8;
  firstname = new UntypedFormControl('', [Validators.required, Validators.minLength(1)]);
  lastname = new UntypedFormControl('', [Validators.required, Validators.minLength(1)]);
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  password = new UntypedFormControl('', [Validators.required, Validators.minLength(10)]);

  constructor(
    private ems: EntitlementService,
    private auth: AuthorizationService,
    private router: Router, 
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public snackMsg: SnackService, 
    public dialog: MatDialog,
    private location: Location
    ) {
      this.spinner.show("spinneronly"); 
      this.user = this.route.snapshot.paramMap.get('username') ?? "";
      this.loadUsers();
  }

  //Edit User Methods
  displayedColumns: string[] = ['name', 'email', 'deactivate', 'changepassword'];
  dataSource = new MatTableDataSource(new Array<Contact>());

  private loadUsers(): void {
    this.spinner.show("spinneronly"); 
    let getContacts = this.ems.getContacts(this.auth.getCRMID());
      forkJoin([getContacts]).subscribe( next =>{
        let raw = JSON.stringify(next);
        let getContactsResult = JSON.parse(raw);
        this.users = new Array<Contact>();
        for(let c in getContactsResult[0]){
          let contact:Contact = getContactsResult[0][c];
          this.users.push({
              name: contact.name,
              id: contact.id,
              email: contact.email
          }); 
        }
        this.dataSource = new MatTableDataSource(this.users);
        this.spinner.hide("spinneronly"); 
      }, () => {
        this.snackMsg.openSnackBar("An Error Has Occurred.", 5000); 
        this.spinner.hide("spinneronly"); 
      });
  }

  applyFilterEvent(filterEvent: Event){
    if (filterEvent == null) return;
    let filterValue = (<HTMLInputElement>filterEvent.target).value;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  remove(userid: string){
      this.spinner.show("spinneronly"); 
      let user = this.users.find(u => u.id == userid);
      if (user == null) return;
      let remove = this.ems.removeContact(user.id, user.email, this.auth.getCRMID());
      remove.subscribe((next:Boolean) => {
        if (next == true){
          let index = this.users.findIndex(u => u.id == userid);
          this.users.splice(index, 1);
          this.dataSource = new MatTableDataSource(this.users);
          this.spinner.hide("spinneronly"); 
          if (user == null) return;
          this.snackMsg.openSnackBar("Removed " + user.name, 5000);
        }
        else {
          this.snackMsg.openSnackBar("An Error Has Occurred.", 5000); 
          this.spinner.hide("spinneronly"); 
        } 
      }, () => {
        this.snackMsg.openSnackBar("An Error Has Occurred.", 5000); 
        this.spinner.hide("spinneronly"); 
      });
  }

  add(){
    this.adduser = true;
    this.newuseradded = false;
    var pass:string = Math.random().toString(20).substring(2,12);
    pass = pass.trim();
    //pass = pass.concat("Bb2");
    pass += "Bb2";
    while (pass.length < 10)
    {
      pass += Math.random().toString(20).substring(2,12);
      pass = pass.trim();
    }
    this.password.setValue(pass.substring(0,13));
  }

  updatepassword(element: any){
    this.router.navigate(['/' + this.user + '/superuser/users/resetpassword/' + element.email]);
  }

  // Add user methods

  savenewuser(){
    if (this.password.value == null ||
      this.password.value.length < 8 ||
      this.password.value.length > 30 ||
      !(this.password.value.match('[a-z]')) ||
      !(this.password.value.match('[A-Z]')) ||
      !(this.password.value.match('[0-9\+!@$%^&*()_-]')) ||
      (this.password.value.match('[^a-zA-Z0-9\+!@$%^&*()_-]') != null)) {
    this.snackMsg.openSnackBar("Password must be 8 to 30 characters, include 1 upper case, 1 lower case, and 1 number OR special character (!@$%^&*()_-+)", 5000); return; }
  //if (this.password.value == null || this.password.value.length < 10) { this.snackMsg.openSnackBar('Password must be 10+ Characters', 5000); return; }
    if (this.firstname.value == null || this.firstname.value.length < 1) { this.snackMsg.openSnackBar("First name can't be empty.", 5000); return; }
    if (this.lastname.value == null || this.lastname.value.length < 1) { this.snackMsg.openSnackBar("Last name can't be empty.", 5000); return; }
    if (this.email.value == null || !this.email.valid) { this.snackMsg.openSnackBar("Email is not valid.", 5000); return; }

    this.spinner.show("spinneronly"); 
    this.ems.addContact(this.firstname.value.trim(), this.lastname.value.trim(), this.email.value.trim(), this.password.value.trim(), this.auth.getCRMID()).subscribe((next:any) =>{
      if (next == null){
        this.spinner.hide("spinneronly"); 
        return;
      }
      let raw = JSON.stringify(next);
      let result = JSON.parse(raw);
      this.loadUsers();
      if (result == true) {
        this.snackMsg.openSnackBar("Added " + this.firstname.value + " " + this.lastname.value, 5000); 
        this.location.back();
      }
      if (result == false) this.snackMsg.openSnackBar("Failed to add " + this.firstname.value+ " " + this.lastname.value, 5000); 
      this.newuseradded = result;
      this.spinner.hide("spinneronly"); 
    },
    () => { 
      this.snackMsg.openSnackBar("Error: Failed to add " + this.firstname.value + " " + this.lastname.value, 5000); 
      this.spinner.hide("spinneronly"); 
    });
  }

  public goback() {
    this.location.back();
  }


  onNoClick(){
    this.adduser = false;  
  }
}
