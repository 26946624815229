<mat-dialog class="selectcolumns">
    <mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <div class="titlegrid" cdkDragHandle>
            <div>
                <h1 data-testid="select-columns-title" class="title">Column Editor</h1>
            </div>
            <div class="dragger">
                <button mat-icon-button>
                    <mat-icon style="font-size:20px;">
                        <span class="material-symbols-outlined">
                            drag_indicator
                        </span>
                    </mat-icon>
                </button>
            </div>
        </div>
    </mat-dialog-title>
    <mat-dialog-content class="mainarea">
        <div class="menurows">
            <div style="margin-top: 2px;"><button mat-button (click)="defaults()">Defaults</button><mat-icon
                    matTooltip="Undo any changes and use the default column settings." matTooltipClass="tooltip-long"
                    [matTooltipPosition]="'after'">information</mat-icon>
            </div>
            <div class="selectallgrid">
                <div>
                    &nbsp;
                    <!-- <mat-checkbox class="checkboxitem" checked="checked" aria-checked="true">Select
                        All</mat-checkbox> -->
                </div>
                <div class="freezegrid">
                    <div style="margin-top: 2px;">Freeze</div>
                    <div><mat-icon matTooltip="Prevents the column from moving for reference as the table is scrolled."
                            matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">information</mat-icon>
                    </div>
                </div>
            </div>
            <div class="scroll">
                <div class="maincontainer">
                    <div class="freezecontainer">
                        <div class="datalist" *ngFor="let column of getFrozenData()">
                            <div class="databox">
                                <div class="datagrid">
                                    <div style="justify-content: center;">
                                        <mat-checkbox class="checkboxitem"
                                            (change)="setVisibleFromCheck($event, column)"
                                            [checked]="getVisibleByColumnId(column.id)"
                                            [disabled]="getVisibleDisabledState(column.id)"><span>{{column.name}}</span></mat-checkbox>
                                    </div>
                                    <div class="slider">
                                        <mat-slider color="accent" class="sticky" max=1 min=0 step=1 discrete=false
                                            showTickMarks=false>
                                            <input matSliderThumb #slider (change)="sliderChange($event, column.id)"
                                                [ngModel]="getFreezeByColumnIdForSliderValue(column.id)"
                                                [disabled]="getFreezeDisabledState(column.id)">
                                        </mat-slider>
                                    </div>
                                    <div style="text-align: center;">&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div (ngModel)="maincolumncontainer" class="columncontainer" cdkDropList
                    [cdkDropListData]="data.datasource" (cdkDropListDropped)="dropColumn($event)">
                    <div class="datalist" cdkDrag cdkDragBoundary=".columncontainer"
                        [cdkDragDisabled]="getDragDisabledState(column.id)"
                        [cdkDragPreviewContainer]="maincolumncontainer" *ngFor="let column of getWarmData()">
                        <div class="datagrid" *ngIf="showItem(column)">
                            <div style="justify-content: center;">
                                <mat-checkbox class="checkboxitem" (change)="setVisibleFromCheck($event, column)"
                                    [checked]="getVisibleByColumnId(column.id)"
                                    [disabled]="getVisibleDisabledState(column.id)">{{column.name}}</mat-checkbox>
                            </div>
                            <div class="slider">
                                <mat-slider color="accent" class="sticky" max=1 min=0 step=1 discrete=false
                                    showTickMarks=false>
                                    <input matSliderThumb #slider (change)="sliderChange($event, column.id)"
                                        [ngModel]="getFreezeByColumnId(column.id)"
                                        [disabled]="getFreezeDisabledState(column.id)">
                                </mat-slider>
                            </div>
                            <div style="text-align: center;">
                                <button mat-icon-button>
                                    <mat-icon style="font-size:20px;">
                                        <span class="material-symbols-outlined">
                                            drag_handle
                                        </span>
                                    </mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin: auto 1em">
                <mat-form-field class="search">
                    <input matInput (keyup)="applyFilterEvent($event)" placeholder="Filter Columns"
                        [(ngModel)]="filter_text" spellcheck="false">
                    <mat-icon matSuffix style="font-size: 1.2em">search</mat-icon>
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="actionarea">
            <div><button mat-raised-button color="accent" [disabled]="ems.userMode" (click)="save()">Save</button></div>
            <div><button mat-raised-button (click)="close()">This Time Only</button></div>
            <div><button mat-raised-button (click)="discardAndReset()">Discard</button></div>
        </div>
    </mat-dialog-actions>
</mat-dialog>