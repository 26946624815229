import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatComponents } from './modules/shared-modules/AngularMaterialComponents';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { AppComponent } from './app.component';
import { EntitlementsRoutingModule } from './modules/shared-modules/app-routing.module';
import { RouteSpaceReplacePipe, SlashAsPerc2FPipe } from './modules/shared-modules/routeSpaceReplacePipe';
import { AuthorizationService } from './modules/services/authorization.service';
import { EntitlementService } from './modules/services/ems.service';
import { CustomerDataService } from './modules/services/customer-data.service';
import { AdminLandingComponent } from './modules/admin-mode/components/admin-landing/admin-landing.component';
import { UserLandingComponent } from './modules/user-mode/components/user-landing/user-landing.component';
import { UserActivationComponent } from './modules/user-mode/components/user-activation/user-activation.component';
import { AdminCustomerHomeComponent } from './modules/admin-mode/components/admin-customer-view/admin-customer-view.component';
import { PrimaryEditUsersComponent } from './modules/user-mode/components/primary-edit-users/primary-edit-users.component';
import { PrimaryLandingComponent } from './modules/user-mode/components/primary-landing/primary-landing.component';
import { PrimaryManageComponent } from './modules/user-mode/components/primary-manage/primary-manage.component';
import { NewUserDialogComponent } from './modules/user-mode/components/primary-edit-users/new-user-dialog/new-user-dialog.component';
import { AdminCustomerEntitlementsComponent } from './modules/admin-mode/components/admin-customer-view/admin-customer-entitlements/admin-customer-entitlements.component';
import { AdminLogViewComponent } from './modules/admin-mode/components/admin-log-view/admin-log-view.component';
import { AdminTimelimitedentitlementsViewComponent } from './modules/admin-mode/components/admin-timelimitedentitlements-view/admin-timelimitedentitlements-view.component';
import { PrimaryEditUsersResetPasswordComponent } from './modules/user-mode/components/primary-edit-users/primary-edit-users-reset-password/primary-edit-users-reset-password.component';
import { AdminCustomerEditOperationsManagerComponent } from './modules/admin-mode/components/admin-customer-view/admin-customer-edit-operations-manager/admin-customer-edit-operations-manager.component';
import { ChangePasswordComponent } from './modules/shared-components/change-password/change-password.component';
import { QrComponent } from './modules/qr/qr.component';
import { QrmodalComponent } from './modules/qr/qrmodal/qrmodal.component';
import { AdminStatusComponent } from './modules/admin-mode/components/admin-status/admin-status.component';
import { MatSortModule } from '@angular/material/sort';
import { CRMService } from './modules/services/crm.service';
import { SplashComponent } from './modules/shared-components/splash/splash.component';
import { SplashService } from './modules/services/splash.service';
import { CellpopupComponent } from './modules/shared-components/cellpopup/cellpopup.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { LicensepopupComponent } from './modules/user-mode/components/user-activation/licensepopup/licensepopup.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AdminmodalComponent } from './modules/admin-mode/components/admin-customer-view/adminmodal/adminmodal.component';
import { QrlookupComponent } from './modules/qr/qrlookup/qrlookup.component';
import { SnackService } from './modules/services/snack.service';
import { StatusComponent } from './modules/shared-components/status/status.component';
import { MaintenanceComponent } from './modules/shared-components/maintenance/maintenance.component';
import { MatTableModule } from '@angular/material/table';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { PageNotFoundComponent } from './modules/shared-components/page-not-found/page-not-found';
import { LoginComponent } from './modules/shared-components/login/login';
import { EntitlementLoginComponent } from './modules/shared-components/entitlement-login/entitlement-login';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SalesAdministrationComponent } from './modules/admin-mode/components/sales-administration/sales-administration.component';
import { SessionExpiredComponent } from './modules/shared-components/session-expired/session-expired.component';
import { LogOffComponent } from './modules/shared-components/log-off/log-off.component';
import { VolumepopupComponent } from './modules/user-mode/components/user-activation/volumepopup/volumepopup.component';
import { UsbcidSearchComponent } from './modules/shared-components/usbcid-search/usbcid-search.component';
import { EditEntitlementComponent } from './modules/admin-mode/components/admin-customer-view/edit-entitlement/edit-entitlement.component';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { ViewEntitlementComponent } from './modules/admin-mode/components/admin-customer-view/view-entitlement/view-entitlement.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectColumnsComponent } from './modules/shared-components/select-columns/select-columns.component';
import { MatSliderModule } from '@angular/material/slider'
import { DragDropModule, CdkDropList, CdkDrag, CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { DeleteEntitlementComponent } from './modules/admin-mode/components/admin-customer-view/delete-entitlement/delete-entitlement.component';


// for drag/drop functionality
const DragConfig = {
    dragStartThreshold: 0,
    pointerDirectionChangeThreshold: 5,
    zIndex: 999999,
    parentDragRef: SelectColumnsComponent
};

@NgModule({
    declarations: [
        AppComponent,
        UserLandingComponent,
        UserActivationComponent,
        RouteSpaceReplacePipe,
        SlashAsPerc2FPipe,
        PrimaryEditUsersComponent,
        PrimaryLandingComponent,
        PrimaryManageComponent,
        NewUserDialogComponent,
        AdminLandingComponent,
        AdminCustomerHomeComponent,
        AdminCustomerEntitlementsComponent,
        AdminLogViewComponent,
        AdminTimelimitedentitlementsViewComponent,
        AdminCustomerEditOperationsManagerComponent,
        PrimaryEditUsersResetPasswordComponent,
        ChangePasswordComponent,
        QrComponent,
        QrmodalComponent,
        AdminStatusComponent,
        SplashComponent,
        CellpopupComponent,
        LicensepopupComponent,
        AdminmodalComponent,
        QrlookupComponent,
        StatusComponent,
        MaintenanceComponent,
        PageNotFoundComponent,
        LoginComponent,
        EntitlementLoginComponent,
        SalesAdministrationComponent,
        SessionExpiredComponent,
        LogOffComponent,
        VolumepopupComponent,
        UsbcidSearchComponent,
        EditEntitlementComponent,
        ViewEntitlementComponent,
        SelectColumnsComponent,
        DeleteEntitlementComponent
    ],
    imports: [
        EntitlementsRoutingModule,
        BrowserModule,
        HttpClientModule,
        MatComponents,
        MatNativeDateModule,
        LayoutModule,
        FormsModule,
        ReactiveFormsModule,
        BreadcrumbModule,
        MatTableModule,
        MatSortModule,
        MatExpansionModule,
        MatProgressBarModule,
        BrowserAnimationsModule,
        NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),
        CommonModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSliderModule,
        CdkDropList,
        CdkDrag,
        DragDropModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], // needed for /status endpoint & spinner
    providers: [
        AuthorizationService,
        EntitlementService,
        CustomerDataService,
        DatePipe,
        MatDatepickerModule,
        CRMService,
        SplashService,
        SnackService,
        { provide: CDK_DRAG_CONFIG, useValue: DragConfig },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500, panelClass: "snackbar" } },
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'accent' },
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {
                appearance: 'outline',
                hideRequiredMarker: true,
                subscriptSizing: 'dynamic',
                color: 'accent'
            }
        },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: 'mat-field-inside-dialog-workaround' } }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
