<div class="mainbox">
  <div class="leftsidebar">
    &nbsp;
  </div>
  <div class="submainbox">
    <div>
      <div class="logingridrows">
        <div>
          <h1>Welcome to the TenAsys License Portal!</h1>
        </div>
        <div>
          <h3>Enter your username and password <span style="text-decoration: underline;">OR</span> TenAsys entitlement
            and then click proceed.</h3>
        </div>
      </div>
      <div class="loginpanel">
        <div class="logingrid">
          <div class="vcenter">
            <div>
              <form>
                <div class="loginfields">
                  <div>
                    <mat-form-field appearance="outline" class="nomWidth">
                      <mat-label>Enter Username</mat-label>
                      <input matInput autocomplete="on" type="text" placeholder="Username (Email Address)"
                        [(ngModel)]="this.username" name="username" id="username" data-testid="username-input">
                      <mat-icon matSuffix>email</mat-icon>
                      <mat-hint>Your username is your company email address</mat-hint>
                    </mat-form-field>
                  </div>
                  <div>
                    <mat-form-field appearance="outline" class="nomWidth">
                      <mat-label>Enter Password</mat-label>
                      <input matInput autocomplete="on" placeholder="Password" type="password"
                        [(ngModel)]="this.password" data-testid="password-input" name="password" (keydown)="this.onPasswordChange($event.key)"
                        id="password" spellcheck="false">
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="borderstrip"></div>
          <div class="vcenter">
            <div>
              Or use entitlement to activate new TenAsys license
              <form novalidate #f="ngForm">
                <mat-form-field appearance="outline" class="nomWidth">
                  <mat-label>Enter Entitlement or SUAC</mat-label>
                  <input matInput type="text" [(ngModel)]="this.entitlementid" data-testid="entitlementid" name="entitlementid" spellcheck="false">
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
        <div style="text-align: center;">
          <button mat-raised-button value="Login" data-testid="login-btn" [disabled]="this.disallowLogin" color="accent" (click)="tryLogin()">Proceed <mat-icon
              iconPositionEnd>arrow_right_alt</mat-icon></button>
        </div>
      </div>
    </div>
    <div>
      <app-status></app-status>
    </div>
  </div>
  <div class="rightsidebar">
    &nbsp;
  </div>
</div>