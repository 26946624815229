<div class="modal">
	<div>
		<h2 mat-dialog-title><span>{{data.datacolumn}}</span></h2>
	</div>
	<mat-dialog-content>
		<div class="celldata">{{data.datavalue}}</div>
		<div class="buttongrid">
			<mat-dialog-actions align="end">
				<button mat-raised-button *ngIf="this.data.datavalue" (click)="copy(this.data.datavalue)"
					value="Copy">Copy License</button>
				<a mat-raised-button *ngIf="this.data.licenseurl" color="accent" href="{{this.data.licenseurl}}"
					download="license.lic" style="float: right;">License File<mat-icon>arrow_downward</mat-icon></a>
				<button mat-raised-button mat-dialog-close>Close</button>
			</mat-dialog-actions>
		</div>
	</mat-dialog-content>
</div>