export class TimeLimitedEntitlementData {
	license: string = "";
	fingerprint: string = "";
	activateddate: Date = new Date(1980, 1, 1);
	enddate: Date = new Date(1980, 1, 1);
	daystolive: number = 0;
	internalcomment: string = "";
	accountname: string = "";
	productname: string = "";
	ipaddress: string = "";
	private _iseval: number = 0;

	constructor(e: any) {
		this.license = e.license;
		this.fingerprint = e.fingerprint;
		this.activateddate = e.activateddate;
		this.enddate = e.enddate;
		this.daystolive = e.daystolive;
		this.internalcomment = e.internalcomment;
		this.accountname = e.accountname;
		this.productname = e.productname;
		this.ipaddress = e.ipaddress;
		this._iseval = e.isEval;
	}

	get timelimitedtype() { return this._iseval == 1 ? "Evaluation" : "Trial"}
}