import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import { AuthorizationService } from '../../../services/authorization.service';
import { CustomerDataService } from '../../../services/customer-data.service';
import { EntitlementService } from '../../../services/ems.service';
import { AccountData } from '../../../types/AccountData';
import { OrderData, OrderProduct } from '../../../types/OrderData';
import { ActivationProduct, Product } from '../../../types/Product';
import {
  Invoice, /*, ProductLineItem */
  ProductLineItem
} from '../../../types/InvoiceData';
import { EntitlementData } from '../../../types/EntitlementData';
import { EntitlementSummaryRow } from '../../../types/EntitlementSummaryRow';
import { CRMService } from 'src/app/modules/services/crm.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatAccordion } from '@angular/material/expansion';
import { AdminmodalComponent } from './adminmodal/adminmodal.component';
import { SnackService } from 'src/app/modules/services/snack.service';

// controls delay of the tooltip
export const adminCustListTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 0,
  touchendHideDelay: 1000,
};
@Component({
  selector: 'admin-customer-view',
  templateUrl: './admin-customer-view.component.html',
  styleUrls: [
    '../../../shared-styles/divs.scss',
    '../../../shared-styles/tables.scss',
    '../../../shared-styles/elements.scss',
    './admin-customer-view.component.scss'
  ],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: adminCustListTooltipDefaults }
  ],
})
export class AdminCustomerHomeComponent implements OnInit {
  NOTSET: string = "None";
  isIEEdge: boolean = false;
  entitlements: Array<EntitlementData> = new Array<EntitlementData>();
  orders: Array<OrderData> = new Array<OrderData>();
  dataSource: MatTableDataSource<EntitlementSummaryRow> = new MatTableDataSource<EntitlementSummaryRow>();
  displayedColumns: string[] = ['product', 'totalcount', 'availableCount'];
  selectedOrderProducts: Array<OrderProduct> = new Array<OrderProduct>();
  accountDetail: AccountData = new AccountData();
  accountname: string;
  crmendpoint: string = "";
  accountid: string;
  type: string = ""; // key, network, standard, ""
  typeFriendly: string = "";
  showResultsButton: boolean = false; // shows the modal with add entitlement results and the import file
  counts: Array<EntitlementSummaryRow> = new Array<EntitlementSummaryRow>();
  products: Array<Product> = new Array<Product>();
  filteredProducts: Array<Product> = new Array<Product>();
  invoices: Array<Invoice> = new Array<Invoice>();
  minDate: Date = new Date('1/1/2018');
  maxDate: Date;
  selectedOrder: OrderData = new OrderData();
  selectedOrderUrl: string = ""; // this opens link to order
  selectedInvoiceUrl: string = ""; // this opens link to invoice
  selectedProduct: Product = new Product();
  selectedInvoice: Invoice = new Invoice();
  fullQuantity: boolean = false; // set to true if quanity is not reduced
  numberToAdd: number = 0;
  maxNumberToAdd: number = 0;
  LOADDURATION: number = 8;
  comment: string = "";
  setfulfill: boolean = false;
  exportBatchID: number = 0;
  exportURL: string = "";
  errorTimeout: number = 30 * 1000;
  entitlementsURL: string = "";
  entitlementsExportName: string = "";
  isAddEntitlementsBtnDisabled: boolean = true;
  activeonly: boolean = false;
  addEntitlementsFormAdmin: FormGroup = this._formBuilder.group({
    orderControl: new FormControl('', Validators.required),
    invoiceControl: new FormControl('', Validators.required),
    productControl: new FormControl('', Validators.required),
    addNumberControl: new FormControl(0),
    addNumber2Control: new FormControl(0),
    commentControl: new FormControl(''),
    setfulfillControl: new FormControl(false)
  });

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(private ems: EntitlementService,
    public auth: AuthorizationService,
    public crm: CRMService,
    private router: Router,
    private route: ActivatedRoute,
    public snackMsg: SnackService,
    public dialog: MatDialog,
    private customerData: CustomerDataService,
    private spinner: NgxSpinnerService,
    private _formBuilder: FormBuilder
  ) {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (isIEOrEdge) this.isIEEdge = true;
    this.spinner.show("spinneronly");
    this.crmendpoint = crm.getBaseUrl();
    this.accountid = this.customerData.accountData.accountid;
    this.accountname = this.route.snapshot.paramMap.get('customer')?.replace("%2F", "/") ?? "";
    this.customerData.adminCustomerAccount = this.accountname; // displayed in upper-right up screen for admins
    this.customerData.adminCustomerAccountGuid = this.accountid; // displayed in upper-right up screen for admins
    this.customerData.adminCrmEndpoint = this.crmendpoint; // displayed in upper-right up screen for admins
    let getProducts = this.ems.getProducts();
    let getAccount = this.ems.getAccountSummaryAdmin(this.accountid);
    let getOrders = this.ems.getOrders(this.accountid);
    forkJoin([getProducts, getAccount, getOrders]).subscribe(([getProductsResult, getAccountResult, getOrdersResult]) => {
      this.products = getProductsResult;
      this.counts = getAccountResult.entitlementsSummary;
      this.accountDetail = getAccountResult.account;
      this.orders = getOrdersResult;
      this.customerData.totalEntitlements = getAccountResult.totalEntitlements
      this.dataSource = new MatTableDataSource(this.counts);
      this.spinner.hide("spinneronly");
    }, () => {
      this.snackMsg.openSnackBar("An Error Has Occurred.", 5000);
      this.spinner.hide("spinneronly");
    });
  }

  ngOnInit(): void {
  }

  getFullQuantity() {
    let numberToAdd = this.addEntitlementsFormAdmin.controls["addNumberControl"].value;
    return (numberToAdd == this.maxNumberToAdd) ? true : false;
  }

  // key, network, standard, ""
  getType() {
    return this.type.toLowerCase();
  }

  refreshEntitlements() {
    this.spinner.show("productspinner");
    let getCustomers = this.ems.getAccountSummaryAdmin(this.accountid);
    getCustomers.subscribe((getAccountResult) => {
      this.counts = getAccountResult.entitlementsSummary;
      this.dataSource = new MatTableDataSource(this.counts);
      this.spinner.hide("productspinner");
    }, () => {
      this.snackMsg.openSnackBar("An error has occurred refreshing product totals summary.", this.errorTimeout);
      this.spinner.hide("productspinner");
    });
  }

  setSingleInvoice() {
    this.invoices = [];
    this.ems.getInvoices(this.selectedOrder.orderid).subscribe((data) => {
      for (let i in data) {
        let anInvoiceLine = <Invoice>data[i];
        if (this.invoices.find(function (e) { return e.invoiceid == anInvoiceLine.invoiceid }) === undefined) {
          this.invoices.push({
            name: anInvoiceLine.name,
            salesorderid: anInvoiceLine.salesorderid,
            invoicenumber: anInvoiceLine.invoicenumber,
            invoiceid: anInvoiceLine.invoiceid,
            new_teginvoice: anInvoiceLine.new_teginvoice,
            state: anInvoiceLine.state,
            products: anInvoiceLine.products,
          });
        }
        else {
          for (let lineItem of anInvoiceLine.products) {
            this.invoices[this.invoices.findIndex(function (e) { return e.invoiceid == anInvoiceLine.invoiceid })].products.push(lineItem);
          }
        }
      }

      // set invoice if only a single length
      if (this.invoices.length == 0) {
        this.addEntitlementsFormAdmin.controls['invoiceControl'].setErrors({ 'No invoices found': true });
        this.addEntitlementsFormAdmin.controls['invoiceControl'].markAsTouched();
      }
      else if (this.invoices.length == 1) {
        this.setInvoiceByInvoice(<Invoice>this.invoices[0]);
      } else {
        this.spinner.hide("invoicespinner");
        this.addEntitlementsFormAdmin.controls['invoiceControl'].setErrors({ "Manual invoice selection required": true });
        this.addEntitlementsFormAdmin.controls['invoiceControl'].markAsTouched();
      }
      this.resetProduct();
      this.resetCommentAndEnd();
      this.spinner.hide("invoicespinner");
    }, () => {
      this.snackMsg.openSnackBar("An Error Has Occurred.", this.errorTimeout);
      this.spinner.hide("invoicespinner");
      this.spinner.hide("spinneronly");
    });
  }

  setSingleProduct() {
    // set invoice if only a single length
    if (this.filteredProducts.length == 1) {
      this.resetQuantity();
      this.selectedProduct = <Product>this.filteredProducts[0];
      this.setProductByProductValue(this.selectedProduct);
      if (this.getFullQuantity) {
        this.addEntitlementsFormAdmin.controls["setfulfillControl"].setValue(true);
      }
    }
  }

  toTitleCase(str: string) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1, txt.length).toLowerCase();
      }
    );
  }

  getPrimaryContact() {
    let contact = this.toTitleCase(this.accountDetail.primaryTechnicalFirstName) + " " +
      this.toTitleCase(this.accountDetail.primaryTechnicalLastName);
    if (contact.trim() == "") {
      contact = this.NOTSET;
    }
    return contact;
  }

  getSecondaryContact() {
    let contact = this.toTitleCase(this.accountDetail.secondaryTechnicalFirstName) + " " +
      this.toTitleCase(this.accountDetail.secondaryTechnicalLastName);
    if (contact.trim() == "") {
      contact = this.NOTSET;
    }
    return contact;
  }

  getOpsManager() {
    let contact = this.toTitleCase(this.accountDetail.operationsManagerFirstName) + " " +
      this.toTitleCase(this.accountDetail.operationsManagerLastName);
    if (contact.trim() == "") {
      contact = this.NOTSET;
    }
    return contact;
  }

  calculateTotal(columnname: string) {
    let total: number = 0;
    if (columnname.toLowerCase() == "total") {
      this.counts.forEach(function (value) {
        total += value.total;
      });
    } else if (columnname.toLowerCase() == "available") {
      this.counts.forEach(function (value) {
        total += value.available;
      });
    }
    return total;
  }

  setInvoiceByInvoice(inv: Invoice) {
    this.selectedInvoice = inv;

    // get all the products attached to invoice
    let productMaps = this.selectedInvoice.products.map(p => (p.productName.toUpperCase()));

    // filter the filterProducts to only include these    
    this.filteredProducts = this.products.filter(p => productMaps.indexOf(p.ProductName.toUpperCase()) > -1);

    // set it
    this.addEntitlementsFormAdmin.controls["invoiceControl"].setValue(inv);
    this.selectedInvoiceUrl = this.crmendpoint + "/main.aspx?pagetype=entityrecord&etn=invoice&id=" + inv.invoiceid;

    // reset product
    this.resetProduct();
  }

  setInvoice(invEvent: MatSelectChange) {
    this.setInvoiceByInvoice(invEvent.value);
  }

  getShowCrm() {
    return this.showResultsButton;
  }

  setShowCrm() {
    if (this.entitlementsURL == "" && this.exportURL == "") {
      this.showResultsButton = false;
    } else {
      this.showResultsButton = true;
    }
  }

  setType() {
    if (this.selectedProduct != undefined && this.selectedProduct != null) {
      let prodType = this.selectedProduct.ProductType.toLowerCase();
      switch (prodType) {
        case "license server":
          this.typeFriendly = "SDK Network";
          this.type = "network";
          break;
        case "network":
          this.typeFriendly = "SDK Network";
          this.type = "network";
          break;
        case "key":
          this.typeFriendly = "USB/CID Hardware Key";
          this.type = "key";
          break;
        case "hwkey":
          this.typeFriendly = "USB/CID Hardware Key";
          this.type = "key";
          break;
        case "usb":
          this.typeFriendly = "USB/CID Hardware Key";
          this.type = "key";
          break;
        case "usb/cid":
          this.typeFriendly = "USB/CID Hardware Key";
          this.type = "key";
          break;
        case "stickers":
          this.typeFriendly = "Standard (Entitlements Only)";
          this.type = "standard";
          break;
        case "piece":
          this.typeFriendly = "Standard (Entitlements Only)";
          this.type = "standard";
          break;
        default:
          this.typeFriendly = "Unknown Product";
          this.type = "";
      }

      // append actual value
      this.typeFriendly += " [" + this.selectedProduct.ProductType + "]";
    } else {
      this.typeFriendly = "";
      this.type = "";
    }
  }

  setProductByProductValue(prod: Product) {
    this.selectedProduct = prod;
    this.resetQuantity();

    // set quality   and ProductType  
    this.maxNumberToAdd = 0;
    let invoiceProducts = this.selectedInvoice.products.filter(p => p?.productName.toLowerCase() == this.selectedProduct.ProductName.toLowerCase());
    if (invoiceProducts.length > 0) {

      // this is already split by product type (just based on name)
      for (let lineitem of invoiceProducts) {
        this.maxNumberToAdd += lineitem.quantity;
        this.selectedProduct.ProductType = lineitem.productType;
      }
    } else {
      this.addEntitlementsFormAdmin.controls['productControl'].setErrors({ "No valid products found.": true });
      this.addEntitlementsFormAdmin.controls['productControl'].markAsTouched();
      this.maxNumberToAdd = 0;
      this.selectedProduct.ProductType = "";
    }

    // set hint under product to indicate what type or product we're activating
    this.setType();

    if (this.getType() == "network") {
      this.numberToAdd = 6;
      this.maxNumberToAdd = 500;
      this.addEntitlementsFormAdmin.controls["addNumber2Control"].setValue(this.numberToAdd);
    } else {
      this.numberToAdd = this.maxNumberToAdd;
    }
    this.addEntitlementsFormAdmin.controls["addNumberControl"].setValue(this.numberToAdd);

    // set it
    this.addEntitlementsFormAdmin.controls["productControl"].setValue(prod);

    // check if button should be enabled
    this.checkIsValid();
  }

  setProduct(prodEvent: MatSelectChange) {
    this.setProductByProductValue(prodEvent.value);
  }

  setAddEntitlementsAmount() {
    this.fullQuantity = this.getFullQuantity();
    if (this.fullQuantity) {
      this.addEntitlementsFormAdmin.controls["setfulfillControl"].setValue(true);
    } else {
      this.addEntitlementsFormAdmin.controls["setfulfillControl"].setValue(false);
    }

    // check if button should be enabled
    this.checkIsValid();
  }

  showEntitlements() {
    this.router.navigate(['/' + this.auth.getUsername() + '/admin/' + this.accountDetail.name.replace('/', '%2F') + '/entitlements']);
  }

  showOperationsManager() {
    this.router.navigate(['/' + this.auth.getUsername() + '/admin/' + this.accountDetail.name.replace('/', '%2F') + '/operationsmanager']);
  }

  showCrmUpdate(w, h) {
    const dialogRef = this.dialog.open(AdminmodalComponent, {
      panelClass: 'cardnopadding-dialog',
      data: {
        exportURL: this.exportURL,
        entitlementsURL: this.entitlementsURL,
        entitlementsExportName: this.entitlementsExportName
      },
      height: h,
      width: w
    });
    dialogRef.afterClosed().subscribe(result => { });
  }

  addUSBEntitlements(addEntitlements: any) {
    this.entitlementsURL = "";
    this.exportURL = "";

    addEntitlements.subscribe((data: any) => {
      if (data) {
        if ('ErrorCode' in data) {
          this.spinner.hide("spinneronly");
        } else {
          this.exportBatchID = data.currentcreatebatchid
          this.exportURL = this.ems.getExportUSBURL(this.accountid, this.exportBatchID);
          this.refreshEntitlements(); // refresh totals

          // open modal
          this.setShowCrm();
          this.showCrmUpdate('60vw', '40vh');
        }
      }
      else this.snackMsg.openSnackBar("An error occurred:  No data was returned from the add USB entitlements call", this.errorTimeout);
      this.spinner.hide("spinneronly");
    },
      () => {
        this.snackMsg.openSnackBar("An error occurred.", this.errorTimeout);
        this.spinner.hide("spinneronly");
      });
  }

  addEntitlements(addEntitlements: any) {
    this.entitlementsURL = "";
    this.exportURL = "";

    addEntitlements.subscribe((data: any) => {
      if (data) {
        if ('ErrorCode' in data) {
          this.spinner.hide("spinneronly");
        } else {
          let countReturned = data.totalCreated;
          if (countReturned > 0) {
            let isLicensed = data.newEntitlements[0].Entitlement != undefined;
            if (isLicensed) data.newEntitlements[0] = data.newEntitlements[0].Entitlement;

            this.entitlementsExportName = this.accountDetail.name + "_" + this.addEntitlementsFormAdmin.get("invoice")?.value + ".csv";
            this.entitlementsURL = this.ems.getAddEntitlementsExport(data.currentcreatebatchid);
          }
          this.refreshEntitlements(); // refresh totals

          // open modal
          this.setShowCrm();
          this.showCrmUpdate('40vw', '40vh');

          this.spinner.hide("spinneronly");
        }
      }
      else {
        this.snackMsg.openSnackBar("An error occurred.  No data was returned.", this.errorTimeout);
        this.spinner.hide("spinneronly");
      }
    },
      () => {
        this.snackMsg.openSnackBar("An error occurred.", this.errorTimeout);
        this.spinner.hide("spinneronly");
      });
  }

  getAddEntitlementsFormAdminControls(name: string) {
    return this.addEntitlementsFormAdmin.get(name);
  }

  // enables add entitlement button
  checkIsValid(): boolean {
    let order = this.addEntitlementsFormAdmin.controls['orderControl'].value;
    let product = this.addEntitlementsFormAdmin.controls['productControl'].value;
    let addNumber = this.addEntitlementsFormAdmin.controls['addNumberControl'].value;
    let addNumber2 = this.addEntitlementsFormAdmin.controls['addNumber2Control'].value;
    let invoice = this.addEntitlementsFormAdmin.controls['invoiceControl'].value;
    let valid: boolean = true;

    // since we don't control orders, let's not prevent interaction due to their state, only invoices
    // if (valid && this.selectedOrder.state == '3') {
    //   valid = false;
    //   this.addEntitlementsFormAdmin.controls['orderControl'].setErrors({ 'alreadyfulfilled': true });
    // }

    if (order == undefined || order == null || invoice == undefined || invoice == null || product == undefined || product == null) {
      valid = false;
    }

    if (valid && this.selectedInvoice.state == '8') {
      valid = false;
      this.addEntitlementsFormAdmin.controls['invoiceControl'].setErrors({ 'alreadyfulfilled': true });
      this.addEntitlementsFormAdmin.controls['invoiceControl'].markAsTouched();
    }

    // if we have a product type we may have enough to proceed
    if (this.getType() == "") {
      valid = false;
    }

    if (valid) {
      // validate num add
      if (addNumber == null || addNumber == undefined) {
        valid = false;
        this.addEntitlementsFormAdmin.controls['addNumberControl'].setErrors({ 'required': true });
      } else if (addNumber > this.maxNumberToAdd) {
        valid = false;
        this.addEntitlementsFormAdmin.controls['addNumberControl'].setErrors({ 'quantity exceeded': true });
      }

      if (valid) // if still valid, quantity is good
      {
        this.addEntitlementsFormAdmin.controls['addNumberControl'].setErrors(null);

        if (this.getType() == "network") {
          if (addNumber2 == null || addNumber2 == undefined) {
            valid = false;
            this.addEntitlementsFormAdmin.controls['addNumber2Control'].setErrors({ 'required': true });
          }

          if (addNumber2 > this.maxNumberToAdd) {
            valid = false;
            this.addEntitlementsFormAdmin.controls['addNumber2Control'].setErrors({ 'quantity exceeded': true });
          }
          this.addEntitlementsFormAdmin.controls['addNumber2Control'].markAsTouched();
        }

        if (valid) // if still valid, quantity (SEAT2) is good
        {
          this.addEntitlementsFormAdmin.controls['addNumber2Control'].setErrors(null);

          //double-check for form errors
          if (!this.addEntitlementsFormAdmin.controls['orderControl'].valid ||
            !this.addEntitlementsFormAdmin.controls['invoiceControl'].valid ||
            !this.addEntitlementsFormAdmin.controls['productControl'].valid ||
            !this.addEntitlementsFormAdmin.controls['addNumberControl'].valid
          ) {
            valid = false;
          }
        }
      }
      this.addEntitlementsFormAdmin.controls['addNumberControl'].markAsTouched();
    }

    if (valid) {
      // enable button
      this.isAddEntitlementsBtnDisabled = false;
    } else {
      this.isAddEntitlementsBtnDisabled = true;
      this.setShowCrm();
    }
    return valid;
  }

  setOrder(orderSelected: MatSelectChange) {
    let ord = orderSelected.value;
    this.selectedOrder = ord;
    this.selectedOrderProducts = ord.products;
    this.selectedOrderUrl = this.crmendpoint + "/main.aspx?pagetype=entityrecord&etn=salesorder&id=" + this.selectedOrder.orderid;
    // if (this.selectedOrder.state == '3') {
    //   this.addEntitlementsFormAdmin.controls['orderControl'].setErrors({ 'alreadyfulfilled': true });
    //   this.addEntitlementsFormAdmin.controls['orderControl'].markAsTouched();
    // }
    this.resetInvoice();
  }

  private resetInvoice(): void {
    this.spinner.show("invoicespinner");
    this.selectedInvoice = new Invoice();
    this.addEntitlementsFormAdmin.controls['invoiceControl'].setValue(null);
    this.addEntitlementsFormAdmin.controls['invoiceControl'].setErrors(null);
    this.addEntitlementsFormAdmin.controls['invoiceControl'].markAsTouched();
    this.setSingleInvoice(); // this reset product when data returned from API
  }

  private resetProduct(): void {
    // check box to fulfill
    this.addEntitlementsFormAdmin.controls["setfulfillControl"].setValue(false);

    // reset product
    this.selectedProduct = new Product();
    this.addEntitlementsFormAdmin.controls['productControl'].setValue(null);
    this.addEntitlementsFormAdmin.controls['productControl'].setErrors(null);
    this.addEntitlementsFormAdmin.controls['productControl'].markAsTouched();
    this.type = "";
    this.setSingleProduct();
  }

  private resetQuantity(): void {
    this.addEntitlementsFormAdmin.controls['addNumberControl'].setValue(0);
    this.addEntitlementsFormAdmin.controls['addNumberControl'].setErrors(null);
    this.addEntitlementsFormAdmin.controls['addNumberControl'].markAsTouched();
    this.addEntitlementsFormAdmin.controls['addNumber2Control'].setValue(0);
    this.addEntitlementsFormAdmin.controls['addNumber2Control'].setErrors(null);
    this.addEntitlementsFormAdmin.controls['addNumber2Control'].markAsTouched();
  }

  private resetCommentAndEnd(): void {
    this.addEntitlementsFormAdmin.controls['commentControl'].setValue(null);
    this.addEntitlementsFormAdmin.controls['commentControl'].setErrors(null);
    this.addEntitlementsFormAdmin.controls['commentControl'].markAsTouched();
  }

  addEntitlementsClick(event: any) {
    this.spinner.show("spinneronly");

    // disable "add entitlements" button
    this.isAddEntitlementsBtnDisabled = true;

    let order = this.addEntitlementsFormAdmin.controls['orderControl'].value;
    let product = this.addEntitlementsFormAdmin.controls['productControl'].value;
    let newCount = this.addEntitlementsFormAdmin.controls['addNumberControl'].value;
    let newCount2 = this.addEntitlementsFormAdmin.controls['addNumber2Control'].value;
    let invoice = this.selectedInvoice.invoiceid;
    let comment = this.addEntitlementsFormAdmin.controls['commentControl'].value;
    let fulfillinvoice: boolean = this.addEntitlementsFormAdmin.get("setfulfillControl")?.value;
    let isNetwork = this.type == "network";
    let addProduct = new ActivationProduct();
    addProduct.product = product.EmsMap;
    addProduct.productVersion = product.ProductVersion;
    addProduct.productType = this.getType();
    addProduct.numberToAdd = isNetwork ? 1 : newCount;
    addProduct.crmAccountid = this.accountDetail.accountid;
    addProduct.crmOrderid = order.orderid;
    addProduct.crmInvoiceid = invoice;
    addProduct.comment = comment;
    addProduct.endDate = null;
    addProduct.days = null;
    addProduct.seats1 = isNetwork ? newCount : 0;
    addProduct.seats2 = isNetwork ? newCount2 : 0;
    addProduct.setfulfillstatus = fulfillinvoice;
    addProduct.productmapid = product.Id;
    addProduct.username = this.auth.getUsername();
    addProduct.authToken = this.auth.getSecurityToken();
    if (addProduct.productType == "key" || addProduct.productType == "network") {
      let addUSBEntitlements = this.ems.addUSBEntitlements(addProduct);
      this.addUSBEntitlements(addUSBEntitlements);
    }
    else {
      let addEntitlements = this.ems.addEntitlements(addProduct);
      this.addEntitlements(addEntitlements);
    };
  }
}
