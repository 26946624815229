//These are shared Material.Angular.io components

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule }  from '@angular/material/form-field';
import { MatTreeModule } from '@angular/material/tree';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';

export const MatComponents = [ 
    MatButtonModule, MatInputModule, MatIconModule, 
    MatMenuModule, MatToolbarModule, MatButtonToggleModule, 
    MatGridListModule, MatSnackBarModule, MatProgressSpinnerModule,
    MatDialogModule, MatTableModule, MatListModule, MatTabsModule,
    MatTreeModule, MatExpansionModule,
    MatCardModule, MatDividerModule, MatPaginatorModule, 
    MatSortModule, MatCheckboxModule, MatAutocompleteModule,
    MatSelectModule, MatDatepickerModule, MatNativeDateModule,
    MatTooltipModule, MatFormFieldModule
];