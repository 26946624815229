export class ActivationObject {
    securityToken: string = "";
    username: string = "";
    userType: number = 0;
    requestValue: string = ""; //<entitlement [guid]> or <product type [rt, mcrt, drtos-rt, drtos-mcrt, eval, etc...]> 
    requestType: string = ""; //entitlement, suac, login
    source: string = ""; //third party web service call, portal, licman, admin portal
    comment: string = "";
    fingerprint: string = "";
    force: boolean = false;
}