import { Component, HostListener } from '@angular/core';
import { AuthorizationService } from '../../services/authorization.service';
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from '@angular/material/dialog';
import { EntitlementService } from '../../services/ems.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackService } from '../../services/snack.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: [
    '../../shared-styles/divs.scss',
    '../../shared-styles/tables.scss',
    '../../shared-styles/elements.scss',
    './change-password.component.scss'
  ]
})
export class ChangePasswordComponent {
  public oldPassword: string = "";
  public newPassword: string = "";
  public confirmPassword: string = ""; 
  public loggedIn: boolean = false;
  public showPass: boolean = false;
  public isReset: boolean = false;
  LOADDURATION: number = 8;

  constructor(private auth: AuthorizationService,
    private router: Router, 
    private ems: EntitlementService,
    private spinner: NgxSpinnerService,
    public snackMsg: SnackService, 
    public dialog: MatDialog, 
    private location: Location,
    private routeParams: ActivatedRoute) { 
      this.spinner.show("spinneronly"); 
      this.auth.isTokenValid().subscribe((data)=>{
        if (data == true) this.loggedIn = true;
        this.spinner.hide("spinneronly"); 
      }, (err) => {this.spinner.hide()});
    }


  login(){
    this.router.navigateByUrl('login');
  }

  cancel(){
    if (this.isReset) {
      this.login()
    } else {
      this.location.back();
    }
  }

  updatePassword(){
    if (this.isPasswordValid())
    {
      this.spinner.show("spinneronly"); 
      if (this.oldPassword == this.confirmPassword) {
          this.snackMsg.openSnackBar("Password updated.", 5000); 
          this.spinner.hide("spinneronly"); 
          this.isReset = true;
          return;
      }
      else {
        this.ems.updatePassword(this.oldPassword, this.newPassword).subscribe((data:boolean) =>{
          this.oldPassword = '';
          if (data) { 
            this.snackMsg.openSnackBar("Password updated.", 5000); 
            this.isReset = true;
            this.spinner.hide("spinneronly"); 
          }
          else {
            this.snackMsg.openSnackBar("Password update failed.", 5000); 
            this.spinner.hide("spinneronly"); 
          }
        }, () =>{
          this.snackMsg.openSnackBar("An Error has Occurred.", 5000); 
          this.spinner.hide("spinneronly"); 
        });
      }
    }
    else {
      if (this.newPassword.length < 8 ||
        this.newPassword.length > 30 ||
        !(this.newPassword.match('[a-z]')) ||
        !(this.newPassword.match('[A-Z]')) ||
        !(this.newPassword.match('[0-9\+!@$%^&*()_-]')) ||
        (this.newPassword.match('[^a-zA-Z0-9\+!@$%^&*()_-]') != null)) {
          this.snackMsg.openSnackBar("New password must be 8 to 30 characters, and include: 1+ upper case (A-Z), 1+ lower case (a-z), 1+ number (0-9) OR special character (!@#$%^&*()_-+=,.)", 5000);
      }
      if (this.newPassword != this.confirmPassword) this.snackMsg.openSnackBar("New and Confirm Passwords do not match.", 5000); 
    }
  }

  isPasswordValid(): boolean {
    return this.newPassword == this.confirmPassword && this.newPassword.length > 7 && this.oldPassword != this.newPassword && this.oldPassword.length > 7;
  }

    
  closeButtonLegend(){
    return this.isReset ? "Go to Login" : "Cancel";
  }

  ngOnInit(): void {
  }

}
