import { Component } from '@angular/core';
import { EntitlementService } from '../../services/ems.service';
import { forkJoin } from 'rxjs';
import { SnackService } from '../../services/snack.service';
import { UsbCidSearchService } from '../../services/usbcidsearch.service';

@Component({
  selector: 'app-usbcid-search',
  templateUrl: './usbcid-search.component.html',
  styleUrls: ['./usbcid-search.component.scss']
})
export class UsbcidSearchComponent {
  searchingUSB: boolean = false;
  usbserial: string = "";
  exportString: string = "";
  keysPressed: number = 0;
  hasUsb: boolean = false;
  accountid: string = "";
  constructor(
    private ems: EntitlementService,
    private snackMsg: SnackService,
    private usbsearchservice: UsbCidSearchService
  ) { }

  ngOnInit(): void {
    this.usbsearchservice.hasUsb.subscribe({
      next: (v) => this.hasUsb = v
    });

    this.usbsearchservice.accountid.subscribe({
      next: (v) => this.accountid = v
    });
  }

  public validUSB(): boolean {
    if (this.searchingUSB) return true;
    let usb = this.usbserial != undefined ? this.usbserial : '';
    if (usb.length > 3 && this.exportString == "" && this.keysPressed != usb.length) {
      this.searchingUSB = true;
      this.keysPressed = usb.length;
      this.performUSBSearch();
    }
    if (usb.length < 4) {
      this.exportString = "";
      this.keysPressed = usb.length;
    }
    return true;
  }

  private performUSBSearch() {
    this.exportString = "";
    let usb = this.usbserial != undefined ? this.usbserial : '';
    let usbCheck = this.ems.getEntitlements(this.accountid, 1, 1, 1, 'all', usb, 1, 20);
    this.snackMsg.openSnackBar("Searching for key(s) with Serial: " + usb, 1000);
    forkJoin([usbCheck]).subscribe(next => {
      let nextcheck = next[0];
      if (nextcheck.entitlements.length > 0) {
        this.exportString = this.ems.getUSBKeyLicense(this.accountid, usb);
        this.searchingUSB = false;
        return true;
      }
      else {
        this.snackMsg.openSnackBar("No matching key found", 2000);
        this.exportString = "";
        this.searchingUSB = false;
        return true;
      }
    },
      (error) => {
        this.snackMsg.openSnackBar("An error orccurred while searching for key(s) with Serial: " + usb, 1000);
        this.searchingUSB = false;
        let e = error;
        return true;
      });
  }

}
