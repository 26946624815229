import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackService } from '../../services/snack.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ColumnData, ColumnFilterData } from '../../types/ColumnData';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EntitlementService } from '../../services/ems.service';

@Component({
  selector: 'app-select-columns',
  templateUrl: './select-columns.component.html',
  styleUrls: ['./select-columns.component.scss'],
})
export class SelectColumnsComponent {
  dragcolumns: MatTableDataSource<string> = new MatTableDataSource<string>();
  column_data_orig: ColumnData[] = null;
  saved_column_data: ColumnData[] = null;
  data_filtered: boolean = false;
  filter_text: string = "";
  maincolumncontainer: any = null;
  filter_columns: ColumnFilterData[] = [];
  @Output() emitService = new EventEmitter();
  constructor(
    public snackMsg: SnackService,
    public spinner_edit: NgxSpinnerService,
    public dialogRef: MatDialogRef<SelectColumnsComponent>,
    public ems: EntitlementService,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        datasource: MatTableDataSource<any>,
        column_data: ColumnData[],
        saved_column_data: ColumnData[],
        dirty: boolean
      }) {
  }

  ngOnInit(): void {
    this.data.column_data = this.getColumnsAll();
    this.column_data_orig = JSON.parse(JSON.stringify(this.data.column_data));
    if (this.column_data_orig == null) {
      this.saved_column_data = JSON.parse(JSON.stringify(this.data.saved_column_data));
    }
    this.column_data_orig.forEach(e => {
      this.filter_columns.push({ id: e.id, name: e.name, show_list: true });
    });
  }

  ngAfterViewInit() {
  }

  save() {
    this.dialogRef.close({ column_data: this.data.column_data, save: true, dirty: this.data.dirty });
  }

  close() {
    this.dialogRef.close({ column_data: this.data.column_data, save: false, dirty: this.data.dirty });
  }

  updateColumns() {
    let newColumns: ColumnData[] = [];
    this.getFrozenData().forEach((z: ColumnData, index: number) => {
      z.index = index;
      newColumns.push(z);
    });
    this.getWarmData().forEach((z: ColumnData, index: number) => {
      z.index = index + this.getFrozenData().length;
      newColumns.push(z);
    });
    this.data.column_data = newColumns;
    this.emitService.next(this.data.column_data);
    return this.data.column_data;
  }

  defaults() {
    this.data.column_data = JSON.parse(JSON.stringify(this.data.saved_column_data));
    this.data_filtered = false;
    this.filter_text = "";
    this.filter_columns.forEach(e => {
      e.show_list = true;
    });
    this.data.dirty = true;
    this.updateColumns();
  }

  reset() {
    this.data.column_data = JSON.parse(JSON.stringify(this.column_data_orig));
    this.data_filtered = false;
    this.filter_text = "";
    this.filter_columns.forEach(e => {
      e.show_list = true;
    });
    this.data.dirty = false;
    this.updateColumns();
  }

  discardAndReset() {
    this.reset();
    this.dialogRef.close({ column_data: this.data.column_data, save: false, dirty: this.data.dirty });
  }

  setVisibleFromCheck(checkbox: MatCheckboxChange, column: ColumnData) {
    this.data.dirty = true;
    this.data.column_data.forEach(e => {
      if (e == column) {
        this.setVisible(column, checkbox.checked)
      }
    });
    this.updateColumns();
  }

  setVisible(column: ColumnData, visible: boolean) {
    let newcolumns: ColumnData[] = [];
    this.data.column_data.forEach(e => {
      if (e.id == column.id) {
        e.visible = visible;
      }
      newcolumns.push(e);
    });
    this.updateColumns();
  }

  SetVisibleFromDrag(columnid: string, visible: boolean) {
    let newcolumns: ColumnData[] = [];
    this.data.column_data.forEach(e => {
      if (e.id == columnid) {
        e.visible = visible;
      }
      newcolumns.push(e);
    });
    this.updateColumns();
  }

  getColumnsAll() {
    let newcolumns: ColumnData[] = [];
    newcolumns = newcolumns.concat(this.getFrozenData());
    newcolumns = newcolumns.concat(this.getWarmData());
    return newcolumns;
  }

  getDisplayedColumnsFreeze() {
    return this.data.column_data.filter(e => e.freeze).map(e => e.id);
  }

  getDisplayedColumns() {
    return this.data.column_data.filter(e => !e.freeze).map(e => e.id);
  }

  getFreezeByColumnIdForSliderValue(columnid: string) {
    return this.getFreezeByColumnId(columnid) ? 1 : 0;
  }

  getFreezeByColumnId(columnid: string) {
    return this.data.column_data.filter(e => e.id == columnid).map(e => e.freeze).pop();
  }

  getVisibleByColumnId(columnid: string) {
    return this.data.column_data.filter(e => e.id == columnid).map(e => e.visible).pop();
  }

  getVisibleDisabledState(columnid: string) {
    return this.data.column_data.filter(e => e.id == columnid).pop().freeze;
  }

  getFreezeDisabledState(columnid: string) {
    return !(this.data.column_data.filter(e => e.id == columnid).pop().visible);
  }

  getDragDisabledState(columnid: string) {
    let result = false;
    if (this.data_filtered) {
      result = true;
    } else {
      let continueLoop = true;
      this.data.column_data.every(element => {
        if (element.id == columnid) {
          if (element.freeze || !element.visible) {
            result = true;
            continueLoop = false;
          }
        }
        return continueLoop;
      });
    }
    return result;
  }

  getColumnNames(columns: ColumnData[]) {
    return columns.map(e => e.id);
  }

  getWarmData() {
    let items = null;
    if (this.data.column_data == null) return [];
    items = this.data.column_data.filter(e => !e.freeze).map(e => e);
    return items;
  }

  getFrozenData() {
    let items = null;
    if (this.data.column_data == null) return [];
    items = this.data.column_data.filter(e => e.freeze).map(e => e);
    return items;
  }

  getColumnByColumnId(columnid: string) {
    return this.data.column_data.filter(e => e.id == columnid).pop();
  }

  getPositionByColumnId(columnid: string) {
    let continueLoop = true;
    let result = 0;
    this.data.column_data.every(e => {
      if (e.id == columnid) {
        continueLoop = false;
      } else {
        result++;
      }
      return continueLoop;
    });
    return result;
  }

  updateColumnOrderMain(column: ColumnData, current: number, newloc: number) {
    this.data.column_data.splice(current, 1);
    this.data.column_data.splice(newloc, 0, column);
    this.updateColumns();
    return this.data.column_data;
  }

  sliderChange(slider: any, columnid: string) {
    this.data.dirty = true;
    const sliderval = slider.srcElement.value;
    const column = this.getColumnByColumnId(columnid);
    const previousIndex: number = this.getPositionByColumnId(columnid)
    const newIndex: number = this.getFrozenData().length;
    column.freeze = sliderval == 0 ? false : true;
    this.updateColumnOrderMain(column, previousIndex, newIndex)
  }

  showItem(column: ColumnData) {
    let showItem = true;
    this.filter_columns.forEach(e => {
      if (e.id == column.id) {
        showItem = e.show_list == null || e.show_list ? true : false;
      }
    });
    return showItem;
  }

  applyFilterEvent(filterEvent: Event): void {
    const val = (<HTMLInputElement>filterEvent.target).value;
    if (val == "") {
      this.data_filtered = false;
    } else {
      this.data_filtered = true;
    }
    this.filter_columns.forEach(e => {
      if (e.name.toLowerCase().includes(val.toLowerCase())) {
        e.show_list = true;
      } else {
        e.show_list = false;
      }
    });
  }

  dropColumn(event: CdkDragDrop<MatTableDataSource<string>>) {
    this.data.dirty = true;
    const previousIndex: number = this.getFrozenData().length + event.previousIndex;
    const currentIndex = this.getFrozenData().length + event.currentIndex;
    const column = this.data.column_data[previousIndex];
    const disabled = !this.getVisibleByColumnId(column.id);
    if (previousIndex == currentIndex) {
      this.SetVisibleFromDrag(column.id, disabled);
    } else {
      this.data.column_data = this.updateColumnOrderMain(column, previousIndex, currentIndex);
    };
  }
}
