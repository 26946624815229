import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EntitlementService } from '../../../services/ems.service';
import { AuthorizationService } from '../../../services/authorization.service';
import { Chart } from 'chart.js/auto';
import { DatePipe } from '@angular/common';
import { SplashService } from 'src/app/modules/services/splash.service';

@Component({
  selector: 'app-admin-status',
  templateUrl: './admin-status.component.html',
  styleUrls: [
    '../../../shared-styles/divs.scss',
    '../../../shared-styles/tables.scss',
    '../../../shared-styles/elements.scss',
    './admin-status.component.scss'
  ]
})
export class AdminStatusComponent implements AfterViewInit {
  userLoginData: any;
  LOADDURATION: number = 60;
  loginDisplayedColumns: string[] = [
    'creationtime',
    'user',
    'type'
  ];
  loginchart: any;
  login2chart: any;

  @ViewChild(MatPaginator, { static: true }) Loginpaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) Loginsort: MatSort;

  constructor(
    private ems: EntitlementService,
    private auth: AuthorizationService,
    private splashService: SplashService,
    private datePipe: DatePipe
  ) {
    this.splashService.show(this.LOADDURATION);
    this.ems.getAdminStatus().subscribe((data) => {
      this.createLoginChart(data);
      this.userLoginData = new MatTableDataSource(data.logins);
      if (this.Loginpaginator != null) this.userLoginData.paginator = this.Loginpaginator;
      if (this.Loginsort != null) this.userLoginData.sort = this.Loginsort;
      this.splashService.hide();
    },
      () => {
        this.splashService.hide()
      }
    );
  }

  createLoginChart(data) {
    // for line graph
    let dateLoginLabels = [];
    let totalLoginData: any = {};
    let totalAdminData: any = {};
    let totalTrusted: any = {};
    let totalOpsMan: any = {};
    let totalUser: any = {};

    // for bar graph
    let userLabels = [];
    let userData: any = {};

    data.logins.forEach(function (value) {
      var dateLabel = value.creationtime.split('T')[0];
      var timestamp = new Date().getTime() - (90 * 24 * 60 * 60 * 1000)
      var datems =  Date.parse(dateLabel);
      if (datems >= timestamp) {

        // total labels
        if (!dateLoginLabels.includes(dateLabel)) {
          dateLoginLabels.push(dateLabel);
        }

        // user labels
        if (!userLabels.includes(value.user)) {
          userLabels.push(value.user);
        }

        // bar graph
        if (typeof (userData[value.user]) === "undefined") {
          userData[value.user] = 1
        } else {
          userData[value.user] += 1
        }

        // line graph
        if (typeof (totalLoginData[dateLabel]) === "undefined") {
          totalLoginData[dateLabel] = 1;
        } else {
          totalLoginData[dateLabel] += 1;
        }

        // update admin
        if (value.type.toLowerCase() == "administrator") {
          if (typeof (totalAdminData[dateLabel]) === "undefined") {
            totalAdminData[dateLabel] = 1;
          } else {
            totalAdminData[dateLabel] += 1;
          }
        }

        // trusted
        if (value.type.toLowerCase() == "trusted 3rd party admin") {
          if (typeof (totalTrusted[dateLabel]) === "undefined") {
            totalTrusted[dateLabel] = 1;
          } else {
            totalTrusted[dateLabel] += 1;
          }
        }

        // ops manager
        if (value.type.toLowerCase() == "operations manager") {
          if (typeof (totalOpsMan[dateLabel]) === "undefined") {
            totalOpsMan[dateLabel] = 1;
          } else {
            totalOpsMan[dateLabel] += 1;
          }
        }

        // user
        if (value.type.toLowerCase() == "user") {
          if (typeof (totalUser[dateLabel]) === "undefined") {
            totalUser[dateLabel] = 1;
          } else {
            totalUser[dateLabel] += 1;
          }
        }
      }
    });

    this.loginchart = new Chart("loginChart", {
      type: 'line',
      data: {
        labels: dateLoginLabels,
        datasets: [
          {
            label: "# Total",
            data: totalLoginData,
            backgroundColor: 'rgba(255,99,132,.2)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 3,
            fill: true
          },
          {
            label: "# Admin",
            data: totalAdminData,
            backgroundColor: '#EEA47FFF',
            borderColor: '#EEA47FFF',
            borderWidth: 1
          },
          {
            label: "# OpsManager",
            data: totalOpsMan,
            backgroundColor: '#990011FF',
            borderColor: '#990011FF',
            borderWidth: 1
          },
          {
            label: "# Trusted3rdParty",
            data: totalTrusted,
            backgroundColor: '#101820FF',
            borderColor: '#101820FF',
            borderWidth: 1
          },
          {
            label: "# Users",
            data: totalUser,
            backgroundColor: '#00FFFF',
            borderColor: '#00FFFF',
            borderWidth: 1
          }
        ]
      },
      options: {
        aspectRatio: 2.5,
      }
    });

    this.login2chart = new Chart("login2Chart", {
      type: 'bar',
      data: {
        labels: userLabels,
        datasets: [{
          label: "Users",
          data: userData,
          backgroundColor: 'rgba(75, 00, 150, 0.2)',
          borderColor: 'rgba(75, 00, 150,1)',
          borderWidth: 2,
        }]
      },
      options: {
      }
    });
  }

  applyFilterUserEvent(filterEvent: Event) {
    if (filterEvent != null) this.applyUserFilter((<HTMLInputElement>filterEvent.target).value);
  }

  applyUserFilter(filterValue: string) {
    this.userLoginData.filter = filterValue.trim().toLowerCase();
    if (this.userLoginData.paginator) {
      this.userLoginData.paginator.firstPage();
    }
  }

  ngAfterViewInit(): void {
    if (this.userLoginData) {
      if (this.Loginpaginator != null) this.userLoginData.paginator = this.Loginpaginator;
      if (this.Loginsort != null) this.Loginsort.sortChange.subscribe(() => { if (this.Loginsort != null) this.Loginpaginator.pageIndex = 0 });
    }
  }
}

