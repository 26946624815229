import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthorizationData } from '../types/AuthorizationData';
import { IUserData } from '../types/UserData';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, shareReplay, timeout } from 'rxjs/operators';
import { Router } from "@angular/router";

@Injectable()
export class AuthorizationService {
    private user: IUserData;
    private endpoint: string = environment.baseApiUrl; //'https://tenasysws.azurewebsites.net';
    private version: string = environment.apiVersion;
    private previousPage: string | undefined;
    private isNavigatingToMaintenance = false;
    readonly storageItemName: string = 'tenasyslicman';
    constructor(
        private http: HttpClient,
        private router: Router
    ) {
        this.user = <IUserData>{};
    }

    public setPreviousPage(route: string) {
        this.previousPage = route;
    }

    public getPreviousPage() {
        return this.previousPage;
    }

    public reset() {
        this.user.username = "";
        this.user.userType = -1;
        this.user.contact = undefined;
        this.user.securityToken = "";
    }

    public setSession() {
        sessionStorage.setItem(this.storageItemName, JSON.stringify(this.user));
    }

    public restoreSession() {
        if (sessionStorage.getItem(this.storageItemName) === null) return;
        else this.user = JSON.parse(sessionStorage.getItem(this.storageItemName) ?? "");
        this.isTokenValid().subscribe((data) => {
            if (data != true) {
                this.reset();
                if (!(this.router.url == '/entitlement' || this.router.url == '/login')) {
                    this.router.navigateByUrl('login');
                }
            }
        });
    }

    public isEntitlement() {
        var isEntitlement = false;
        if (this.user.username != null && this.user.username != "") {
            isEntitlement = (this.user.username.match(/-/g) || []).length == 4;
        }
        return isEntitlement;
    }

    // returns string name of user or "Login/Logout/Exit"
    public whoLoggedIn() {
        let loginText = "";
        if (this.isLoggedIn()) {
            loginText = this.user.username;
        }
        return loginText;
    }

    // boolean whether logged in
    public isLoggedIn() {
        var loggedIn = false;
        let baseUrl = this.router.url.split('?')[0];
        if (baseUrl.endsWith('/login') || baseUrl.endsWith('/entitlement')) {
        } else if ((this.user.username != null && this.user.username != "")
            && !this.isEntitlement()
            && (this.user.securityToken != null && this.user.securityToken != "")) {
            loggedIn = true;
        }
        return loggedIn;
    }

    public setContact(contact: any) {
        this.user.contact = contact;
    }

    public getCRMID() {
        return this.user.contact.crmid;
    }

    public getUsername() {
        return this.user.username;
    }

    public checkTokenClaimDate(): boolean {
        return false;
    }

    public setSecurityToken(token: string) {
        this.user.securityToken = token;
    }

    public getSecurityToken() {
        return this.user.securityToken;
    }

    public getCrmID() {
        return this.user.contact.crmid;
    }

    public getUserType() {
        return this.user.userType;
    }

    public setUserType(userType: number) {
        this.user.userType = userType;
    }

    public sendToMaintenance() {
        if (!this.isNavigatingToMaintenance) {
            this.isNavigatingToMaintenance = true;
            this.router.navigate(['/maintenance']);
        }
    }

    public isTokenValid() {
        let body = {
            username: this.user.username,
            securityToken: this.user.securityToken,
            userType: this.user.userType
        }
        var url = this.endpoint + '/api/' + this.version + '/testtoken';
        var timeoutSeconds: number = 60
        //return this.http.post<any>(url, body).pipe(shareReplay(1));

        return this.http.post<any>(url, body).pipe(
            timeout(timeoutSeconds * 1000),
            catchError((error) => {
                let errCode:number = 0;
                let runId:number = 0;
                if (error != null) {
                    if (error.status == 503) {
                        this.sendToMaintenance();
                    }
                } 
                return of(false); 
            }),
            shareReplay(1)
        );
    }

    public login(username: string, password: string): Observable<AuthorizationData> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        this.user.username = username;
        let pass = password;
        let body = {
            username: this.user.username,
            password: pass
        }
        return this.http.post<AuthorizationData>(this.endpoint + '/api/' + this.version + '/login', body, { headers }).pipe(shareReplay(1));
    }

    public entitlementLogin(entitlementid: string): Observable<AuthorizationData> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        this.user.username = entitlementid;
        return this.http.get<AuthorizationData>(this.endpoint + '/api/' + this.version + '/entitlementlogin/' + entitlementid, { headers }).pipe(shareReplay(1));
    }

    public writeToConsole(out: string) {
        window.console.log(out);
    }
}
