import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class CRMService {
    // https://tenasys.crm.dynamics.com or https://tenasyssandbox.crm.dynamics.com/
    public endpoint:string = "";
    public getBaseUrl() {
        return environment.baseCrmUrl;
    }
}