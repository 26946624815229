import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../../services/authorization.service';
import { CustomerDataService } from '../../services/customer-data.service';
import { Router, ActivatedRoute } from "@angular/router";
import { SnackService } from '../../services/snack.service';
import { MatDialog } from '@angular/material/dialog';
import { AccountData } from '../../types/AccountData';
import { EntitlementService } from '../../services/ems.service';
import { CidLookup } from '../../types/CidLookup';
import { QrmodalComponent } from '../qrmodal/qrmodal.component';
import { SplashService } from '../../services/splash.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-qrlookup',
  templateUrl: './qrlookup.component.html',
  styleUrls: [
    '../../shared-styles/divs.scss',
    '../../shared-styles/tables.scss',
    '../../shared-styles/elements.scss',
    './qrlookup.component.scss'
  ]
})
export class QrlookupComponent implements OnInit {
  username: string = "";
  password: string = "";
  computerid: string = "";
  serial: string = "";
  enhLicense: string = "";
  classLicense: string = "";
  showLogin: boolean = false;
  showLogoff: boolean = false;
  timedOut: boolean = false;
  isLicShown: boolean = false;
  LOADDURATION: number = 8;
  cidData: CidLookup;
  constructor(public auth: AuthorizationService,
    private router: Router, 
    private snackMsg: SnackService,
    private splash: SplashService,
    private spinner: NgxSpinnerService,
    private customerData: CustomerDataService,
    public dialog: MatDialog, 
    private routeParams: ActivatedRoute,
    private route: ActivatedRoute,
    private ems: EntitlementService) {
      this.computerid = this.route.snapshot.paramMap.get('computerid') ?? "";
      this.serial = this.route.snapshot.paramMap.get('serial') ?? "";
    }

  ngOnInit(): void {
  }

  public onPasswordChange(key: any) {
    if (key === "Enter") {
      this.tryLogin();
    }
  }

  public showLicense() {
    var cidData2 = new CidLookup(this.cidData.toString());
    if (cidData2.classic_licenses != null && cidData2.classic_licenses != undefined){
      cidData2.classic_licenses = cidData2.classic_licenses.replace(/\"/g, '')
    }
    if (this.cidData != null)
    {
      var cidData2 = new CidLookup(this.cidData.toString());
      const dialogRef = this.dialog.open(QrmodalComponent, {
        data: {
          enhanced_license: cidData2.enhanced_licenses,
          classic_license: cidData2.classic_licenses,
          usbid: this.serial
        },
        height: '600px',
        width: '80vw'
      });
      dialogRef.afterClosed().subscribe(result => {});
    }
  }

   public tryLogin() {
    if (this.serial == "" || this.username == "" || this.password == "") {
      this.snackMsg.openSnackBar("Please enter your TenAsys License Portal credentials and the USB/CID serial number to continue.", 5000);  
    } else {
      this.spinner.show("spinneronly");
      this.auth.login(this.username, this.password)
        .subscribe(data => {
          this.customerData.loginType = "login";          
          if (data.securityToken == "Login Failed") { 
            this.snackMsg.openSnackBar("Login Failed.", 5000);        
          } else {
            this.auth.setContact(data.contact);
            this.auth.setSecurityToken(data.securityToken);
            this.auth.setUserType(data.userType);
            this.customerData.accountData = new AccountData();
            this.customerData.username = this.username;
            this.customerData.accountData.accountid = data.customer.id;
            this.customerData.accountData.name = data.customer.name;              
            this.auth.setSession();
            if (data.customer.name == "changepassword"){
              this.router.navigateByUrl('changepassword');
            } else {
              this.splash.show(this.LOADDURATION);
              this.ems.GetCidInfo<CidLookup>(this.serial).subscribe((cidData) => {
                this.cidData = cidData;  
                if (cidData != null && !cidData.hasOwnProperty('ErrorCode')) {                  
                  this.showLicense() ;
                } else {
                  this.snackMsg.openSnackBar("No license found for USB/CID serial number provided.  Please check the serial number and try again.", 5000);  
                }
                this.splash.hide();                
              });              
            }
          }
  
          this.spinner.hide("spinneronly");
        },
        error => {
          this.spinner.hide("spinneronly");
          this.splash.hide();
          this.snackMsg.openSnackBar("The login request failed.", 5000); 
        }
      );
    } 
  }
}
