import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EntitlementData } from '../../../../types/EntitlementData';
import { OrderData } from '../../../../types/OrderData';
import { Invoice } from '../../../../types/InvoiceData';
import { AuthorizationService } from '../../../../services/authorization.service';
import { CustomerDataService } from '../../../../services/customer-data.service';
import { Router, ActivatedRoute } from "@angular/router";
import { EntitlementService } from '../../../../services/ems.service';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { saveAs } from 'file-saver';
import { DatePipe, formatDate } from '@angular/common';
import { CRMService } from 'src/app/modules/services/crm.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { ExportColumns } from 'src/app/modules/types/ExportColumns';
import { SplashService } from 'src/app/modules/services/splash.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackService } from 'src/app/modules/services/snack.service';
import { EditEntitlementComponent } from '../edit-entitlement/edit-entitlement.component';
import { ViewEntitlementComponent } from '../view-entitlement/view-entitlement.component';
import { ColumnData, UpdateColumnRequest, UpdateColumnResponse } from 'src/app/modules/types/ColumnData';
import { SelectColumnsComponent } from 'src/app/modules/shared-components/select-columns/select-columns.component';
import { DeleteEntitlementComponent } from '../delete-entitlement/delete-entitlement.component';
@Component({
  selector: 'app-admin-customer-entitlements',
  templateUrl: './admin-customer-entitlements.component.html',
  styleUrls: [
    '../../../../shared-styles/divs.scss',
    '../../../../shared-styles/tables.scss',
    '../../../../shared-styles/elements.scss',
    './admin-customer-entitlements.component.scss'
  ]
})
export class AdminCustomerEntitlementsComponent implements OnInit, AfterViewInit {
  isLoading: boolean = true;
  user: string = "";
  entitlements: Array<EntitlementData> = new Array<EntitlementData>();
  accountname: string = "";
  filterString: string = "";
  dataSource: MatTableDataSource<EntitlementData> = new MatTableDataSource<EntitlementData>();
  orders: Array<OrderData> = new Array<OrderData>();
  invoices: Array<Invoice> = new Array<Invoice>();
  filterActivatedState: number = 0;
  filterReservedState: number = 0;
  filterCidState: number = 0;
  filterProductState: string = "";
  crmendpoint: string = "";
  totalEntitlements: number = 0;
  accountid: string = "";
  sortColumn: string = "id";
  isChanged: boolean = false
  sortDirectionIsDesc: number = 0;
  products: Array<string> = new Array<string>();
  hasData: boolean = true;
  pageSize: number = 0;
  LOADDURATION: number = 45;
  displayedColumns: string[] = [];
  column_data: ColumnData[] = [];
  saved_column_data: ColumnData[] = [];
  customersResult = null;
  download_started: boolean = false;
  stringSplit = "!$1$!";
  batchCount: number = 0;
  reservationTypeResult = null;
  dirty: boolean = false;
  pageEvent: PageEvent = new PageEvent();
  adminEntitlementsForm = this.fb.group({
    formlockstyle: this.fb.control('0'),
    radexport: this.fb.control('adminfull'),
  });

  @ViewChild(MatPaginator, { static: true }) paginator?: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort?: MatSort;

  constructor(
    private datePipe: DatePipe = new DatePipe('mediumDate'),
    private router: Router,
    private auth: AuthorizationService,
    private ems: EntitlementService,
    private splash: SplashService,
    public spinner: NgxSpinnerService,
    public spinner_0: NgxSpinnerService,
    private route: ActivatedRoute,
    private customerData: CustomerDataService,
    public crm: CRMService,
    public snackMsg: SnackService,
    public dialog: MatDialog,
    public fb: FormBuilder) {
    this.init();
  }

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator ?? null;
      if (this.sort != undefined) this.sort.sortChange.subscribe(next => {
        if (this.paginator != null) this.paginator.pageIndex = 0;
      }, () => {
        this.snackMsg.openSnackBar("An Error Has Occurred.", 5000);
        this.splash.hide();
      });
    }
  }

  init() {
    this.splash.show(this.LOADDURATION);
    this.crmendpoint = this.crm.getBaseUrl();
    this.user = this.route.snapshot.paramMap.get('username') ?? "";
    this.accountname = this.route.snapshot.paramMap.get('customer') ?? "";
    this.pageSize = this.ems.getPageSize();
    this.totalEntitlements = this.customerData.totalEntitlements;
    this.accountid = this.customerData.accountData.accountid;
    const getAccount = this.ems.getAccountAdmin(this.accountid, 1, this.pageSize, true);
    const getProducts = this.ems.getProducts();
    const getAccountInvoices = this.ems.getAccountInvoices(this.accountid);
    const getReservationTypes = this.ems.getReservationTypes();
    const getUserSettings = this.ems.getUserSettings(this.auth.getCRMID(), this.ems.userMode);
    const getCustomers = this.ems.getCustomers();
    let pages = Math.ceil(this.totalEntitlements / this.pageSize);
    var getQueries: Array<Observable<any>> = new Array<Observable<any>>();
    getQueries.push(getProducts);
    getQueries.push(getReservationTypes);
    getQueries.push(getAccount);
    getQueries.push(getAccountInvoices);
    getQueries.push(getUserSettings);
    getQueries.push(getCustomers);
    for (let i = 1; i <= pages; i++) {
      getQueries.push(this.ems.getEntitlements(this.accountid, 0, 0, 0, '*', '', i, this.pageSize));
    }
    this.entitlements = new Array<EntitlementData>();
    let productMap: Map<string, string> = new Map<string, string>();

    forkJoin(getQueries).subscribe(next => {
      if (next == null) {
        this.splash.hide();
        return;
      }
      const productResult = next[0]
      this.reservationTypeResult = next[1];
      const accountResult = next[2];
      const accountInvoiceResult = next[3];
      const userResult: UpdateColumnResponse = next[4];
      this.customersResult = next[5];
      this.accountname = accountResult.account.name;
      this.orders = accountResult.orders;
      this.invoices = accountResult.invoices;
      if (userResult.ColumnDataCustom != null) {
        userResult.ColumnDataCustom = userResult.ColumnDataCustom.sort((a, b) => a.index - b.index);
        this.column_data = userResult.ColumnDataCustom;
      } else {
        userResult.ColumnDataDefault = userResult.ColumnDataDefault.sort((a, b) => a.index - b.index);
        this.column_data = userResult.ColumnDataDefault;
      }
      this.saved_column_data = JSON.parse(JSON.stringify(userResult.ColumnDataDefault));
      this.displayedColumns = this.getDisplayedColumnsAll();

      for (let i = 1; i <= next.length - 1; i++) {
        let getEntitlementsResult = next[i]
        for (let e in getEntitlementsResult.entitlements) {
          let entitlement: EntitlementData = getEntitlementsResult.entitlements[e];
          // add in product info
          let product = productResult.filter((value) => value.Id == entitlement.productmapid);
          if (product.length >= 1) {
            entitlement.productname = product[0].ProductName;
          } else {
            entitlement.productname = "";
          }

          // add in invoice info
          let invoice = accountInvoiceResult.filter((value) => entitlement.crminvoiceid != null && value.crminvoiceid.toLowerCase() == entitlement.crminvoiceid.toLowerCase());
          if (invoice.length >= 1) {
            entitlement.crminvoicename = invoice[0].crminvoicename;
          } else {
            entitlement.crminvoicename = "";
          }

          // add in reservation info
          let reservationType = this.reservationTypeResult.filter((value) => value.id == entitlement.reservation_type_id);
          if (reservationType.length >= 1) {
            entitlement.reservationtype = reservationType[0].type;
            entitlement.reservation_type_desc = reservationType[0].type_description;
          } else {
            entitlement.reservationtype = "";
            entitlement.reservation_type_desc = "";
          }

          if (!productMap.has(getEntitlementsResult.entitlements[e].productname)) {
            productMap.set(getEntitlementsResult.entitlements[e].productname, getEntitlementsResult.entitlements[e].productname);
            this.products.push(getEntitlementsResult.entitlements[e].productname);
          }
          this.entitlements.push(entitlement);
        }
      }
      this.dataSource = new MatTableDataSource(this.entitlements);
      this.dataSource.paginator = this.paginator ?? null;
      this.dataSource.sort = this.sort ?? null;
      this.applyFilter();
      this.splash.hide();
    }, () => {
      this.snackMsg.openSnackBar("An Error Has Occurred.", 5000);
      this.splash.hide();
    });
  }

  updateFilterPredicate(filterValues: string, column_data: ColumnData[]) {
    this.dataSource.filterPredicate = function (d: EntitlementData, filter: string): boolean {
      let filter_parts = filter.split("!$1$!");
      filter = filter_parts[0];
      let productFilter = filter_parts[1];
      let activated = Number(filter_parts[2]);
      let cid = Number(filter_parts[3]);
      let keepRow = true;
      let searchId = true;
      let searchPO = true;
      let searchProductName = true;
      let searchTenasysEntitlementID = true;
      let searchSUAC = true;
      let searchLicense = true;
      let searchCustomerActivationComment = true;
      let searchUSBKey = true;
      let searchActivatedBy = true;
      let searchActivatedDate = true;
      let searchIPAddress = true;
      let searchCRMInvoiceName = true;
      let searchClassicInvoiceNumber = true;
      let searchClassicOrderNumber = true;
      let searchCreatedDate = true;
      let searchCIDClassicLicense = true;
      let searchCreatedBy = true;
      let searchInternalComment = true;
      let searchSource = true;
      let searchCreateBatchID = true;
      let serarchReservationType = true;
      let searchFingerprint = true;

      function getVisibleByColumnId(columnid: string) {
        return column_data.filter(e => e.id == columnid).map(e => e.visible).pop();
      }
      if (column_data != null) {
        searchId = getVisibleByColumnId("id");
        searchProductName = getVisibleByColumnId("productname");
        searchTenasysEntitlementID = getVisibleByColumnId("tenasysentitlementid");
        searchActivatedDate = getVisibleByColumnId("activateddate");
        searchActivatedBy = getVisibleByColumnId("activatedby");
        searchLicense = getVisibleByColumnId("license");
        searchCustomerActivationComment = getVisibleByColumnId("customeractivationcomment");
        searchIPAddress = getVisibleByColumnId("ipaddress");
        searchCRMInvoiceName = getVisibleByColumnId("crminvoicename");
        searchPO = getVisibleByColumnId("ponumber");
        searchInternalComment = getVisibleByColumnId("internalcomment");
        searchUSBKey = getVisibleByColumnId("usbkey");
        searchSource = getVisibleByColumnId("source");
        searchCreatedDate = getVisibleByColumnId("createddate");
        searchCreatedBy = getVisibleByColumnId("createdby");
        searchFingerprint = getVisibleByColumnId("fingerprint");
        searchSUAC = getVisibleByColumnId("suac");
        searchClassicInvoiceNumber = getVisibleByColumnId("classicinvoice");
        searchClassicOrderNumber = getVisibleByColumnId("classicorder");
        serarchReservationType = getVisibleByColumnId("reservationtype");
        searchCreateBatchID = getVisibleByColumnId("createbatchid");
        searchCIDClassicLicense = getVisibleByColumnId("cidclassiclicense");
      }

      // ensure case insensitivity
      filter = filter.toLowerCase();

      const foundId = searchId && d.id != null && d.id.toString().trim().indexOf(filter) != -1;
      const foundProductName = searchProductName && d.productname != null && d.productname.toLowerCase().trim().indexOf(filter) != -1;
      const foundTenasysEntitlementID = searchTenasysEntitlementID && d.tenasysentitlementid != null && d.tenasysentitlementid.toLowerCase().trim().indexOf(filter) != -1;
      const foundActivatedDate = searchActivatedDate != null && d.activateddate != null && d.activateddate.toString().toLowerCase().indexOf(filter) != -1;
      const foundActivatedBy = searchActivatedBy && d.activatedby != null && d.activatedby.toLowerCase().trim().indexOf(filter) != -1;
      const foundLicense = searchLicense && d.license != null && d.license.toLowerCase().trim().startsWith(filter);
      const foundFingerprint = searchFingerprint && d.fingerprint != null && d.fingerprint.toLowerCase().trim().indexOf(filter) != -1;
      const foundCustomerActivationComment = searchCustomerActivationComment && d.customeractivationcomment != null && d.customeractivationcomment.toLowerCase().trim().indexOf(filter) != -1;
      const foundIPAddress = searchIPAddress && d.ipaddress != null && d.ipaddress.toLowerCase().trim().indexOf(filter) != -1;
      const foundCRMInvoiceName = searchCRMInvoiceName && d.crminvoicename != null && d.crminvoicename.toLowerCase().trim().indexOf(filter) != -1;
      const foundPO = searchPO && d.ponumber != null && d.ponumber.toLowerCase().trim().indexOf(filter) != -1;
      const foundInternalComment = searchInternalComment && d.internalcomment != null && d.internalcomment.toLowerCase().trim().indexOf(filter) != -1;
      const foundUSBKey = searchUSBKey && d.usbkey != null && d.usbkey.toLowerCase().trim().indexOf(filter) != -1;
      const foundSource = searchSource && d.source != null && d.source.toLowerCase().trim().indexOf(filter) != -1;
      const foundCreatedDate = searchCreatedDate != null && d.createddate != null && d.createddate.toString().toLowerCase().indexOf(filter) != -1;
      const foundCreatedBy = searchCreatedBy && d.createdby != null && d.createdby.toLowerCase().indexOf(filter) != -1;
      const foundSUAC = searchSUAC && d.suac != null && d.suac.toLowerCase().indexOf(filter) != -1;
      const foundClassicInvoiceNumber = searchClassicInvoiceNumber && d.classicinvoicenumber != null && d.classicinvoicenumber.toLowerCase().indexOf(filter) != -1;
      const foundClassicOrderNumber = searchClassicOrderNumber && d.classicordernumber != null && d.classicordernumber.toLowerCase().indexOf(filter) != -1;
      const foundCIDClassicLicense = searchCIDClassicLicense && d.cidclassiclicense != null && d.cidclassiclicense.toLowerCase().startsWith(filter);
      const foundReservationType = serarchReservationType && d.reservationtype != null && d.reservationtype.toLowerCase().startsWith(filter);
      const foundCreateBatchID = searchCreateBatchID && d.createbatchid != null && d.createbatchid.toString().trim().indexOf(filter) != -1;
      if (filter.length == 0 || foundId || foundProductName || foundTenasysEntitlementID || foundActivatedDate || foundActivatedBy ||
        foundLicense || foundFingerprint || foundCustomerActivationComment || foundIPAddress || foundCRMInvoiceName || foundPO ||
        foundInternalComment || foundUSBKey || foundSource || foundCreatedDate || foundCreatedBy || foundSUAC || foundClassicInvoiceNumber ||
        foundClassicOrderNumber || foundCIDClassicLicense || foundReservationType || foundCreateBatchID) {
        keepRow = true;
      } else {
        keepRow = false;
      }

      if (keepRow) {
        if (cid === 0 || (cid === 1 && (d.usbkey !== "" && d.usbkey !== null)) || (cid === 2 && (d.usbkey === "" || d.usbkey === null))) {
          keepRow = true;
        } else {
          keepRow = false;
        }
      }

      if (keepRow) {
        if (productFilter.toLowerCase() === "" || productFilter.toLowerCase() === "all" || d.productname.toLowerCase() === productFilter.toLowerCase()) {
          keepRow = true;
        } else {
          keepRow = false;
        }
      }

      if (keepRow) {
        if (activated == 0
          || (activated == 1 && !(d.license == null || d.license == ""))
          || (activated == 2 && (d.license == null || d.license == ""))) {
          keepRow = true;
        } else {
          keepRow = false;
        }
      }
      return keepRow;
    }
    this.dataSource.filter = filterValues;
  }

  save() {
    this.snackMsg.openSnackBar("Saving your changes, please wait!", 5000);
    this.spinner.show("spinneronly");
    let req = new UpdateColumnRequest("admin-entitlements");
    req.userid = this.auth.getCrmID();
    req.column_data = this.column_data;
    this.ems.saveEntitlementDetailColumns(req).subscribe((respond) => {
      let updatemsg = "An error occurred while saving your column settings.  Please try again later.";
      if (respond.TotalUpdated > 0) {
        updatemsg = "Your column settings have been saved.";
        this.snackMsg.openSnackBar(updatemsg, 5000);
      }
    });
    this.spinner.hide("spinneronly");
  }

  getFreeze(columnid: string) {
    let freeze = false;
    let col = this.column_data.filter(e => e.id == columnid);
    if (col.length != 0) {
      freeze = col[0].freeze;
    }
    return freeze;
  }

  getWarmData() {
    if (this.column_data == null) return [];
    let warm = this.column_data.filter(e => !e.freeze).map(e => e);
    return warm;
  }

  getFrozenData() {
    if (this.column_data == null) return [];
    let cold = this.column_data.filter(e => e.freeze).map(e => e);
    return cold;
  }

  getDisplayedColumnsAll() {
    let newcolumns: ColumnData[] = [];
    newcolumns = newcolumns.concat(this.getFrozenData());
    newcolumns = newcolumns.concat(this.getWarmData().filter(e => e.visible));
    return newcolumns.map(e => e.id);
  }

  selectColumns() {
    const dialogRef = this.dialog.open(SelectColumnsComponent, {
      data: {
        datasource: this.dataSource,
        column_data: this.column_data,
        saved_column_data: this.saved_column_data,
        dirty: this.dirty
      },
      width: '500px',
      panelClass: 'selectcolumns-dialog'
    });
    dialogRef.componentInstance.emitService.subscribe((val) => {
      this.column_data = val;
      this.applyFilter();
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.dirty != null) {
        this.dirty = result.dirty;
      }
      if (result && result.save) {
        if (this.dirty) {
          this.dirty = false;
          this.save();
        } else {
          this.snackMsg.openSnackBar("Nothing to save.", 5000);
        }
      }
    });
  }

  orderName(orderid: string) {
    if (orderid == null || orderid == "") return "";
    let order = this.orders.find((e) => e.orderid.toLowerCase() == orderid.toLowerCase());
    return order != null ? order.PONumber : "";
  }

  isActive(license: string) {
    return (license != undefined && license != "") ? "True" : "False";
  }

  getBatchItems(batchid: number) {
    let dataSource = new MatTableDataSource(this.entitlements);
    let data = dataSource.data.filter((d) => {
      if (d.createbatchid == batchid) {
        return true;
      }
      return false;
    });
    this.batchCount = data.length;
    return this.batchCount;
  }

  rowActionDelete(data, fullBatch) {
    const dialogRef = this.dialog.open(DeleteEntitlementComponent, {
      data: {
        datavalue: data,
        account: { id: this.accountid, name: this.accountname },
        batchitems: this.batchCount,
        fullBatch: fullBatch
      },
      width: '500px',
      height: '490px',  //500px if we remove refresh charge, 545px (ish) with refresh charges
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.updated == true) {
        this.init();
      }
    });
  }


  rowAction(rowid: number, entitlement, data, edittype) {
    const dialogRef = this.dialog.open(EditEntitlementComponent, {
      data: {
        datacolumn: "Edit Entitlement",
        datavalue: data,
        account: { id: this.accountid, name: this.accountname },
        entitlement: entitlement,
        reservation_types: this.reservationTypeResult,
        accounts: this.customersResult,
        batchitems: this.batchCount,
        edittype: edittype
      },
      width: '700px',
      height: '524px',
      panelClass: 'custom-dialog-container'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.updated == true) {
        if (result.accountchanged && result.moveaccont != null) {
          this.accountname = result.moveaccont.name;
          this.accountid = result.moveaccont.id;
          this.customerData.switchAccount(this.accountid, this.accountname);
          this.router.navigateByUrl('/' + this.auth.getUsername() + '/admin/' + this.accountname.replace('/', '%2F'));
        } else {
          this.init();
        }
      }
    });
  }

  showDetail(datacolumn: string, input: string, idtype = "", id = "", license = "", fingerprint = "") {
    if (input != null && input.toString().trim() != "") {
      var url = "";
      if (idtype != "") {
        if (idtype == "order") {
          url = this.crmendpoint + "main.aspx?appid=90933db0-b17a-eb11-a812-000d3a55fe16&pagetype=entityrecord&etn=salesorder&id=" + id;
        } else {
          url = this.crmendpoint + "main.aspx?appid=90933db0-b17a-eb11-a812-000d3a55fe16&pagetype=entityrecord&etn=invoice&id=" + id;
        }
      }
      const dialogRef = this.dialog.open(ViewEntitlementComponent, {
        data: {
          datavalue: input,
          datacolumn: datacolumn,
          url: url,
          license: license,
          fingerprint: fingerprint
        },
        width: '35vw'
      });

      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  tooltip(input: string) {
    return input.replace(/(?:\r\n|\r|\n)/g, '\n');
  }

  public getProductList() {
    return this.products;
  }

  public setProductFilter(product) {
    this.filterProductState = product;
    this.applyFilter();
  }

  public setUsedFilter(used) {
    this.filterActivatedState = used;
    this.applyFilter();
  }

  public setCIDFilter(cid) {
    this.filterCidState = cid;
    this.applyFilter();
  }

  public highlight() {
    this.isChanged = true
    setTimeout(() => {
      this.isChanged = false
    }, 1000);
  }

  // this sets the filter to hardware key if the export column option hardware keys is chosen
  public setCIDFilterControl() {
    this.setCIDFilter(1);
    this.adminEntitlementsForm.get('formlockstyle').setValue('1');
    this.highlight();
  }

  public getReservedButtonText() {
    if (this.filterReservedState == 1) return 'False';
    if (this.filterReservedState == 2) return 'True';
    return 'All';
  }

  applyFilterEvent(filterEvent: Event): void {
    let val = (<HTMLInputElement>filterEvent.target).value;
    this.applyFilter(val);
  }

  applyFilter(newFilter?: string) {
    if (newFilter != null) {
      this.filterString = newFilter;
    }
    let fullFilter = `${this.filterString}${this.stringSplit}${this.filterProductState}${this.stringSplit}${this.filterActivatedState}${this.stringSplit}${this.filterCidState}`;
    this.updateFilterPredicate(fullFilter, this.column_data);
    this.hasData = this.dataSource.data.length > 0;
    if (this.paginator != null) this.dataSource.paginator = this.paginator;
    if (this.sort != null) this.dataSource.sort = this.sort;
    this.download_started = false;  // allow the download button to be clicked again
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  downloadFileByRecord($event) {
    if (!this.download_started) {
      // get export radio option
      let columns: ExportColumns = new ExportColumns();
      var item = this.adminEntitlementsForm.get('radexport');
      if (item.value == "adminfull") {
        columns.type = "adminfull";
      } else if (item.value == "opsmanfull") {
        columns.type = "opsmanfull";
      } else if (item.value == "cidmin") {
        columns.type = "cidmin";
      }
      this.download_started = true;
      var today = formatDate(Date.now(), 'yyyy-MMM-dd_HH-mm-ss', 'en_US');
      var downloadName = this.accountname + "_" + today + "_" + "TenAsysEntitlements.csv";
      this.spinner_0.show("spinner_0");
      this.snackMsg.openSnackBar("Creating TenAsys Entitlement data download: " + downloadName, 8000);
      var page = 1;
      var pagesize = 1000000;
      this.ems.GetExport<Blob>(this.accountid, this.filterCidState,
        this.filterActivatedState, this.filterReservedState,
        this.filterProductState, this.filterString, page,
        pagesize, false, columns.type)
        .subscribe(blob => {
          saveAs(blob, downloadName);
        },
          err => {
            this.snackMsg.openSnackBar("An error occurred during the creation of your TenAsys Entitlement data download.", 5000 * 4);
            this.spinner_0.hide("spinner_0")
            this.download_started = false;
          },
          () => {
            this.spinner_0.hide("spinner_0")
            this.download_started = false;
          });
    } else {
      this.snackMsg.openSnackBar("The process to create TenAsys Entitlement data download already in progress.  Please wait!", 5000);
    }
  }
}







