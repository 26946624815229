import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizationService } from 'src/app/modules/services/authorization.service';
import { EntitlementService } from 'src/app/modules/services/ems.service';
import { SnackService } from 'src/app/modules/services/snack.service';
import { UpdateEntitlement } from 'src/app/modules/types/UpdateEntitlement';

@Component({
  selector: 'app-delete-entitlement',
  templateUrl: './delete-entitlement.component.html',
  styleUrls: ['./delete-entitlement.component.scss']
})
export class DeleteEntitlementComponent {
  showmultialert: boolean = false;
  disableSaveBtn: boolean = false;
  saveComment: string = '';
  batchitems: number = 0;
  entitlement: string = '';
  fullBatch: boolean = false;
  confirmed: boolean = false;
  batchid: number = null;
  changeMade: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteEntitlementComponent>,
    public snackMsg: SnackService,
    public auth: AuthorizationService,
    public spinner_edit: NgxSpinnerService,
    private ems: EntitlementService,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        datavalue: object,
        account: { id: string, name: string },
        entitlement: string,
        batchitems: number,
        fullBatch: boolean
      }) {
    this.entitlement = data.entitlement;
    this.batchitems = data.batchitems;
    this.fullBatch = data.fullBatch;
    this.batchid = data.datavalue["createbatchid"];
  }
  ngOnInit(): void {  }


  confirmRecordConfirmed() {
    this.confirmed = true;
  }

  updateRecordConfirmed() {
    var entitlement = new UpdateEntitlement();
    entitlement.entitlementstateid = 6;
    entitlement.entitlementid = this.data.datavalue["tenasysentitlementid"];
    entitlement.username = this.auth.getUsername();
    entitlement.authToken = this.auth.getSecurityToken();
    entitlement.currentaccountid = this.data.account.id;
    entitlement.creationbatchid = this.fullBatch ? this.data.datavalue["createbatchid"] : 0;
    entitlement.updatereason = this.saveComment;

    // disable button
    this.disableSaveBtn = true;
    let msg = `Deleting ${this.fullBatch ? this.batchitems : 1} entitlement(s). Please wait.`;

    this.snackMsg.openSnackBar(msg, 6000);
    this.spinner_edit.show("spinner_edit");
    this.ems.UpdateEntitlement(entitlement).subscribe((respond) => {
      if (respond.TotalUpdated > 0) {
        this.changeMade = true;
      }
      let updatemsg = "Entitlements deleted: " + respond.TotalUpdated;
      this.snackMsg.openSnackBar(updatemsg, 6000);
      this.dialogRef.close({ updated: this.changeMade });
      this.spinner_edit.hide("spinner_edit");
    });
  }

  close() {
    this.dialogRef.close({ updated: this.changeMade });
  }
}
