<div class="usbsearch">
    <div class="usbsearchfield" *ngIf="hasUsb">
        <mat-expansion-panel hideToggle class="hr mat-elevation-z0">
            <mat-expansion-panel-header style="height: inherit;">
                <mat-panel-title>
                    <mat-form-field>
                        <input matInput type="text" placeholder="Serial Number" [(ngModel)]="this.usbserial"
                            name="usbserial" id="usbserial">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </mat-panel-title>
                <mat-panel-description>
                    <p style="width: 80%;">Enter your TenAsys USB/CID key serial number here to
                        retrieve your TenAsys license!</p>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="dlbutton">
                <a mat-raised-button color="accent" *ngIf="validUSB() && exportString != ''" href="{{exportString}}"
                    download="KeyLicenses.csv">Download
                    <mat-icon>arrow_downward</mat-icon></a>
            </div>
        </mat-expansion-panel>
    </div>
</div>