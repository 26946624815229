<div class="mainbox">
    <div class="leftsidebar">
        &nbsp;
    </div>
    <div class="submainbox">
        <div>
            <div class="topgridbox">
                <div class="topgrid">
                    <div>
                        <mat-form-field class="search">
                            <mat-label>Filter by Text</mat-label>
                            <input matInput data-testid="filter-by-text" (keyup)="applyFilterEvent($event)"
                                placeholder="Enter text string to filter" spellcheck="false">
                            <mat-icon matSuffix style="font-size: 1.2em">search</mat-icon>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="dropdown" data-testid="filter-by-product">
                            <mat-label>Filter Product</mat-label>
                            <mat-select value="all" [ngModel]="filterProductState"
                                (selectionChange)="setProductFilter($event.value)">
                                <mat-option value="all">All</mat-option>
                                <mat-option [value]="product" *ngFor="let product of getProductList()">
                                    {{product}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="dropdown" data-testid="filter-by-licensed">
                            <mat-label>Filter Licensed</mat-label>
                            <mat-select value="0" [ngModel]="filterActivatedState"
                                (selectionChange)="setUsedFilter($event.value)">
                                <mat-option value="0">All</mat-option>
                                <mat-option value="1">True</mat-option>
                                <mat-option value="2">False</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="dropdown" data-testid="filter-by-lock-style">
                            <mat-label>Filter Lock Style</mat-label>
                            <mat-select value="0" [ngModel]="filterCidState"
                                (selectionChange)="setCIDFilter($event.value)">
                                <mat-option value="0">All</mat-option>
                                <mat-option value="1">USB/CID Keys</mat-option>
                                <mat-option value="2">Node Locked</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="columns">
                        <button mat-button data-testid="select-columns" (click)="selectColumns()">Columns</button>
                    </div>
                </div>
            </div>

            <div class="detailstable">
                <div id="table" class="table">
                    <table mat-table density="-4" [dataSource]="dataSource" matSort #table>
                        <ng-container matColumnDef="actions" sticky *ngIf="getFreeze('actions')">
                            <th mat-header-cell *matHeaderCellDef>Actions</th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                <button mat-button (click)="this.getBatchItems(element.createbatchid)"
                                    [attr.data-testid]="'action-' + i" [matMenuTriggerFor]="actionMenu">
                                    <mat-icon>action</mat-icon><span>Actions</span>
                                </button>
                                <mat-menu #actionMenu="matMenu">
                                    <button mat-menu-item [attr.data-testid]="'edit-frozen-' + i"
                                        [matMenuTriggerFor]="editMenu"><mat-icon>edit</mat-icon><span>Edit</span></button>
                                    <mat-menu #editMenu="matMenu">
                                        <button mat-menu-item [attr.data-testid]="'edit-frozen-' + i"
                                            (click)="rowAction(element.id, element.tenasysentitlementid, element, 'single')">
                                            <span>This Entitlement Only</span>
                                        </button>
                                        <button mat-menu-item [attr.data-testid]="'edit-frozen-all-' + i"
                                            [disabled]="this.batchCount <= 1" mat-menu-item
                                            (click)="rowAction(element.id, element.tenasysentitlementid, element, 'batch')">
                                            <span>Entire Entitlement Batch ({{this.batchCount}})</span>
                                        </button>
                                    </mat-menu>
                                    <button mat-menu-item [attr.data-testid]="'delete-frozen-' + i"
                                        [matMenuTriggerFor]="batchDeleteMenu"><mat-icon>delete</mat-icon><span>Delete</span>
                                    </button>
                                    <mat-menu #batchDeleteMenu="matMenu">
                                        <button mat-menu-item (click)="rowActionDelete(element, false)">
                                            <span class="delete">This Entitlement Only</span>
                                        </button>
                                        <button mat-menu-item [attr.data-testid]="'edit-frozen-all-' + i"
                                            [disabled]="this.batchCount <= 1" mat-menu-item
                                            (click)="rowActionDelete(element, true)">
                                            <span class="delete">Entire Entitlement Batch ({{this.batchCount}})</span>
                                        </button>
                                    </mat-menu>
                                </mat-menu>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="id" sticky *ngIf="getFreeze('id')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="rowid-freeze-sort">Row Id
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Row Id', element.id)">
                                    {{element.id}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="tenasysentitlementid" sticky
                            *ngIf="getFreeze('tenasysentitlementid')">
                            <th mat-header-cell *matHeaderCellDef>Entitlement</th>
                            <td mat-cell *matCellDef="let element">
                                <button *ngIf="element.reservation_type_id == 2" mat-button color="warn"
                                    (click)="showDetail('Entitlement', element.tenasysentitlementid)">
                                    {{element.tenasysentitlementid}}
                                </button>
                                <button
                                    *ngIf="element.reservation_type_id == 3 || element.reservation_type_id == 4 || element.reservation_type_id == 5"
                                    mat-button color="warn"
                                    (click)="showDetail('Entitlement', element.tenasysentitlementid)">
                                    <span style="text-decoration: line-through;">{{element.tenasysentitlementid}}</span>
                                </button>
                                <button color="primary" mat-button *ngIf="element.reservation_type_id == 1"
                                    (click)="showDetail('Entitlement', element.tenasysentitlementid)">
                                    {{element.tenasysentitlementid}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="isactive" sticky *ngIf="getFreeze('isactive')">
                            <th mat-header-cell *matHeaderCellDef>Licensed</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Licensed', element.licensed)">
                                    {{element.licensed}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="emsentitlementid" sticky *ngIf="getFreeze('emsentitlementid')">
                            <th mat-header-cell *matHeaderCellDef>EMS Id</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('EMS Id', element.emsentitlementid)">
                                    {{element.emsentitlementid}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="suac" sticky *ngIf="getFreeze('suac')">
                            <th mat-header-cell *matHeaderCellDef>Suac</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Suac', element.suac)">
                                    {{element.suac}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="license" sticky *ngIf="getFreeze('license')">
                            <th mat-header-cell *matHeaderCellDef>License</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button
                                    (click)="showDetail('License', element.license, '', '', element.license)">
                                    {{element.license != null && element.license.length > 25 ? (element.license |
                                    slice:0:25) + ".." :
                                    element.license}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="classiclicense" sticky *ngIf="getFreeze('classiclicense')">
                            <th mat-header-cell *matHeaderCellDef
                                matTooltip="Imported by EntitlementImporter from the SUAC Manager"
                                matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">Classic License</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button
                                    (click)="showDetail('Classic License', element.classiclicense, '', '', element.classiclicense)">
                                    {{element.classiclicense != null && element.classiclicense.length > 25 ?
                                    (element.classiclicense |
                                    slice:0:25) + ".." : element.classiclicense}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="cidclassiclicense" sticky *ngIf="getFreeze('cidclassiclicense')">
                            <th mat-header-cell *matHeaderCellDef matTooltip="Created by USB/CID Onboarding Tool"
                                matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">CID Classic License</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button
                                    (click)="showDetail('CID Classic License', element.shortlicense, '', '', element.shortlicense)">
                                    {{element.shortlicense != null && element.shortlicense.length > 25 ?
                                    (element.shortlicense |
                                    slice:0:25) + ".." : element.shortlicense}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="fingerprint" sticky *ngIf="getFreeze('fingerprint')">
                            <th mat-header-cell *matHeaderCellDef>Fingerprint</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button
                                    (click)="showDetail('Fingerprint', element.fingerprint, '', '', '', element.fingerprint)">
                                    {{element.fingerprint != null && element.fingerprint.length > 25 ?
                                    (element.fingerprint | slice:0:25)
                                    +
                                    ".." : element.fingerprint}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ponumber" sticky *ngIf="getFreeze('ponumber')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="purchaseorder-freeze-sort">Purchase Order</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button
                                    (click)="showDetail('Purchase Order (PO)', element.ponumber , 'order', element.crmorderid)">
                                    {{element.ponumber != null && element.ponumber.length > 25 ?
                                    (element.ponumber | slice:0:25) + ".." : element.ponumber}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="crminvoicename" sticky *ngIf="getFreeze('crminvoicename')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="invoice-freeze-sort">
                                Invoice</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button
                                    (click)="showDetail('Invoice', element.crminvoicename , 'invoice', element.crminvoiceid)">
                                    {{element.crminvoicename != null && element.crminvoicename.length > 25 ?
                                    (element.crminvoicename | slice:0:25) + ".." : element.crminvoicename}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="customeractivationcomment" sticky
                            *ngIf="getFreeze('customeractivationcomment')">
                            <th mat-header-cell *matHeaderCellDef>Activation Comment</th>
                            <td mat-cell *matCellDef="let element" matTooltip="{{element.customeractivationcomment}}"
                                matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">
                                <button mat-button
                                    (click)="showDetail('Customer Activation Comment', element.customeractivationcomment)">
                                    {{element.customeractivationcomment != null &&
                                    element.customeractivationcomment.length > 25 ?
                                    (element.customeractivationcomment | slice:0:25) + ".." :
                                    element.customeractivationcomment}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="activateddate" sticky *ngIf="getFreeze('activateddate')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="licenseddate-freeze-sort">Activated On</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Activated On', element.activateddate)">
                                    {{element.activateddate | date:'mediumDate'}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="activatedby" sticky *ngIf="getFreeze('activatedby')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="licensedby-freeze-sort">
                                Licensed By</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Licensed By', element.activatedby)">
                                    {{element.activatedby}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="createddate" sticky *ngIf="getFreeze('createddate')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="entitlementcreateddate-freeze-sort">Entitlement Created Date</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button
                                    (click)="showDetail('Entitlement Created Date', element.createddate)">
                                    {{element.createddate | date:'mediumDate'}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="createdby" sticky *ngIf="getFreeze('createdby')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="entitlementaddedby-freeze-sort">Entitlement Added By</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Entitlement Added By', element.createdby)">
                                    {{element.createdby}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="internalcomment" sticky *ngIf="getFreeze('internalcomment')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="creatorcomment-freeze-sort">Creator Comment</th>
                            <td mat-cell *matCellDef="let element" matTooltip="{{element.internalcomment}}"
                                matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">
                                <button mat-button (click)="showDetail('Internal Comment', element.internalcomment)">
                                    {{element.internalcomment != null && element.internalcomment.length > 25 ?
                                    (element.internalcomment |
                                    slice:0:25) + ".." : element.internalcomment}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="modifieddate" sticky *ngIf="getFreeze('modifieddate')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="modifiedon-freeze-sort">
                                Modified On</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Modified On', element.modifieddate)">
                                    {{element.modifieddate | date:'mediumDate'}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="modifiedby" sticky *ngIf="getFreeze('modifiedby')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="modifiedby-freeze-sort">
                                Modified By</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Modified By', element.modifiedby)">
                                    {{element.modifiedby}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="createbatchid" sticky *ngIf="getFreeze('createbatchid')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="creationbatchid-freeze-sort">Creation Batch ID</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Creation Batch ID', element.createbatchid)">
                                    {{element.createbatchid}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ipaddress" sticky *ngIf="getFreeze('ipaddress')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="creationbatchid-freeze-sort">License IP Address</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('License IP Address', element.ipaddress)">
                                    {{element.ipaddress}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="productname" sticky *ngIf="getFreeze('productname')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="product-freeze-sort">
                                Product</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Product', element.productname)">
                                    {{element.productname}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="usbkey" sticky *ngIf="getFreeze('usbkey')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="usbcidkey-freeze-sort">
                                USB/CID Key</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('USB/CID Key', element.usbkey)">
                                    {{element.usbkey}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="classicorder" sticky *ngIf="getFreeze('classicorder')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="classicorder-freeze-sort">Classic Order</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Classic Order', element.classicordernumber)">
                                    {{element.classicordernumber}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="classicinvoice" sticky *ngIf="getFreeze('classicinvoice')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="classicinvoice-freeze-sort">Classic Invoice</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button
                                    (click)="showDetail('Classic Invoice', element.classicinvoicenumber)">
                                    {{element.classicinvoicenumber}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="source" sticky *ngIf="getFreeze('source')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="source-freeze-sort">
                                Source</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Source', element.source)">
                                    {{element.source}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="reservationtype" sticky *ngIf="getFreeze('reservationtype')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="state-freeze-sort">State
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <button *ngIf="element.reservation_type_id == 2"
                                    matTooltip="{{element.reservation_type_desc}}" matTooltipClass="tooltip-long"
                                    [matTooltipPosition]="'after'" mat-button color="warn"
                                    (click)="showDetail('Entitlement', element.reservationtype)">
                                    {{element.reservationtype}}
                                </button>
                                <button
                                    *ngIf="element.reservation_type_id == 3 || element.reservation_type_id == 4 || element.reservation_type_id == 5"
                                    matTooltip="{{element.reservation_type_desc}}" matTooltipClass="tooltip-long"
                                    [matTooltipPosition]="'after'" mat-flat-button color="warn"
                                    (click)="showDetail('Entitlement', element.reservationtype)">
                                    {{element.reservationtype}}
                                </button>
                                <button mat-button *ngIf="element.reservation_type_id == 1"
                                    (click)="showDetail('Entitlement', element.reservationtype)">
                                    {{element.reservationtype}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="actions" *ngIf="!getFreeze('actions')">
                            <th mat-header-cell *matHeaderCellDef>Actions</th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                <button mat-button [attr.data-testid]="'edit-notfrozen-' + i"
                                    [matMenuTriggerFor]="actionMenu"
                                    (click)="this.getBatchItems(element.createbatchid)"><mat-icon>edit</mat-icon><span>Edit</span></button>
                                <mat-menu #actionMenu="matMenu">
                                    <button mat-menu-item [attr.data-testid]="'edit-notfrozen-' + i"
                                        [matMenuTriggerFor]="editMenu"><mat-icon>edit</mat-icon><span>Edit</span></button>
                                    <mat-menu #editMenu="matMenu">
                                        <button mat-menu-item [attr.data-testid]="'edit-notfrozen-' + i"
                                            (click)="rowAction(element.id, element.tenasysentitlementid, element, 'single')">
                                            <span>This Entitlement Only</span>
                                        </button>
                                        <button mat-menu-item [attr.data-testid]="'edit-notfrozen-all-' + i"
                                            [disabled]="this.batchCount <= 1" mat-menu-item
                                            (click)="rowAction(element.id, element.tenasysentitlementid, element, 'batch')">
                                            <span>Entire Entitlement Batch ({{this.batchCount}})</span>
                                        </button>
                                    </mat-menu>
                                    <button mat-menu-item [attr.data-testid]="'delete-notfrozen-' + i"
                                        [matMenuTriggerFor]="batchDeleteMenu"><mat-icon>delete</mat-icon><span>Delete</span>
                                    </button>
                                    <mat-menu #batchDeleteMenu="matMenu">
                                        <button mat-menu-item (click)="rowActionDelete(element, false)">
                                            <span class="delete">This Entitlement Only</span>
                                        </button>
                                        <button mat-menu-item [attr.data-testid]="'edit-notfrozen-all-' + i"
                                            [disabled]="this.batchCount <= 1" mat-menu-item
                                            (click)="rowActionDelete(element, true)">
                                            <span class="delete">Entire Entitlement Batch ({{this.batchCount}})</span>
                                        </button>
                                    </mat-menu>
                                </mat-menu>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="id" *ngIf="!getFreeze('id')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="rowid-unfreeze-sort">Row
                                Id</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Row Id', element.id)">
                                    {{element.id}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="tenasysentitlementid" *ngIf="!getFreeze('tenasysentitlementid')">
                            <th mat-header-cell *matHeaderCellDef>Entitlement</th>
                            <td mat-cell *matCellDef="let element">
                                <button *ngIf="element.reservation_type_id == 2" mat-button color="warn"
                                    (click)="showDetail('Entitlement', element.tenasysentitlementid)">
                                    {{element.tenasysentitlementid}}
                                </button>
                                <button
                                    *ngIf="element.reservation_type_id == 3 || element.reservation_type_id == 4 || element.reservation_type_id == 5"
                                    mat-button color="warn"
                                    (click)="showDetail('Entitlement', element.tenasysentitlementid)">
                                    <span style="text-decoration: line-through;">{{element.tenasysentitlementid}}</span>
                                </button>
                                <button color="primary" mat-button *ngIf="element.reservation_type_id == 1"
                                    (click)="showDetail('Entitlement', element.tenasysentitlementid)">
                                    {{element.tenasysentitlementid}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="isactive" *ngIf="!getFreeze('isactive')">
                            <th mat-header-cell *matHeaderCellDef>Licensed</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Licensed', element.licensed)">
                                    {{element.licensed}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="emsentitlementid" *ngIf="!getFreeze('emsentitlementid')">
                            <th mat-header-cell *matHeaderCellDef>EMS Id</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('EMS Id', element.emsentitlementid)">
                                    {{element.emsentitlementid}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="suac" *ngIf="!getFreeze('suac')">
                            <th mat-header-cell *matHeaderCellDef>Suac</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Suac', element.suac)">
                                    {{element.suac}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="license" *ngIf="!getFreeze('license')">
                            <th mat-header-cell *matHeaderCellDef>License</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button
                                    (click)="showDetail('License', element.license, '', '', element.license)">
                                    {{element.license != null && element.license.length > 25 ? (element.license |
                                    slice:0:25) + ".." :
                                    element.license}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="classiclicense" *ngIf="!getFreeze('classiclicense')">
                            <th mat-header-cell *matHeaderCellDef
                                matTooltip="Imported by EntitlementImporter from the SUAC Manager"
                                matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">Classic License</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button
                                    (click)="showDetail('Classic License', element.classiclicense, '', '', element.classiclicense)">
                                    {{element.classiclicense != null && element.classiclicense.length > 25 ?
                                    (element.classiclicense |
                                    slice:0:25) + ".." : element.classiclicense}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="cidclassiclicense" *ngIf="!getFreeze('cidclassiclicense')">
                            <th mat-header-cell *matHeaderCellDef matTooltip="Created by USB/CID Onboarding Tool"
                                matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">CID Classic License</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button
                                    (click)="showDetail('CID Classic License', element.shortlicense, '', '', element.shortlicense)">
                                    {{element.shortlicense != null && element.shortlicense.length > 25 ?
                                    (element.shortlicense |
                                    slice:0:25) + ".." : element.shortlicense}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="fingerprint" *ngIf="!getFreeze('fingerprint')">
                            <th mat-header-cell *matHeaderCellDef>Fingerprint</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button
                                    (click)="showDetail('Fingerprint', element.fingerprint, '', '', '', element.fingerprint)">
                                    {{element.fingerprint != null && element.fingerprint.length > 25 ?
                                    (element.fingerprint | slice:0:25)
                                    +
                                    ".." : element.fingerprint}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ponumber" *ngIf="!getFreeze('ponumber')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="purchaseorder-unfreeze-sort">Purchase Order</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button
                                    (click)="showDetail('Purchase Order (PO)', element.ponumber , 'order', element.crmorderid)">
                                    {{element.ponumber != null && element.ponumber.length > 25 ?
                                    (element.ponumber | slice:0:25) + ".." : element.ponumber}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="crminvoicename" *ngIf="!getFreeze('crminvoicename')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="invoice-unfreeze-sort">
                                Invoice</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button
                                    (click)="showDetail('Invoice', element.crminvoicename , 'invoice', element.crminvoiceid)">
                                    {{element.crminvoicename != null && element.crminvoicename.length > 25 ?
                                    (element.crminvoicename | slice:0:25) + ".." : element.crminvoicename}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="customeractivationcomment"
                            *ngIf="!getFreeze('customeractivationcomment')">
                            <th mat-header-cell *matHeaderCellDef>Activation Comment</th>
                            <td mat-cell *matCellDef="let element" matTooltip="{{element.customeractivationcomment}}"
                                matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">
                                <button mat-button
                                    (click)="showDetail('Customer Activation Comment', element.customeractivationcomment)">
                                    {{element.customeractivationcomment != null &&
                                    element.customeractivationcomment.length > 25 ?
                                    (element.customeractivationcomment | slice:0:25) + ".." :
                                    element.customeractivationcomment}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="activateddate" *ngIf="!getFreeze('activateddate')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="licenseddate-sort">
                                Activated On</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Activated On', element.activateddate)">
                                    {{element.activateddate | date:'mediumDate'}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="activatedby" *ngIf="!getFreeze('activatedby')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="licensedby-unfreeze-sort">Licensed By</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Licensed By', element.activatedby)">
                                    {{element.activatedby}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="createddate" *ngIf="!getFreeze('createddate')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="entitlementcreateddate-unfreeze-sort">Entitlement Created Date</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button
                                    (click)="showDetail('Entitlement Created Date', element.createddate)">
                                    {{element.createddate | date:'mediumDate'}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="createdby" *ngIf="!getFreeze('createdby')">
                            <th mat-header-cell *matHeaderCellDef data-testid="entitlementaddedby-unfreeze-sort">
                                Entitlement Added By</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Entitlement Added By', element.createdby)">
                                    {{element.createdby}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="internalcomment" *ngIf="!getFreeze('internalcomment')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="creatorcomment-unfreeze-sort">Creator Comment</th>
                            <td mat-cell *matCellDef="let element" matTooltip="{{element.internalcomment}}"
                                matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">
                                <button mat-button (click)="showDetail('Internal Comment', element.internalcomment)">
                                    {{element.internalcomment != null && element.internalcomment.length > 25 ?
                                    (element.internalcomment |
                                    slice:0:25) + ".." : element.internalcomment}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="modifieddate" *ngIf="!getFreeze('modifieddate')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="modifiedon-unfreeze-sort">Modified On</th>
                            <td mat-cell *matCellDef="let element" data-testid="element.modifieddate">
                                <button mat-button (click)="showDetail('Modified On', element.modifieddate)">
                                    {{element.modifieddate | date:'mediumDate'}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="modifiedby" *ngIf="!getFreeze('modifiedby')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="modifiedby-unfreeze-sort">Modified By</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Modified By', element.modifiedby)">
                                    {{element.modifiedby}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="createbatchid" *ngIf="!getFreeze('createbatchid')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="creationbatchid-unfreeze-sort">Creation Batch ID</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Creation Batch ID', element.createbatchid)">
                                    {{element.createbatchid}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ipaddress" *ngIf="!getFreeze('ipaddress')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="licenseipaddress-unfreeze-sort">License IP Address</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('License IP Address', element.ipaddress)">
                                    {{element.ipaddress}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="productname" *ngIf="!getFreeze('productname')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="product-unfreeze-sort">
                                Product</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Product', element.productname)">
                                    {{element.productname}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="usbkey" *ngIf="!getFreeze('usbkey')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="usbcidkey-unfreeze-sort">
                                USB/CID Key</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('USB/CID Key', element.usbkey)">
                                    {{element.usbkey}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="classicorder" *ngIf="!getFreeze('classicorder')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="classicorder-unfreeze-sort">Classic Order</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Classic Order', element.classicordernumber)">
                                    {{element.classicordernumber}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="classicinvoice" *ngIf="!getFreeze('classicinvoice')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                data-testid="classicinvoice-unfreeze-sort">Classic Invoice</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button
                                    (click)="showDetail('Classic Invoice', element.classicinvoicenumber)">
                                    {{element.classicinvoicenumber}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="source" *ngIf="!getFreeze('source')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="source-unfreeze-sort">
                                Source</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button (click)="showDetail('Source', element.source)">
                                    {{element.source}}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="reservationtype" *ngIf="!getFreeze('reservationtype')">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header data-testid="state-unfreeze-sort">
                                State</th>
                            <td mat-cell *matCellDef="let element">
                                <button *ngIf="element.reservation_type_id == 2"
                                    matTooltip="{{element.reservation_type_desc}}" matTooltipClass="tooltip-long"
                                    [matTooltipPosition]="'after'" mat-button color="warn"
                                    (click)="showDetail('Entitlement', element.reservationtype)">
                                    {{element.reservationtype}}
                                </button>
                                <button
                                    *ngIf="element.reservation_type_id == 3 || element.reservation_type_id == 4 || element.reservation_type_id == 5"
                                    matTooltip="{{element.reservation_type_desc}}" matTooltipClass="tooltip-long"
                                    [matTooltipPosition]="'after'" mat-flat-button color="warn"
                                    (click)="showDetail('Entitlement', element.reservationtype)">
                                    {{element.reservationtype}}
                                </button>
                                <button mat-button *ngIf="element.reservation_type_id == 1"
                                    (click)="showDetail('Entitlement', element.reservationtype)">
                                    {{element.reservationtype}}
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="getDisplayedColumnsAll()"></tr>
                        <tr mat-row *matRowDef="let row; columns: getDisplayedColumnsAll();"></tr>
                        <!-- Row shown when there is no matching data.-->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No data matching the filter provided.</td>
                        </tr>
                    </table>
                </div>
                <mat-paginator [pageSize]="10" color="accent" [pageSizeOptions]="[10, 100, 200, totalEntitlements]"
                    [length]="totalEntitlements" showFirstLastButtons></mat-paginator>
            </div>

            <div class="export_data_grid">
                <div>
                    <button mat-raised-button color="accent" (click)="downloadFileByRecord($event)"
                        matTooltip="Click this button to download the filtered TenAsys license data selected above!"
                        matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">Download License
                        Data<mat-icon>arrow_downward</mat-icon></button>
                </div>
                <div style="text-align: left;">
                    <form [formGroup]="adminEntitlementsForm">
                        <mat-label>Export Columns</mat-label><br />
                        <input type="radio" value="adminfull" name="radexport" formControlName="radexport"
                            checked="checked" />
                        <mat-label for="adminfull"
                            matTooltip="All columns available for the Administrator (includes internal information)"
                            matTooltipClass="tooltip-long"
                            [matTooltipPosition]="'after'">Administrator</mat-label><br />
                        <input type="radio" value="opsmanfull" name="radexport" formControlName="radexport" />
                        <mat-label for="opsmanfull"
                            matTooltip="Data is provided with same columns as available to the Operations Manager"
                            matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">Operations
                            Manager</mat-label><br />
                        <input type="radio" value="cidmin" name="radexport" formControlName="radexport"
                            (click)="setCIDFilterControl()" />
                        <mat-label for="opsmanfull"
                            matTooltip="License download data will contain columns: serial, enhanced, and CID classic license.  This option also sets filter to USB/CID hardware keys only."
                            matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">Hardware Key Only</mat-label>
                    </form>
                </div>
                <div>
                    <ngx-spinner name="spinner_0" bdColor="white" size="small" color="#e12523" type="square-jelly-box"
                        [fullScreen]="false">
                        <p style="color: white">&nbsp;</p>
                    </ngx-spinner>
                </div>
            </div>
        </div>
    </div>
    <div class="rightsidebar">
        &nbsp;
    </div>
</div>