import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SnackService } from '../../services/snack.service';
import { AuthorizationService } from '../../services/authorization.service';
import { Router } from '@angular/router';
import { SplashService } from '../../services/splash.service';
import { CustomerDataService } from '../../services/customer-data.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: [
    '../../shared-styles/divs.scss',
    '../../shared-styles/tables.scss',
    '../../shared-styles/elements.scss',
    './maintenance.component.scss'
  ]
})
export class MaintenanceComponent implements OnInit {

  constructor(private auth: AuthorizationService,
    private router: Router,
    private spinner: SplashService,
    private customerData: CustomerDataService,
    public snackMsg: SnackService,
    public dialog: MatDialog
  ) {
    this.auth.reset();
  }

  ngOnInit(): void {
  }

}
