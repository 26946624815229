import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizationService } from 'src/app/modules/services/authorization.service';
import { EntitlementService } from 'src/app/modules/services/ems.service';
import { SnackService } from 'src/app/modules/services/snack.service';

@Component({
  selector: 'app-view-entitlement',
  templateUrl: './view-entitlement.component.html',
  styleUrls: ['./view-entitlement.component.scss']
})
export class ViewEntitlementComponent {
  expand: boolean = true;
  dataresult: string = "";
  constructor(
    private ems: EntitlementService,
    public auth: AuthorizationService,
    public snackMsg: SnackService,
    public spinner_view: NgxSpinnerService,
    public dialogRef: MatDialogRef<ViewEntitlementComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: {datacolumn: string, datavalue: string, url: string, license: string, fingerprint: string}) {    
   }

  ngOnInit(): void {
    this.dataresult = "";
    this.expand = true;
  }

  closeDialog() {
    this.dialogRef.close('Closing!');
  }

  decodelicense(license: string) {
    this.spinner_view.show("spinner_view");
    this.ems.decodeLicense(license).subscribe((respond) => {
      this.snackMsg.openSnackBar("license decoded!", 2000);
      this.dataresult = JSON.stringify(respond, null, 2);
      this.expand = true;
      this.spinner_view.hide("spinner_view");
    });
  }

  decodefingerprint(fingerprint: string) {
    this.spinner_view.show("spinner_view");
    this.ems.decodeFingerprint(fingerprint).subscribe((respond) => {
      this.snackMsg.openSnackBar("fingerprint decoded!", 2000);
      this.dataresult = JSON.stringify(respond, null, 2);
      this.expand = true;
      this.spinner_view.hide("spinner_view");
    });
  }

  public copy(input: string){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = input;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
