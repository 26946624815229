<div class="splash" [class.splash]="showSplash" [class.nosplash]="!showSplash">
  <app-splash></app-splash>
</div>
<div id="box">
  <ngx-spinner name="spinneronly" size="medium" type="ball-spin-clockwise" color="#e12523">
    <!-- <p class="loading">Loading...</p> -->
  </ngx-spinner>
  <div id="title">
    <button color="primary" mat-button (click)="gotoLogin()" class="nopadding">
      <h1>TenAsys License Portal</h1>
    </button>
  </div>
  <div id="subtitle">
    <a mat-button style="border-radius: 0px; padding: 0px;" href="https://www.tenasys.com" class="nopadding">
      <h3>www.tenasys.com</h3>
    </a>
  </div>
  <div id="toolbar_menu" class="menupanelgrid" data-testid="user-dropdown">
    <div *ngIf="this.auth.isLoggedIn()">
      <div>
        <button mat-button [matMenuTriggerFor]="menu" aria-label="Drop-down options">
          {{this.auth.whoLoggedIn()}}<mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="after">
          <button (click)="goback()" mat-menu-item>
            <mat-icon>subdirectory_arrow_left</mat-icon>
            <span>Go Back</span>
          </button>
          <button *ngIf="auth.getUserType() == 4 || auth.getUserType() == 3"
            (click)="adminNavTo('temporaryentitlements')" mat-menu-item>
            <mat-icon>&nbsp;</mat-icon>
            <span>Evals and Runtime Trials</span>
          </button>
          <button (click)="adminNavTo('logs')" *ngIf="auth.getUserType() == 4" mat-menu-item>
            <mat-icon>&nbsp;</mat-icon>
            <span>Logs</span>
          </button>
          <button (click)="adminNavTo('loginstats')" *ngIf="auth.getUserType() == 4" mat-menu-item>
            <mat-icon>&nbsp;</mat-icon>
            <span>Login Statistics</span>
          </button>
          <button (click)="adminNavTo('salesadmin')" *ngIf="auth.getUserType() == 4 || auth.getUserType() == 3"
            mat-menu-item>
            <mat-icon>&nbsp;</mat-icon>
            <span>Sales Administration</span>
          </button>
          <button (click)="gotoChangePassword()" data-testid="change-password" mat-menu-item>
            <mat-icon>password</mat-icon>
            <span>Change Password</span>
          </button>
          <button (click)="gotoLogin()" data-testid="logout-btn" mat-menu-item>
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div id="account" class="menupanelgrid">
    <a *ngIf="this.cust.adminCustomerAccount != ''" mat-button
      href="{{this.cust.adminCrmEndpoint}}main.aspx?appid=90933db0-b17a-eb11-a812-000d3a55fe16&pagetype=entityrecord&etn=account&id={{this.cust.adminCustomerAccountGuid}}"
      target="_blank">{{this.cust.adminCustomerAccount.toUpperCase()}}</a>
  </div>
  <div id="bread" class="breadcrumb">
    <div>
      <xng-breadcrumb [separator]="iconTemplate">
        <ng-template #iconTemplate>
          <mat-icon>chevron_right</mat-icon>
        </ng-template>
        <ng-container *xngBreadcrumbItem="let bcitem; let info = info; let first = first">
          <mat-icon *ngIf="info">{{info}}</mat-icon>
          <ng-container>{{bcitem}}</ng-container>
        </ng-container>
      </xng-breadcrumb>
    </div>
  </div>
  <div id="contentbox">
    <div style="height: 100%;">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div id="footer">
    <div class="footergrid">
      <div><mat-label>&copy; 2001-2024 Ten<span class="a">A</span>sys Corporation. All rights reserved.</mat-label>
      </div>
      <div><mat-label>License Portal Build: {{getBuild()}}.</mat-label></div>
    </div>
  </div>
</div>


