import { Component, OnInit } from '@angular/core';
import { EntitlementService } from '../../../../services/ems.service';
import { AuthorizationService } from '../../../../services/authorization.service';
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackService } from 'src/app/modules/services/snack.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-primary-edit-users-reset-password',
  templateUrl: './primary-edit-users-reset-password.component.html',
  styleUrls: [
    '../../../../shared-styles/divs.scss',
    '../../../../shared-styles/tables.scss',
    '../../../../shared-styles/elements.scss',
    './primary-edit-users-reset-password.component.scss']
})
export class PrimaryEditUsersResetPasswordComponent implements OnInit {
  public isReset: boolean = false;
  public password: string = "";
  public usertoreset: string = "";
  public isEmail: boolean = false;
  public resetdisabled: boolean = false;
  LOADDURATION: number = 8;

  constructor(private ems: EntitlementService,
    private spinner: NgxSpinnerService,
    public snackMsg: SnackService,
    public auth: AuthorizationService, 
    private router: Router, 
    private route: ActivatedRoute,
    private location: Location
    ) { 
      this.usertoreset = this.route.snapshot.paramMap.get('usertoreset') ?? "";
    }
  
  performReset(){
    this.spinner.show("spinneronly"); 
    this.resetdisabled = true;
    this.ems.resetPassword(this.usertoreset).subscribe(
      (data:any)=> {
        if (data) {
          this.password = data.temppassword;
          if (this.password.length < 8 ||
            this.password.length > 30 ||
            !(this.password.match('[a-z]')) ||
            !(this.password.match('[A-Z]')) ||
            !(this.password.match('[0-9\+!@$%^&*()_-]')) ||
            (this.password.match('[^a-zA-Z0-9\+!@$%^&*()_-]') != null)) {
              this.password = "";
              this.performReset();
          } else {
            this.isReset = true;
            this.snackMsg.openSnackBar("Temporary Password Set. Please copy the temporary password shown and send to the user.", 5000);
          }
        } else { 
          this.snackMsg.openSnackBar("An error occurred while trying to force the password reset.", 5000);
        }
        this.spinner.hide("spinneronly"); 
      }, 
      () => {
        this.snackMsg.openSnackBar("An error occurred while trying to force the password reset.", 5000);
        this.spinner.hide("spinneronly"); 
      }
    );
  }

  
  closeButtonLegend(){
    return this.isReset ? "Close" : "Cancel";
  }

  onNoClick(): void {
    this.location.back();
  }

  ngOnInit() {
    
  }

}
