<div class="modal">
	<div>
		<h2 mat-dialog-title>Attention &hyphen;</h2>
	</div>
	<mat-dialog-content>
		<div>
			The system fingerprint as provided will generate a valid license, but in case of a Windows reinstallation
			this license cannot be re-applied. For a RAID-enabled system, proceed with generating this
			license.<br><br>For more information, review this TenAsys Knowledge Base article:<br>
			<a class="hlink" target="_blank" mat-button color="accent"
				href="https://tenasys.com/my-tenasys/knowledge-base/knowledge-base-page/?pageNum=196">Invalidated
				node-locked INtime 6 license after Windows reload</a>
			<br>
			<ngx-spinner name="modalspinner" size="medium" type="ball-spin-clockwise" color="#e12523">
				<!-- <p class="loading">Loading...</p> -->
			</ngx-spinner>
		</div>
		<div class="buttongrid">
			<mat-dialog-actions>
				<button mat-raised-button disabled="{{this.proceedclicked}}" (click)="action('cancel')"
					value="cancel">Cancel</button>
				<button #proceedBtn autofocus mat-raised-button disabled="{{this.proceedclicked}}"
					(click)="action('proceed')" value="proceed">Proceed</button>
			</mat-dialog-actions>
		</div>
	</mat-dialog-content>
</div>