export class SearchEntitlements {
    username: string = "";
    authToken: string = "";
    searchtext: string = "";
    searchtype: string = "entitlement"; // entitlement, license, usb
	licensetype: string = "permanent"; // permanent, temporary
}

export class AdminSearchResults {
    tenasysentitlementid: string = "";
	createddate?: Date;
	enddate?: Date;
	daystolive?: number;
	createdby: string = "";
	internalcomment: string = "";
	license: string = "";
	activateddate?: Date;
	activatedby: string = "";
	ponumber: string = "";
	customeractivationcomment: string = "";
	ipaddress: string = "";
	fingerprint: string = "";
	usbkey: string = "";
	source: string = "";
	suac: string = "";
	classiclicense: string = "";
    classicordernumber: string = "";
    classicinvoicenumber: string = "";
    modifieddate?: Date;
    modifiedby: string = "";
	modifiedcomment: string = "";
	crmaccountid: string = "";
	crminvoicename: string = "";
	shortlicense: string = "";
	productname: string = "";
	createbatchid: number = 0;
	licenseidentifer?: string = "";
}