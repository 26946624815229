<div class="mainbox">
	<div class="leftsidebar">
		&nbsp;
	</div>
	<div class="submainbox">
		<div>
			<mat-card class="card" appearance="outlined">
				<mat-card-header>
					<mat-card-title>{{accountDetail.name}}</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<div class="contentgrid">
						<div>
							<dl>
								<dt>Primary Technical Contact</dt>
								<dd>{{getPrimaryContact()}}</dd>
								<dt>Secondary Technical Contact</dt>
								<dd>{{getSecondaryContact()}}</dd>
								<dt>Operations Manager Contact</dt>
								<dd>{{getOpsManager()}}</dd>
							</dl>
						</div>
						<div class="productbox">
							<table mat-table [dataSource]="dataSource">
								<ng-container matColumnDef="product">
									<th class="product" mat-header-cell *matHeaderCellDef> Product </th>
									<td class="product" mat-cell *matCellDef="let count">
										{{count.productname}} </td>
								</ng-container>
								<ng-container matColumnDef="totalcount">
									<th class="producttotal" mat-header-cell *matHeaderCellDef> Total </th>
									<td class="producttotal" mat-cell *matCellDef="let count">
										{{count.total | number}} </td>
								</ng-container>
								<ng-container matColumnDef="availableCount">
									<th class="producttotal" mat-header-cell *matHeaderCellDef> Available </th>
									<td class="producttotal" mat-cell *matCellDef="let count">
										{{count.available | number}} </td>
								</ng-container>
								<ng-container matColumnDef="activate">
									<th mat-header-cell *matHeaderCellDef> </th>
									<td class="createlicense" mat-cell *matCellDef="let count"><button
											class="activatebutton" mat-raised-button disabled="{{count.available == 0}}"
											(click)="activate(count.productname)">Create
											License</button></td>
								</ng-container>
								<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
								<tr mat-row *matRowDef="let row; columns: displayedColumns;" style="max-height: 38px;">
								</tr>
							</table>
						</div>
						<div><app-usbcid-search [accountid]="accountid" [hasUsb]="hasUSB"></app-usbcid-search>
						</div>
					</div>
				</mat-card-content>
				<mat-card-footer>
					<div class="buttongrid">
						<div>
							<button mat-raised-button value="Users" id="users" (click)="go('users')">
								<span (click)="go('users')">Manage Users</span>
								<mat-icon>account_box</mat-icon>
							</button>
						</div>
						<div>
							<button mat-raised-button value="Entitlement Details"
								(click)="go('entitlements')" data-testid="entitlement-details">Entitlement
								Details<mat-icon>view_list</mat-icon>
							</button>
						</div>
					</div>
				</mat-card-footer>
			</mat-card>
		</div>
	</div>
	<div class="rightsidebar">
		&nbsp;
	</div>
</div>