<div class="titlegrid">
    <div class="title"><h1 mat-dialog-title>TenAsys USB/CID {{this.data.usbid}}</h1></div>
    <div>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>Close</button>
        </mat-dialog-actions>
    </div>
</div>
<mat-dialog-content>
    <div id="licenses">
        <div>
            <div class="qrmodalhelp">
                To use the license(s) below:
                <br />&nbsp;&nbsp;&nbsp;&nbsp;Enter the License String with “Install License File” or “Enter License String” in the
                        License Manager applet, opened from the INtime Configuration
                        Panel.
            </div>
            <div class="inputs">
                <h1>Enhanced License:</h1>
                <textarea class="mono" id="enhLicense" data-testid="enhanced-license" name="enhanced_license" matTextArea
                    readonly>{{this.data.enhanced_license}}</textarea>
            </div>
            <div class="inputs" style="margin-top: 1em;">
                <h1 style="display: inline;">License Code (if applicable): </h1><mat-label class="mono licensecode">{{this.data.classic_license}}</mat-label>
            </div>
        </div>
    </div>
</mat-dialog-content>
