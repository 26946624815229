export class ColumnData {
    id: string = "";
    name: string = "";
    visible: boolean = true;
    freeze: boolean = false;
    index: number = 0;
}

export class ColumnFilterData {
    id: string = "";
    name: string = "";
    show_list: boolean = true;
}

export class UpdateColumnRequest {
    userid: string = "";
    column_data: ColumnData[] = [];
    table_name: string = "";

    constructor(tableName: string) {
        this.table_name = tableName;
    }
}

export class UpdateColumnResponse {
    userid: string = "";
    ColumnDataDefault: ColumnData[] = [];
    ColumnDataCustom: ColumnData[] = [];
}
