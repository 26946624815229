import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { Location } from '@angular/common';
import { CustomerDataService } from '../app/modules/services/customer-data.service';
import { AuthorizationService } from '../app/modules/services/authorization.service';
import { version } from "../version";
import { MatAccordion } from '@angular/material/expansion';
import { SplashService } from './modules/services/splash.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './modules/shared-styles/divs.scss',
    './modules/shared-styles/tables.scss',
    './modules/shared-styles/elements.scss',
    './app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  title = 'TenAsys License Portal';
  public showContainerInTablet: boolean;
  public showContainerInHandset: boolean;
  public showSplash: boolean = false;
  constructor(private router: Router,
    public cust: CustomerDataService,
    public auth: AuthorizationService,
    private location: Location,
    private splash: SplashService) { }

  ngOnInit(): void {
    this.splash.observe().subscribe((d) => {
      this.showSplash = d.showSplash;
    });
  }
  
  public adminNavTo(newRoute: string) {
    this.router.navigate(['/' + this.auth.getUsername() + '/admin/' + newRoute + '/']);
  }

  public userNavTo(newRoute: string) {
    this.router.navigate(['/' + this.auth.getUsername() + '/user/' + newRoute + '/']);
  }

  public opsmanNavTo(newRoute: string) {
    this.router.navigate(['/' + this.auth.getUsername() + '/superuser/' + newRoute + '/']);
  }

  public gotoChangePassword() {
    this.auth.setPreviousPage(this.router.url);
    var userType = this.auth.getUserType();
    if (userType == 3 || userType == 4) {
      this.adminNavTo('changepassword');
    } else if (userType == 2) {
      this.opsmanNavTo('changepassword');
    } else if (userType == 1) {
      this.userNavTo('changepassword');
    } else {
      this.gotoLogin();
    }
  }

  public gotoLogin() {
    this.cust.priorRoute = this.router.url;

    this.auth.setPreviousPage(this.router.url);
    var userType = this.auth.getUserType();
    if (userType == 3 || userType == 4) {
      this.adminNavTo('logoff');
    } else if (userType == 2) {
      this.opsmanNavTo('logoff');
    } else if (userType == 1) {
      this.userNavTo('logoff');
    } else {
      this.router.navigate(['/login/']);
    }
  }

  public goback() {
    this.location.back();
  }

  public getBuild() {
    let ver: string = version.number.split(" ")[0];
    let verArr: Array<string> = ver.split(".");
    // Remove 6.4/7.0/7.1/ect
    if (verArr.length == 4) {
      ver = verArr[2] + "." + verArr[3];
    }
    return ver;
  }
}
