import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Status } from '../../types/status';
import { EntitlementService } from '../../services/ems.service';
import { Router } from '@angular/router';
import { SnackService } from '../../services/snack.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: [
    '../../shared-styles/divs.scss',
    '../../shared-styles/tables.scss',
    '../../shared-styles/elements.scss',
    './status.component.scss'
  ],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class StatusComponent implements OnInit {
  public status: Status = new Status();
  hoursAfterMaintenance = 4;
  daysBeforeShowingMaintenance = 3;
  currentDate = new Date();
  maintenanceDatePlusHours = new Date();
  maintenanceDateMinusDays = new Date();
  next_maintenance = new Date();
  lastDate: Date = null;
  getStatusCall = null;
  showStatus: boolean = false;
  private isNavigatingLogin = false;
  constructor(
    private ems: EntitlementService,
    private router: Router,
    private snackMsg: SnackService
  ) {
  }

  ngOnInit(): void {
    this.getStatusCall = this.ems.getStatus();
    this.getStatusCall.subscribe(next => {
      if (next == null) {
        return;
      }
      this.showStatus = true;
      this.status = next;
      this.next_maintenance = new Date(this.status.next_maintenance);
      this.maintenanceDateMinusDays = new Date(this.next_maintenance.getTime() - (24*60*60*1000 * this.daysBeforeShowingMaintenance))
      this.maintenanceDatePlusHours = new Date(this.next_maintenance.getTime() + (60*60*1000 * this.hoursAfterMaintenance))
      //console.log("Release window: " + this.maintenanceDateMinusDays.toLocaleString() + " to " + this.maintenanceDatePlusHours.toLocaleString());
      if (this.status.available) {
        if (this.router.url.toLowerCase().startsWith('/maintenance')) {
          if (!this.isNavigatingLogin) {
            this.snackMsg.openSnackBar("The TenAsys License Portal is not in maintenance.  Redirecting to login.", 5000);
            this.isNavigatingLogin = true;
            this.router.navigate(['/']);
          }
        }
      }
    });
  }

  // 0 = false, 1 = true, 2 = unknown
  getStatus(): number {
    var messagenum = 2;
    if (this.status.available != null) {
      if (this.status.available) {
        messagenum = 1;
      } else {
        this.ems.sendToMaintenance();
        messagenum = 0;
      }
    }
    return messagenum;
  }

  showMaintenance() {
    return this.currentDate > this.maintenanceDateMinusDays && this.next_maintenance > this.maintenanceDateMinusDays && this.currentDate < this.maintenanceDatePlusHours;
  }

  getMessage(): string {
    let msg = "";
    if (this.status.next_maintenance_message != "") {
      msg = this.status.next_maintenance_message;
    }
    return msg;
  }

  getHours(): string {
    let hours = "hour"
    if (this.status.next_maintenance_downtime_hours != null && this.status.next_maintenance_downtime_hours > 1) {
      hours += "s";
    }
    return "Expected Downtime: " + this.status.next_maintenance_downtime_hours + " " + hours;
  }

  displayMaintenanceDate(): boolean {
    var displayDate = false;
    if (this.status.next_maintenance != null) {
      if (this.showMaintenance()) {
        displayDate = true;
      }
    }
    return displayDate;
  }

  displayMaintenanceMessage(): boolean {
    var displayMessage = false;
    if (this.status.next_maintenance_message != null && this.status.next_maintenance_message != "") {
        this.status.next_maintenance_message.replace("\\n", "<br>") + "</ul>";
        displayMessage = true;
    }
    return displayMessage;
  }

  displayMaintenanceDowntime(): boolean {
    var displayDowntime = false;
    if (this.status.next_maintenance_downtime_hours != null && this.status.next_maintenance_downtime_hours > 0) {
        displayDowntime = true;
      }
    return displayDowntime;
  }
}
