import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthorizationService } from '../../../services/authorization.service';
import { ActivatedRoute } from "@angular/router";
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EntitlementService } from '../../../services/ems.service';
import { TimeLimitedEntitlementData } from '../../../types/TimeLimitedEntitlementData';
import { SplashService } from 'src/app/modules/services/splash.service';
import { CellpopupComponent } from 'src/app/modules/shared-components/cellpopup/cellpopup.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackService } from 'src/app/modules/services/snack.service';
import { SelectColumnsComponent } from 'src/app/modules/shared-components/select-columns/select-columns.component';
import { ColumnData, UpdateColumnRequest, UpdateColumnResponse } from 'src/app/modules/types/ColumnData';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'app-admin-timelimitedentitlements-view',
  templateUrl: './admin-timelimitedentitlements-view.component.html',
  styleUrls: [
    '../../../shared-styles/divs.scss',
    '../../../shared-styles/tables.scss',
    '../../../shared-styles/elements.scss',
    './admin-timelimitedentitlements-view.component.scss'
  ]
})
export class AdminTimelimitedentitlementsViewComponent implements OnInit {
  isLoading: boolean = true;
  user: string;
  entitlements: Array<TimeLimitedEntitlementData> = new Array<TimeLimitedEntitlementData>();
  accountname: string;
  dataSource: MatTableDataSource<TimeLimitedEntitlementData> = new MatTableDataSource<TimeLimitedEntitlementData>();
  filter: string | null = null;
  LOADDURATION: number = 8;
  dirty: boolean = false;
  hasData: boolean = true;
  column_data: ColumnData[] = [];
  saved_column_data: ColumnData[] = [];
  filterString: string = "";
  displayedColumns: string[] = [];
  pageSize: number = 0;
  totalEntitlements: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator?: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort?: MatSort;

  constructor(
    private datePipe: DatePipe = new DatePipe('mediumDate'),
    private auth: AuthorizationService,
    private ems: EntitlementService,
    private splash: SplashService,
    public spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public snackMsg: SnackService,
    public dialog: MatDialog) {
    splash.show(this.LOADDURATION);
    this.user = this.route.snapshot.paramMap.get('username') ?? "";
    this.accountname = this.route.snapshot.paramMap.get('customer') ?? "";
    const getTimeLimitedEntitlements = this.ems.getTimeLimitedEntitlements();
    const getUserSettings = this.ems.getUserSettings(this.auth.getCRMID(), this.ems.userMode, "time-limited");
    var getQueries: Array<Observable<any>> = new Array<Observable<any>>();
    getQueries.push(getTimeLimitedEntitlements);
    getQueries.push(getUserSettings);

    forkJoin(getQueries).subscribe(next => {
      if (next == null) {
        this.splash.hide();
        return;
      }
      const data = next[0]
      const userResult:UpdateColumnResponse = next[1];      
      if (userResult.ColumnDataCustom != null) {
        userResult.ColumnDataCustom = userResult.ColumnDataCustom.sort((a, b) => a.index - b.index); 
        this.column_data = userResult.ColumnDataCustom;
      } else {
        userResult.ColumnDataDefault = userResult.ColumnDataDefault.sort((a, b) => a.index - b.index);    
        this.column_data = userResult.ColumnDataDefault;
      }
      this.saved_column_data = JSON.parse(JSON.stringify(userResult.ColumnDataDefault));
      this.displayedColumns = this.getDisplayedColumnsAll();
      this.entitlements = new Array<TimeLimitedEntitlementData>();
      
      for (const e in data) {
        const el = new TimeLimitedEntitlementData(data[e]);
        this.entitlements.push(el);
      }
      this.totalEntitlements = this.entitlements.length;
      this.dataSource = new MatTableDataSource(this.entitlements);
      this.dataSource.paginator = this.paginator ?? null;
      this.dataSource.sort = this.sort ?? null;      
      splash.hide();
    }, () => {
      this.snackMsg.openSnackBar("An Error Has Occurred.", 5000);
      this.splash.hide();
    });
  };

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator ?? null;
      if (this.sort != undefined) this.sort.sortChange.subscribe(next => {
        if (this.paginator != null) this.paginator.pageIndex = 0;
      }, () => {
        this.snackMsg.openSnackBar("An Error Has Occurred.", 5000);
        this.splash.hide();
      });
    }
  }

  applyFilterEvent(filterEvent: Event) {
    if (filterEvent != null) this.applyFilter((<HTMLInputElement>filterEvent.target).value);
  }

  applyFilter(newFilter?: string) {
    if (newFilter != null) {
      this.filterString = newFilter;
    }
    this.updateFilterPredicate(this.filterString, this.column_data);
    this.hasData = this.dataSource.data.length > 0;
    if (this.paginator != null) this.dataSource.paginator = this.paginator;
    if (this.sort != null) this.dataSource.sort = this.sort;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  showDetail(datacolumn: string, input: string) {
    if (input != null && input.toString().trim() != "") {
      const dialogRef = this.dialog.open(CellpopupComponent, {
        data: {
          datavalue: input,
          datacolumn: datacolumn,
          url: ""
        },
        width: '35vw'
      });

      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  getWarmData() {
    if (this.column_data == null) return [];
    let warm = this.column_data.filter(e => !e.freeze).map(e => e);
    return warm;
  }

  getFrozenData() {
    if (this.column_data == null) return [];
    let cold = this.column_data.filter(e => e.freeze).map(e => e);
    return cold;
  }

  getDisplayedColumnsAll() {
    let newcolumns: ColumnData[] = [];
    newcolumns = newcolumns.concat(this.getFrozenData());
    newcolumns = newcolumns.concat(this.getWarmData().filter(e => e.visible));
    return newcolumns.map(e => e.id);
  }

  updateFilterPredicate(filter: string, column_data: ColumnData[]) {
    this.dataSource.filterPredicate = function (d: TimeLimitedEntitlementData, filter: string): boolean {
      let searchActivatedDate = true;
      let searchInternalComment = true;
      let searchAccountName = true;
      let searchProductName = true;
      let searchEndDate = true;
      let searchDays = true;
      let searchLicense = true;
      let searchFingerprint = true;
      let searchIPAddress = true;
      let searchLicenseType = true;

      function getVisibleByColumnId(columnid: string) {
        return column_data.filter(e => e.id == columnid).map(e => e.visible).pop();
      }

      if (column_data != null) {
        searchActivatedDate = getVisibleByColumnId("activateddate");
        searchInternalComment = getVisibleByColumnId("internalcomment");
        searchAccountName = getVisibleByColumnId("accountname");
        searchProductName = getVisibleByColumnId("productname");
        searchEndDate = getVisibleByColumnId("enddate");
        searchDays = getVisibleByColumnId("daystolive");
        searchLicense = getVisibleByColumnId("license");
        searchFingerprint = getVisibleByColumnId("fingerprint");
        searchIPAddress = getVisibleByColumnId("ipaddress");
        searchLicenseType = getVisibleByColumnId("timelimitedtype");
      }

      // ensure case insensitivity
      filter = filter.toLowerCase();

      const foundAccountName = searchAccountName && d.accountname != null && d.accountname.toLowerCase().trim().indexOf(filter) != -1;
      const foundProductName = searchProductName && d.productname != null && d.productname.toLowerCase().trim().indexOf(filter) != -1;
      const foundActivatedDate = searchActivatedDate && d.activateddate != null && d.activateddate != null && d.activateddate.toString().toLowerCase().indexOf(filter) != -1;
      const foundLicense = searchLicense && d.license != null && d.license.toLowerCase().trim().startsWith(filter);
      const foundFingerprint = searchFingerprint && d.fingerprint != null && d.fingerprint.toLowerCase().trim().indexOf(filter) != -1;
      const foundIPAddress = searchIPAddress && d.ipaddress != null && d.ipaddress.toLowerCase().trim().indexOf(filter) != -1;
      const foundInternalComment = searchInternalComment && d.internalcomment != null && d.internalcomment.toLowerCase().trim().indexOf(filter) != -1;
      const foundEnddate = searchEndDate && d.enddate != null && d.enddate.toString().toLowerCase().indexOf(filter) != -1;
      const foundDaysToLive = searchDays && d.daystolive != null && d.daystolive.toString().trim().indexOf(filter) != -1;
      const foundTimeLimitedType = searchLicenseType && d.ipaddress != null && d.ipaddress.toLowerCase().trim().indexOf(filter) != -1;
      if (filter.length == 0 || foundAccountName || foundEnddate || 
        foundEnddate || foundDaysToLive || foundProductName || foundActivatedDate || 
        foundLicense || foundFingerprint || foundIPAddress || foundInternalComment || 
        foundTimeLimitedType) {
        return true;
      } else {
        return false;
      }
    }
    this.dataSource.filter = filter;
  }

  getFreeze(columnid: string) {
    let freeze = false;
    let col = this.column_data.filter(e => e.id == columnid);
    if (col.length != 0) {
      freeze = col[0].freeze;
     }
    return freeze;
  }

  save() {
    this.snackMsg.openSnackBar("Saving your changes, please wait!", 5000);
    this.spinner.show("spinneronly");
    let req = new UpdateColumnRequest("admin-timelimited");
    req.userid = this.auth.getCrmID();
    req.column_data = this.column_data;
    this.ems.saveTimeLimitedColumns(req).subscribe((respond) => {
      let updatemsg = "An error occurred while saving your column settings.  Please try again later.";
      if (respond.TotalUpdated > 0) {
        updatemsg = "Your column settings have been saved.";
        this.snackMsg.openSnackBar(updatemsg, 5000);
      }
    });
    this.spinner.hide("spinneronly");
  }

  selectColumns() {
    const dialogRef = this.dialog.open(SelectColumnsComponent, {
      data: {
        datasource: this.dataSource,
        column_data: this.column_data,
        saved_column_data: this.saved_column_data,
        dirty: this.dirty
      },
      width: '500px',
      panelClass: 'selectcolumns-dialog'
    });
    dialogRef.componentInstance.emitService.subscribe((val) => {
      this.column_data = val;
      this.applyFilter();
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.dirty != null) {
        this.dirty = result.dirty;
      }
      if (result && result.save) {
        if (this.dirty) {
          this.dirty = false;
          this.save();
        } else {
          this.snackMsg.openSnackBar("Nothing to save.", 5000);
        }
      }
    });
  }
}
