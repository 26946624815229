import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizationService } from 'src/app/modules/services/authorization.service';
import { EntitlementService } from 'src/app/modules/services/ems.service';
import { SnackService } from 'src/app/modules/services/snack.service';
import { SplashService } from 'src/app/modules/services/splash.service';
import { Customer } from 'src/app/modules/types/Customer';
import { UpdateEntitlement } from 'src/app/modules/types/UpdateEntitlement';

@Component({
  selector: 'app-edit-entitlement',
  templateUrl: './edit-entitlement.component.html',
  styleUrls: [
    '../../../../shared-styles/divs.scss',
    '../../../../shared-styles/tables.scss',
    '../../../../shared-styles/elements.scss',
    './edit-entitlement.component.scss']
})
export class EditEntitlementComponent implements OnInit {
  saveDisabled: boolean = true;
  panelStateOpenState: boolean = false;
  panelTransferOpenState: boolean = false;
  panelRefreshOpenState: boolean = false;
  navigateToTransfer: boolean = false;
  forceDisableState: boolean = false;
  entitlementStates: { name: string, selected: boolean }[];
  initialStateId: { id: number, name: string } = { id: null, name: "" };
  newState: { id: number, name: string } = { id: null, name: "" };
  saveComment: string = "";
  statechanged: boolean = false;
  accountchanged: boolean = false;
  saveProceed: boolean = false;
  searchCustomerName: string = "";
  filterAccounts: Array<Customer>;
  scrolledOnced: boolean = false;
  scrolledTwoOnced: boolean = false;
  disableSaveBtn: boolean = false;
  showmultialert: boolean = false;
  hiddenStateId: number = 0;
  refreshCharges: number = 1;
  batchtype: string = "single"; // single and batch
  newAccount: { id: string, name: string } = { id: "", name: "" };
  constructor(
    private router: Router,
    private ems: EntitlementService,
    private auth: AuthorizationService,
    public snackMsg: SnackService,
    private splash: SplashService,
    public spinner_edit: NgxSpinnerService,
    public dialogRef: MatDialogRef<EditEntitlementComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        datacolumn: string,
        datavalue: object,
        account: { id: string, name: string },
        reservation_types: [object],
        entitlement: string,
        accounts: Array<Customer>,
        batchitems: number,
        edittype: string
      }) {
  }
  @ViewChild('matExpansionPanelState', { static: true }) matExpansionPanelState: MatExpansionPanel;
  @ViewChild('matExpansionPanelAccount', { static: true }) matExpansionPanelAccount: MatExpansionPanel;

  ngOnInit(): void {
    this.filterAccounts = this.data.accounts;
    this.batchtype = this.data.edittype;
    let hiddenid: number = 0;
    for (let state of this.data.reservation_types) {
      if (state["type"].toLowerCase() == "in-process") {
        hiddenid = state["id"];
        break;
      }
    }
    this.hiddenStateId = hiddenid;
    if (this.batchtype != "single") {
      this.showmultialert = true;
    }
    for (let item of this.data.reservation_types) {
      if (item["type"].toLowerCase() == this.data.datavalue["reservationtype"].toLowerCase()) {
        this.initialStateId.id = item["id"];
        this.initialStateId.name = item["type"];
      }
    }

    if (this.initialStateId.id == this.hiddenStateId) {
      this.forceDisableState = true;
    }
  }

  getReservationStateIdByName(name: string): number {
    let id: number = 0;
    for (let state of this.data.reservation_types) {
      if (state["type"].toLowerCase() == name.toLowerCase()) {
        id = state["id"];
        break;
      }
    }
    return id;
  }

  getReservationStateNameById(id: number) {
    let stateName = "";
    for (let state of this.data.reservation_types) {
      if (state["id"] == id) {
        stateName = state["type"];
        break;
      }
    }
    return stateName;
  }

  stateChange(ev) {
    let stateName = this.getReservationStateNameById(ev.target.id);
    if (stateName == "") {
      return;
    }
    this.newState = { id: ev.target.id, name: stateName };
    if (ev.target.id != this.initialStateId.id) {
      this.saveDisabled = false;
      this.statechanged = true;
    } else {
      this.statechanged = false;
    }
    if (!this.statechanged && !this.accountchanged) {
      this.saveDisabled  = true;
    }
    this.matExpansionPanelState.close();
  }

  transferCustomer(customerId: string, customerName: string) {
    if (customerId != this.data.account.id) {
      this.newAccount = { id: customerId, name: customerName };
      this.saveDisabled = false;
      this.accountchanged = true;
    } else {
      this.accountchanged = false;
    }
    if (!this.statechanged && !this.accountchanged) {
      this.saveDisabled  = true;
    }
    this.matExpansionPanelAccount.close();
  }

  customerFilter(): void {
    let valueLower = this.searchCustomerName.toLowerCase();
    this.filterAccounts = this.data.accounts.filter(c => c.name.toLowerCase().includes(valueLower));
  }

  updateRecord() {
    this.saveProceed = true;
  }

  moveaccount() {
    this.snackMsg.openSnackBar("Moving entitlement to: " + this.newAccount.name, 6000);
  }

  updateRecordConfirmed() {
    var entitlement = new UpdateEntitlement();
    entitlement.username = this.auth.getUsername();
    entitlement.authToken = this.auth.getSecurityToken();
    entitlement.currentaccountid = this.data.account.id;
    entitlement.creationbatchid = this.batchtype == "batch" ? this.data.datavalue["createbatchid"] : 0;
    entitlement.updatereason = this.saveComment;

    // disable button
    this.saveProceed = false;
    this.disableSaveBtn = true;

    // whether to navigate upon exit
    let moveAccounts = null;

    if (!this.statechanged && !this.accountchanged) {
      this.snackMsg.openSnackBar("No change is detected.  We cannot proceed.", 6000);
    } else {
      let msg = "";
      entitlement.entitlementid = this.data.entitlement;

      if (this.accountchanged) {
        msg += "Transferring \"" + this.data.entitlement + "\" to account \"" + this.newAccount.name + "\"";
        entitlement.newaccountid = this.newAccount.id;
      }
      if (this.statechanged) {
        if (msg == "") {
          msg += "Altering entitlement state from " + this.initialStateId.name + " to " + this.newState.name;
        } else {
          msg += "\n Altering entitlement state from " + this.initialStateId.name + " to " + this.newState.name;
        }
        entitlement.entitlementstateid = this.newState.id;
      }
      this.snackMsg.openSnackBar(msg, 6000);
      this.spinner_edit.show("spinner_edit");
      this.ems.UpdateEntitlement(entitlement).subscribe((respond) => {
        let updatemsg = "Entitlements affected: " + respond.TotalUpdated;
        if (this.accountchanged) {
          updatemsg += "\n Entitlements moved to account " + this.newAccount.name + ".";
          let snackBarRef = this.snackBar.open(updatemsg, 'View Account', { duration: 10000, verticalPosition: 'top' });
          snackBarRef.onAction().subscribe(() => {
            moveAccounts = this.newAccount;
          });
          snackBarRef.afterDismissed().subscribe(() => {
            this.disableSaveBtn = false;
            this.dialogRef.close({ updated: true, accountchanged: this.accountchanged, moveaccont: moveAccounts });
            this.spinner_edit.hide("spinner_edit");
          });
        } else {
          this.disableSaveBtn = false;
          this.dialogRef.close({ updated: true, accountchanged: this.accountchanged, moveaccont: moveAccounts });
          this.spinner_edit.hide("spinner_edit");
        }
      });
    }
  }

  scrollToMyRef = (id) => {
    var ref = document.getElementById(id);
    setTimeout(function () {
      ref.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100);
  };

  scrollToBottom(id) {
    if (!this.scrolledOnced) {
      this.scrollToMyRef(id);
      this.scrolledOnced = true;
    }
  }

  scrollTwoToBottom(id) {
    if (!this.scrolledTwoOnced) {
      this.scrolledTwoOnced = true;
      this.scrollToMyRef(id);
    }
  }

  closeDialog() {
    this.dialogRef.close('Closing!');
  }

  toggleState() {
    this.panelStateOpenState = !this.panelStateOpenState
  }

  toggleTransfer() {
    this.panelTransferOpenState = !this.panelTransferOpenState
  }

  toggleRefresh() {
    this.panelRefreshOpenState = !this.panelRefreshOpenState
  }
}
