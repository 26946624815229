<div class="mainbox">
    <div class="leftsidebar">
        &nbsp;
    </div>
    <div class="submainbox">
        <div>
            <div>
                <p class="hl1">Log out of system?</p>
            </div>
            <div class="logoff_box_sub">
                <div>
                    <mat-icon class="icon" matSuffix>logout</mat-icon>
                </div>
                <div class="buttongrid">
                    <div><button mat-raised-button color="accent" (click)="navTo('login')">Log Off <mat-icon>logout</mat-icon></button></div>
                    <div><button mat-raised-button (click)="navTo('back')">Back</button></div>
                </div>
            </div>
        </div>
    </div>
    <div class="rightsidebar">
        &nbsp;
    </div>
</div>