<div class="mainbox">
	<div class="leftsidebar">
		&nbsp;
	</div>
	<div class="submainbox">
		<div>
			<div>
				<form>
					<div>
						<div>
							<label>Search entitlement database:</label>
						</div>
						<div class="radgrid">
							<div><input name='searchType' checked="true" (change)="setSearchType($event)" type='radio'
									id='order' matTooltip="Searches purchase order and invoice"
									matTooltipClass="tooltip-long" [matTooltipPosition]="'after'" />PO <span
									class="or">or</span> Invoice</div>
							<div><input name='searchType' (change)="setSearchType($event)" type='radio'
									id='entitlements' />Entitlements</div>
							<div><input name='searchType' (change)="setSearchType($event)" type='radio'
									id='licenses' />Licenses</div>
							<div><input name='searchType' (change)="setSearchType($event)" type='radio' id='usb'
									matTooltip="Searches USB/CID serial and computerid" matTooltipClass="tooltip-long"
									[matTooltipPosition]="'after'" />USB/CID
								Keys</div>
							<div>
								<mat-select placeholder="Permanent or time-limited?" required [(ngModel)]="licenseType">
									<mat-option value="permanent">Standard Licenses</mat-option>
									<mat-option value="temp">Temporary Licenses</mat-option>
								</mat-select>
							</div>
						</div>
					</div>
					<div class="resultgrid">
						<div class="searchgrid">
							<div>
								<mat-form-field appearance="outline">
									<input matInput id="search" class="input" [(ngModel)]="this.searchFilter"
										[placeholder]="searchPlaceHolder" />
								</mat-form-field>
							</div>
							<div class="vcenter">
								<button mat-raised-button (click)="search()">Search</button>
							</div>
						</div>
						<div class="resultstext" *ngIf="this.showResults">
							<mat-expansion-panel [expanded]="this.showResults">
								<mat-expansion-panel-header>
									<mat-panel-title>
										{{this.entitlementsReturned}} entitlements found! <span
											*ngif="!this.showResults">(expand to see search results)</span>
									</mat-panel-title>
								</mat-expansion-panel-header>
								<pre>{{this.resultsText}}</pre>
							</mat-expansion-panel>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div class="columns">
			<div>
				<label>Retrieve USB Onboarding Tool Import File:</label>
				<form>
					<div class="batchgrid">
						<div class="searchgrid">
							<div>
								<mat-form-field appearance="outline">
									<input matInput id="batch" class="input" [(ngModel)]="this.batchid"
										placeholder="Batch ID" />
								</mat-form-field>
							</div>
							<div class="vcenter">
								<button mat-raised-button (click)="getUsbDownload()"
									matTooltip="Download the .usb file required by the TenAsys Onboarding Tool."
									matTooltipClass="tooltip-long" [matTooltipPosition]="'after'">Download</button>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div>
				<div class="tools">
					<div>
						<mat-label>Tool needed to associate entitlements with physical USB/CID hardware
							keys:</mat-label><br />
						<a class="hlink" mat-button color="accent"
							href="https://tenasys-licensing-safenet.s3.amazonaws.com/tools/USBOnboardingTool_Setup.exe"
							download="USB_Importer_Setup.exe">TenAsys USB/CID Onboarding Tool</a>
					</div>
					<div>
						<mat-label>The driver needed for the USB/CID hardware device:</mat-label><br />
						<a class="hlink" mat-button color="accent"
							href="https://tenasys-licensing-safenet.s3.amazonaws.com/tools/Sentinel%20System%20Driver%20Installer%207.5.9.exe"
							download="SentinelSystemDriverInstaller752.exe">Sentinel USB Driver</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="rightsidebar">
	&nbsp;
</div>