<div class="status_box">
    <div data-testid="portalstatus-status">
        <p class="state">License Portal Status:
            <span class="avail" *ngIf="getStatus() == 1">UP</span>
            <span class="down" *ngIf="getStatus() == 0">DOWN</span>
            <span class="unknown" *ngIf="getStatus() == 2">Fetching Status..</span>
        </p>
    </div>
    <div *ngIf="displayMaintenanceDate()" class="statusinfo">
        <div style="padding: 1em;">
            <div>
                <p>A TenAsys License Portal outage is scheduled
                    on:&nbsp;&nbsp;<wbr><span class="maintdate">{{status.next_maintenance | date:'full'}}</span></p>
                <p *ngIf="displayMaintenanceDowntime()">{{getHours()}}
                </p>
            </div>
            <div>
                <p *ngIf="displayMaintenanceMessage()" [innerHTML]="getMessage()">&nbsp;</p>
            </div>
        </div>
    </div>
</div>