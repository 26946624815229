<div class="mainbox">

    <div class="leftsidebar">
        &nbsp;
    </div>
    <div class="submainbox">
        <div class="content">
            <form>
                <div class="reqs">
                    <p>Password Requirements:</p>
                    <ul>
                        <li>Be 8 to 30 characters</li>
                        <li>Include 1 uppercase character</li>
                        <li>Include 1 lowercase character</li>
                        <li>Include 1 number OR special character (!@$%^&*()_-+)</li>
                    </ul>
                </div>
                <div class="resetpassgrid">
                    <div class="padding">
                        <mat-form-field appearance="outline" class="nomWidth">
                            <mat-label>Enter Current Password</mat-label>
                            <input matInput type="{{ showPass ? 'text' : 'password' }}" placeholder="Current Password"
                                [(ngModel)]="this.oldPassword" name="oldPassword" id="oldPassword">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="nomWidth">
                            <mat-label>Enter New Password</mat-label>
                            <input matInput type="{{ showPass ? 'text' : 'password' }}" placeholder="New Password"
                                [(ngModel)]="this.newPassword" name="newPassword" id="newPassword">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="nomWidth">
                            <mat-label>Confirm New Password</mat-label>
                            <input matInput type="{{ showPass ? 'text' : 'password' }}" placeholder="Confirm Password"
                                [(ngModel)]="this.confirmPassword" name="confirmPassword" id="confirmPassword">
                        </mat-form-field>
                    </div>
                    <div style="text-align: left;">
                        <mat-checkbox [(ngModel)]="showPass" name="showPass" id="showPass">Show
                            Passwords?</mat-checkbox>
                    </div>
                    <div class="buttongrid">
                        <button [disabled]="!this.isPasswordValid()" mat-raised-button value="Update Password"
                            color="accent" (click)="updatePassword()" id="update"
                            matTooltip="This button remains disabled until all password requirements are met."
                            matTooltipClass="tooltip-long" [matTooltipPosition]="'above'">Update
                            Password<mat-icon>done</mat-icon></button>
                        <button mat-raised-button (click)="cancel()">{{this.closeButtonLegend()}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="rightsidebar">
        &nbsp;
    </div>

</div>