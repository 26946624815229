export class AccountData {
    name: string = ""; 
    accountid: string = ""; 
    primaryTechnicalFirstName: string = ""; 
    primaryTechnicalLastName: string = ""; 
    primaryTechnicalUserName: string = ""; 
    primaryTechnicalUserId: number = 0;
    primaryTechnicalEmail: string = ""; 
    secondaryTechnicalFirstName: string = "";
    secondaryTechnicalLastName: string = "";
    secondaryTechnicalUserName: string = "";
    secondaryTechnicalUserId: number = 0;
    secondaryTechnicalEmail: string = "";
    operationsManagerFirstName: string = "";
    operationsManagerLastName: string = "";
    operationsManagerUserName: string = "";
    operationsManagerUserId: number = 0;
    operationsManagerEmail: string = "";
    territory: string = "";
}